import React, { useState, useEffect } from "react";
import { doc, updateDoc, deleteField, addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import useUID from '../General/useUID';
import styles from "./PatientView.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faCheckDouble, faPersonCirclePlus, faBan, faPhone, faClipboardList, faUserGraduate, faUserMd, faTrash } from "@fortawesome/free-solid-svg-icons";

const PatientFlag = ({ patientSelected, onDataUpdated }) => {
    const [uid] = useUID();
    const [showOptions, setShowOptions] = useState(false); // State to toggle the dropdown visibility

    const patientId = patientSelected.id

    const flagOptions = [
        { value: "Eligible", icon: faCheckDouble, label: "Eligible" },
        { value: "Ineligible", icon: faCircleExclamation, label: "Ineligible" },
        { value: "Denied Service", icon: faBan, label: "Denied Service" },
        { value: "Call", icon: faPhone, label: "Call" },
        { value: "Needs Assessment", icon: faClipboardList, label: "Needs Assessment" },
        { value: "Enrolled", icon: faUserGraduate, label: "Enrolled" },
        { value: "Outpatient", icon: faUserMd, label: "Outpatient" },
        { value: "intake", icon: faPersonCirclePlus, label: "From Intake" },
        { value: "Remove Flag", icon: faTrash, label: "Remove Flag" },
        // Add any other options you need here
    ];
    

    const handleOptionClick = async (value) => {
        if (!uid || !patientId) {
            console.error('UID or Patient ID is undefined');
            return;
        }
    
        const patientRef = doc(db, "patients", uid, "patientData", patientId);
    
        if (value === "Remove Flag") {
            try {
                await updateDoc(patientRef, {
                    flag: deleteField()
                });
                onDataUpdated()
            } catch (error) {
                console.error('Error removing the flag:', error);
            }
        } else {
            try {
                await updateDoc(patientRef, { flag: value });

            const logData = {
                timestamp: Date.now(),
                patientId: patientId,
                flag: value,
                activity: 'Flag added to patient',
                activityType: 'flagAdded',
                uid: uid
            };

            await addDoc(collection(db, "users", uid, "activityLogs"), logData);
            onDataUpdated()
        } catch (error) {
                console.error('Error flagging the patient:', error);
            }
        }
    
        setShowOptions(false);
    };
    

    const handleCancel = () => {
        setShowOptions(false); // Close dropdown when cancel is clicked
    };
       
    return (
        <>
                <span onClick={() => setShowOptions(true)} className={styles.downloadText}>
                    Flag
                </span>
            {showOptions && (
                <div className={styles.flagContainer}>
                    <ul className={styles.flagDropdown}>
                        {flagOptions.map(option => (
                            <li key={option.value} onClick={() => handleOptionClick(option.value)}>
                                <FontAwesomeIcon icon={option.icon} />
                                {option.label}
                            </li>
                        ))}
                    </ul>
                    
                    <button onClick={handleCancel} className={styles.cancelButton}>
                        Cancel
                    </button>
                </div>
            )}
        </>
    );
};

export default PatientFlag;