import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import { getDatabase, ref, onValue, off } from "firebase/database"; // Import Realtime Database functions

const TopPayersFrequencyTable = () => {
  const [loading, setLoading] = useState(true);
  const [payerData, setPayerData] = useState([]);
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (!uid) return;

    setLoading(true);
    const database = getDatabase();
    const frequencyRef = ref(
      database,
      `aggregateFrequencyData/${uid}/payerData`
    );

    const unsubscribe = onValue(
      frequencyRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setPayerData(data);
        } else {
          setPayerData([]);
        }
        setLoading(false);
      },
      (error) => {
        // console.error(
        //   "Error fetching payer data from Realtime Database:",
        //   error
        // );
        setLoading(false);
      }
    );

    return () => off(frequencyRef, "value", unsubscribe); // Clean up the subscription
  }, [uid]);

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="FrequencyTableContainer">
      <h3 style={{ textAlign: "center" }}>Payers</h3>
      <table>
        <thead>
          <tr>
            <th>Payer Name</th>
            <th>Number of Claims</th>
          </tr>
        </thead>
        <tbody>
          {payerData.map((payer, index) => (
            <tr key={index}>
              <td>{payer.name}</td>
              <td>{payer.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopPayersFrequencyTable;
