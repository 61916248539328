import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { doc, getDocs, setDoc, getDoc, collection, addDoc, updateDoc, deleteDoc } from "firebase/firestore";

function StepFour({ onNext, prevStep, setCanProceed }) {
    const [uid] = useUID();
    const [yearPracticeStarted, setYearPracticeStarted] = useState('');
    const [medicareNumber, setMedicareNumber] = useState('');
    const [medicaidNumber, setMedicaidNumber] = useState('');
    const [deaNumber, setDeaNumber] = useState('');
    const [deaRestriction, setDeaRestriction] = useState({ unlimited: false, restricted: false });
    const [restrictedExplanation, setRestrictedExplanation] = useState('');
    const [practiceType, setPracticeType] = useState({
      solo: false,
      group: false,
      other: false,
      groupNPI: false,
    });
    // New state for managing licenses
    const [licenses, setLicenses] = useState([
      { state: '', licenseName: '', licenseNumber: '', expirationDate: '' }
    ]);
    const [groupMembers, setGroupMembers] = useState([
        { name: '', specialty: '' }
      ]);
    const [alliedHealthProfessionals, setAlliedHealthProfessionals] = useState([]);

  
    const states = [
      'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
      'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
      'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
      'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
      'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ];
    const [yesNoAnswers, setYesNoAnswers] = useState({
        acceptingNewPatients: null, // null, true for yes, false for no
        makeHouseCalls: null,
        provideSecondOpinions: null,
        treatWorkersComp: null,
        offer24HrCoverage: null,
        labServices: null,
        CLIA: null,
        radiology: null,
        alliedHealthProfessionals: false, // Default to false

      });


    // Validation logic to enable or disable the "Next" button
    useEffect(() => {
      const isValid = validateForm();
        setCanProceed(isValid);
    }, [yearPracticeStarted, medicareNumber, medicaidNumber, deaNumber, deaRestriction, restrictedExplanation, practiceType, licenses, groupMembers, alliedHealthProfessionals, yesNoAnswers, setCanProceed]);

    const validateForm = () => {
        // Example validation: ensure yearPracticeStarted and at least one license is filled out
        const hasValidYear = yearPracticeStarted.trim() !== '';
        const hasValidLicenses = licenses.some(license => license.state.trim() !== '' && license.licenseNumber.trim() !== '');
        // Add more validations as needed
        return hasValidYear && hasValidLicenses; // This is a simplified validation logic
    };

      

      useEffect(() => {
        const saveData = async () => {
          const practiceInfoRef = doc(db, `users/${uid}/applications/ipaApplication`);
      
          const practiceInfo = {
            yearPracticeStarted,
            medicareNumber,
            medicaidNumber,
            deaNumber,
            deaRestriction,
            restrictedExplanation,
            practiceType,
            licenses,
            groupMembers,
            alliedHealthProfessionals,
            yesNoAnswers,
          };
      
      
          try {
            await setDoc(practiceInfoRef, { PracticeInfo: practiceInfo }, { merge: true });
          } catch (error) {
            console.error("Error saving practice information:", error);
          }
        };
      
        saveData();
      }, [ yearPracticeStarted, medicareNumber, medicaidNumber, deaNumber, deaRestriction, restrictedExplanation, practiceType, licenses, groupMembers, alliedHealthProfessionals, yesNoAnswers]);
      

      useEffect(() => {
        if (!uid) return;

        const fetchData = async () => {
          const practiceInfoRef = doc(db, `users/${uid}/applications/ipaApplication`);
      
          try {
            const docSnap = await getDoc(practiceInfoRef);
            if (docSnap.exists()) {
              const data = docSnap.data().PracticeInfo;        

              setYearPracticeStarted(data.yearPracticeStarted || '');
              setMedicareNumber(data.medicareNumber || '');
              setMedicaidNumber(data.medicaidNumber || '');
              setDeaNumber(data.deaNumber || '');
              setDeaRestriction(data.deaRestriction || { unlimited: false, restricted: false });
              setRestrictedExplanation(data.restrictedExplanation || '');
              setPracticeType(data.practiceType || { solo: false, group: false, other: false, groupNPI: false });
              setLicenses(data.licenses || [{ state: '', licenseName: '', licenseNumber: '', expirationDate: '' }]);
              setGroupMembers(data.groupMembers || [{ name: '', specialty: '' }]);
              setAlliedHealthProfessionals(data.alliedHealthProfessionals || []);
              setYesNoAnswers(data.yesNoAnswers || {
                acceptingNewPatients: null,
                makeHouseCalls: null,
                provideSecondOpinions: null,
                treatWorkersComp: null,
                offer24HrCoverage: null,
                labServices: null,
                CLIA: null,
                radiology: null,
                alliedHealthProfessionals: false,
              });
            }
          } catch (error) {
            console.error("Error fetching practice information: ", error);
          }
        };
      
        fetchData();
      }, [uid]); // Empty dependency array means this effect runs once on mount
      

    const handleCheckboxNPIChange = (selectedType) => {
      // Set all practice types to false except the selected one
      const updatedPracticeType = {
        solo: false,
        group: false,
        other: false,
        groupNPI: false,
        [selectedType]: !practiceType[selectedType] // Toggle the selected type
      };
    
      setPracticeType(updatedPracticeType);
    };
    
  
    // Function to handle license change
    const handleLicenseChange = (index, field, value) => {
      const newLicenses = licenses.map((license, i) => {
        if (index === i) {
          return { ...license, [field]: value };
        }
        return license;
      });
      setLicenses(newLicenses);
    };
  
    // Function to add a new license
    const addLicense = () => {
      setLicenses([...licenses, { state: '', licenseName: '', licenseNumber: '', expirationDate: '' }]);
    };

    // Function to handle group member change
    const handleGroupMemberChange = (index, field, value) => {
        const newGroupMembers = groupMembers.map((member, i) => {
            if (index === i) {
            return { ...member, [field]: value };
            }
            return member;
         });
        setGroupMembers(newGroupMembers);
    };

    // Function to add a new group member
    const addGroupMember = () => {
        setGroupMembers([...groupMembers, { name: '', specialty: '' }]);
    };

    // Function to handle changes in yes/no questions
    const handleYesNoChange = (question, answer) => {
        setYesNoAnswers(prevState => ({
            ...prevState,
            [question]: answer,
        }));
        };

    const questions = {
        acceptingNewPatients: "Are you currently accepting new patients?",
        makeHouseCalls: "Do you make housecalls?",
        provideSecondOpinions: "Will you provide second opinions?",
        treatWorkersComp: "Do you treat workers' comp patients?",
        offer24HrCoverage: "Do you offer 24hr coverage for your patients?",
        labServices: "Do you offer lab services?",
        CLIA: "Do you have a CLIA?",
        radiology: "Do you offer radiology or imaging services?",
        };

    
      const addAlliedHealthProfessional = () => {
          setAlliedHealthProfessionals([...alliedHealthProfessionals, { name: '', type: '', state: '', licenseNo: '', expDate: '' }]);
      };
      
  
      const handleAlliedHealthProfessionalChange = (index, field, value) => {
          const newAlliedHealthProfessionals = alliedHealthProfessionals.map((professional, i) => {
              if (index === i) {
                return { ...professional, [field]: value };
              }
              return professional;
          });
          setAlliedHealthProfessionals(newAlliedHealthProfessionals);
      };
    
  

  return (
    <div className="">
    <h2 style={{textAlign:"left"}} >Practice Information</h2>
      <div className="input-group-row ipa-row">
              <div className="input-field">
                    <label htmlFor="yearPracticeStarted">Year Practice Started:</label>
                    <input
                      type="date"
                      id="yearPracticeStarted"
                      value={yearPracticeStarted}
                      onChange={(e) => setYearPracticeStarted(e.target.value)}
                    />

            </div>
      </div>

      
      <div className="input-group-row  ipa-row">
              <div className="input-field">        
              <label htmlFor="medicareNumber">Medicare Number:</label>
        <input
          type="text"
          id="medicareNumber"
          value={medicareNumber}
          onChange={(e) => setMedicareNumber(e.target.value)}
        />
      </div>
      <div className="input-field">        
        <label htmlFor="medicaidNumber">Medicaid Number:</label>
        <input
          type="text"
          id="medicaidNumber"
          value={medicaidNumber}
          onChange={(e) => setMedicaidNumber(e.target.value)}
        />
      </div>
    </div>
    <div className="input-group-row  ipa-row">
        <div className="input-field">
          <label htmlFor="deaNumber">DEA Number:</label>
          <input
            type="text"
            id="deaNumber"
            value={deaNumber}
            onChange={(e) => setDeaNumber(e.target.value)}
          />
        </div>
        <div className="input-group-row  ipa-row">
            <div className="input-field">
                <label>DEA Number Restrictions:</label>
                <div style={{ flexDirection: "column", display: "flex" }}>
                <label>
                    <input
                    type="radio"
                    name="deaRestriction"
                    className='custom-checkbox'
                    checked={deaRestriction === 'unlimited'}
                    onChange={() => setDeaRestriction('unlimited')}
                    />
                    Unlimited
                </label>
                <label>
                    <input
                    type="radio"
                    name="deaRestriction"
                    className='custom-checkbox'
                    checked={deaRestriction === 'restricted'}
                    onChange={() => setDeaRestriction('restricted')}
                    />
                    Restricted
                </label>
                </div>
            </div>
  {deaRestriction === 'restricted' && (
    <div className="input-field">
      <label htmlFor="restrictedExplanation">If Restricted, please explain:</label>
      <input
        type="text"
        id="restrictedExplanation"
        value={restrictedExplanation}
        onChange={(e) => setRestrictedExplanation(e.target.value)}
      />
    </div>
  )}
</div>
</div>

      {/* License section */}
      <div className="licenses-section">
        <h3 style={{textAlign:"center"}}>Licenses:</h3>
        {licenses.map((license, index) => (
          <div key={index} className="input-group-row">
            <div className="input-field">
              <label htmlFor={`state-${index}`}>State:</label>
              <select 
                  id={`state-${index}`} 
                  name="state" 
                  value={license.state} 
                  onChange={(e) => handleLicenseChange(index, "state", e.target.value)} 
                >
                  <option value="">Select a State</option>
                  {states.map(s => <option key={s} value={s}>{s}</option>)}
                </select>
            </div>
            <div className="input-field">
              <label htmlFor={`licenseName-${index}`}>License Name:</label>
              <input
                type="text"
                id={`licenseName-${index}`}
                value={license.licenseName}
                onChange={(e) => handleLicenseChange(index, 'licenseName', e.target.value)}
              />
            </div>
            <div className="input-field">
              <label htmlFor={`licenseNumber-${index}`}>License Number:</label>
              <input
                type="text"
                id={`licenseNumber-${index}`}
                value={license.licenseNumber}
                onChange={(e) => handleLicenseChange(index, 'licenseNumber', e.target.value)}
              />
            </div>
            <div className="input-field">
              <label htmlFor={`expirationDate-${index}`}>Expiration Date:</label>
              <input
                type="date"
                id={`expirationDate-${index}`}
                value={license.expirationDate}
                onChange={(e) => handleLicenseChange(index, 'expirationDate', e.target.value)}
              />
            </div>
          </div>
        ))}
        <div className='ipa-button-add'>
          <button onClick={addLicense}>Add Another License</button>
        </div>
      </div>
      {/* Group Members section */}
      <div className="group-members-section">
        <h3 style={{textAlign:"center"}}>Members of the Group</h3>
        {groupMembers.map((member, index) => (
          <div key={index} className="input-group-row">
            <div className="input-field">
              <label htmlFor={`memberName-${index}`}>Name:</label>
              <input
                type="text"
                id={`memberName-${index}`}
                value={member.name}
                onChange={(e) => handleGroupMemberChange(index, 'name', e.target.value)}
              />
            </div>
            <div className="input-field">
              <label htmlFor={`memberSpecialty-${index}`}>Specialty:</label>
              <input
                type="text"
                id={`memberSpecialty-${index}`}
                value={member.specialty}
                onChange={(e) => handleGroupMemberChange(index, 'specialty', e.target.value)}
              />
            </div>
          </div>
        ))}
       <div className='ipa-button-add'>
        <button onClick={addGroupMember}>Add Another Member</button>
      </div>
     </div>
     <div style={{marginBottom:"2rem"}} className="yes-no-questions-section">
      <div className="input-field">        
        <label>Practice Type:</label>
        <div style={{flexDirection: "column", display: "flex"}}>
          <label>
            <input
              type="checkbox"
              className='custom-checkbox'
              checked={practiceType.solo}
              onChange={() => handleCheckboxNPIChange('solo')}
            />
            Solo
          </label>
          <label>
            <input
              type="checkbox"
              className='custom-checkbox'
              checked={practiceType.group}
              onChange={() => handleCheckboxNPIChange('group')}
            />
            Group
          </label>
          <label>
            <input
              type="checkbox"
              className='custom-checkbox'
              checked={practiceType.other}
              onChange={() => handleCheckboxNPIChange('other')}
            />
            Other
          </label>
          <label>
            <input
              type="checkbox"
              className='custom-checkbox'
              checked={practiceType.groupNPI}
              onChange={() => handleCheckboxNPIChange('groupNPI')}
            />
            Group NPI
          </label>
        </div>
      </div>      
      </div>
   <>
    {/* Adjusted Yes/No Question for Allied Health Professionals */}
    <div className=" yes-no-questions-section form-question">
                <label>Do you have any allied health professionals providing patient care in your practice?</label>
                <div style={{ flexDirection: "column", display: "flex", marginBottom: "2rem" }}>
                <label>                    
                    <input
                        type="checkbox"
                        className='custom-checkbox'
                        name="alliedHealthProfessionals"
                        checked={yesNoAnswers.alliedHealthProfessionals === true}
                        onChange={() => handleYesNoChange('alliedHealthProfessionals', true)}
                    /> Yes
                    </label>
                <label>                    
                    <input
                        type="checkbox"
                        className='custom-checkbox'
                        name="alliedHealthProfessionals"
                        checked={yesNoAnswers.alliedHealthProfessionals === false}
                        onChange={() => handleYesNoChange('alliedHealthProfessionals', false)}
                    /> No
                    </label>
                </div>
            </div>

            {/* Directly use yesNoAnswers.alliedHealthProfessionals to control visibility */}
            {yesNoAnswers.alliedHealthProfessionals && (
              <div id="allied-health-info">
                  {alliedHealthProfessionals.map((professional, index) => (
                      <div key={index} className="input-group-row">
                          <div className="input-field">
                          <label htmlFor={`name-${index}`}>Name:</label>
                              <input
                                  type="text"
                                  value={professional.name}
                                  onChange={(e) => handleAlliedHealthProfessionalChange(index, 'name', e.target.value)}
                              />
                          </div>
                          <div className="input-field">
                          <label htmlFor={`name-${index}`}>License Type:</label>
                              <input
                                  type="text"
                                  value={professional.type}
                                  onChange={(e) => handleAlliedHealthProfessionalChange(index, 'type', e.target.value)}
                              />
                          </div>
                          <div className="input-field">
                          <label htmlFor={`name-${index}`}>License Number:</label>
                              <input
                                  type="text"
                                  value={professional.licenseNo}
                                  onChange={(e) => handleAlliedHealthProfessionalChange(index, 'licenseNo', e.target.value)}
                              />
                          </div>
                          <div className="input-field">
                              <select
                                  value={professional.state}
                                  onChange={(e) => handleAlliedHealthProfessionalChange(index, 'state', e.target.value)}
                              >
                                  <option value="">Select a State</option>
                                  {states.map((state) => (
                                      <option key={state} value={state}>{state}</option>
                                  ))}
                              </select>
                          </div>
                          <div className="input-field">
                              <input
                                  type="date"
                                  placeholder="Expiration Date"
                                  value={professional.expDate}
                                  onChange={(e) => handleAlliedHealthProfessionalChange(index, 'expDate', e.target.value)}
                              />
                          </div>
                      </div>
                  ))}
                <div className='ipa-button-add'>
                  <button onClick={addAlliedHealthProfessional}>+ Add Allied Health Professional</button>
              </div>
            </div>
          )}
            </>  




                    {/* Yes/No Questions section with separate Yes and No checkboxes */}
           <div className="yes-no-questions-section input-group-row">
          {Object.entries(questions).map(([questionKey, questionText]) => (
            <div key={questionKey} style={{ flexDirection: "column", display: "flex", marginBottom: "2rem" }}>
              <div>{questionText}</div>
              <label>
                <input
                  type="checkbox"
                  className='custom-checkbox'
                  checked={yesNoAnswers[questionKey] === true}
                  onChange={() => handleYesNoChange(questionKey, true)}
                />
                Yes
              </label>
              <label>
                <input
                  type="checkbox"
                  className='custom-checkbox'
                  checked={yesNoAnswers[questionKey] === false}
                  onChange={() => handleYesNoChange(questionKey, false)}
                />
                No
              </label>
            </div>
          ))}
        </div>
      </div>
  );
}

export default StepFour;
