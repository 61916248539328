import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faFileInvoiceDollar, faBrain, faMagnifyingGlassDollar, faUser } from '@fortawesome/free-solid-svg-icons';

const SupportFeatured = () => {
    const [selectedId, setSelectedId] = useState(null);

    const handleClick = (id) => {
        setSelectedId(selectedId === id ? null : id);
    };

    const sections = [
        { 
            id: 1, 
            title: 'New to Patients?', 
            icon: faHeart,
            articles: [
                { title: 'Adding Patients', link: 'https://popularishealth.com/article/Adding-Patients' },
                { title: 'Clinics', link: 'https://popularishealth.com/article/Clinics' },
                { title: 'Encounters', link: '/improving-patient-intake' },
                { title: 'Downloading Patient Data', link: 'https://popularishealth.com/article/Downloading-Patients-Data' },
                { title: 'Editing or Deleting Patients', link: 'https://popularishealth.com/article/Editing-or-Deleting-Patients' },
                { title: 'Scheduling a Zoom Call', link: 'https://popularishealth.com/article/How-to-Schedule-a-Zoom-Call' },
                { title: 'Using Notes', link: 'https://popularishealth.com/article/Using-Note' },
                { title: 'Medical History', link: 'https://popularishealth.com/article/Medical-History' },
                { title: 'Flags', link: 'https://popularishealth.com/article/Flags' },
                { title: 'Activities', link: 'https://popularishealth.com/article/Activities' },
                { title: 'Billing from Patients', link: 'https://popularishealth.com/article/Billing-for-Patients' },

            ],
        },
        { 
            id: 2, 
            title: 'New to Claims?', 
            icon: faFileInvoiceDollar,
            articles: [
                { title: 'Adding Providers', link: 'https://popularishealth.com/article/Adding-Providers' },
                { title: 'Adding Billing Codes', link: 'https://popularishealth.com/article/Adding-Billing-Codes' },
                { title: 'Viewing Claims', link: 'https://popularishealth.com/article/Viewing-Claims' },
                { title: 'Batch Claims', link: 'https://popularishealth.com/article/Sending-Batch-Claims' },
                { title: 'Downloading Claims Data', link: 'https://popularishealth.com/article/Downloading-Claims-Data' },
                { title: 'Getting Claim Status', link: 'https://popularishealth.com/article/Getting-Claim-Status' },
            ],
        },
        { 
            id: 3, 
            title: 'Articles on AI', 
            icon: faBrain,
            articles: [
                { title: 'How to Use PopularisAI', link: 'https://popularishealth.com/article/How-to-Use-PopularisAI' },
                { title: 'Navigating the Healthcare Information Maze: The Perils of Search Engines and Research Articles.', link: 'https://popularishealth.com/article/Navigating-the-Healthcare-Information-Maze--The-Perils-of-Search-Engines-and-Research-Articles' },
            ],
        },
        { 
            id: 4, 
            title: 'Articles on Billing', 
            icon: faMagnifyingGlassDollar,
            articles: [
                { title: 'The Anatomy of Medical Billing', link: 'https://popularishealth.com/article/The-Anatomy-of-Medical-Billing' },
                { title: 'Why Patient Self-Service is the Future of Medical Billing', link: 'https://popularishealth.com/article/Why-Patient-Self-Service-is-the-Future-of-Medical-Billing' },
                { title: 'Navigating the Complexity of Healthcare Coding: Challenges and Solutions', link: 'https://popularishealth.com/article/Navigating-the-Complexity-of-Healthcare-Coding--Challenges-and-Solutions' },
                { title: 'The Power of Batch Claim Submissions: Managing Large Scale Healthcare Operations Amidst a Pandemic.', link: 'https://popularishealth.com/article/Harnessing-the-Power-of-Batch-Claim-Submissions--Managing-Large-Scale-Healthcare-Operations-Amidst-a-Pandemic' },
            ],
        },
        { 
            id: 5, 
            title: 'Account', 
            icon: faUser,
            articles: [
                { title: 'How to Change Your Password.', link: 'https://popularishealth.com/article/How-to-Change-Your-Password' },
                { title: 'How to Update Your Credit Card.', link: 'https://popularishealth.com/article/How-to-Update-Your-Credit-Card' },
                { title: 'How to Cancel Your Subscription.', link: 'https://popularishealth.com/article/How-to-Cancel-Your-Subscription' },
            ],

        },
    ];

    return (
        <div className="Support-featured-articles-container FAQ-container">
            {sections.map(({ id, title, icon, articles }) => (
                <div key={id}>
                    <h2 className='typography-label-alt' onClick={() => handleClick(id)}>
                        <FontAwesomeIcon style={{ height: '30px', marginBottom: "-4px", color: "00316f" }}icon={icon} />{" "}
                        {selectedId === id ? "-" : "+"}{" "}{title}
                    </h2>
                    {selectedId === id && (
                        <ul>
                            {articles.map((article, index) => (
                                <li className='featuredArticles-li' key={index}>
                                    <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    );
};

export default SupportFeatured;