import React from 'react';
import styles from './quicksearchpopup.module.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from '../../popularis_logos/Popularis_logo_single.png'

const downloadPDFReport = (firstName, lastName) => {
  const input = document.getElementById('capture');
  if (!input) return;

  html2canvas(input, {
    scale: 1, // Reduce scale to lower the resolution and size
    useCORS: true, // Handle cross-origin images
    logging: true, // Enable for debugging, can be turned off for production
    letterRendering: true,
  }).then(canvas => {
    // Convert the canvas to a data URL with lower quality
    const imgData = canvas.toDataURL('image/jpeg', 0.5); // Change to JPEG and reduce quality to 0.5

    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297;  // A4 height in mm
    const topMargin = 10; // Top margin in mm
    const imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;

    const doc = new jsPDF('p', 'mm');
    let position = topMargin; // Start with the top margin

    // Add content to PDF
    doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight - 20; // Adjust for the initial page

    // Handling multiple pages if needed
    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    const fileName = `EligibilityReport_${firstName}_${lastName}.pdf`;
    doc.save(fileName);
  });
};


const SectionTitle = ({ title }) => <h3>{title}</h3>;
const InfoRow = ({ label, value }) => value ? <p>{`${label}: ${value}`}</p> : null;
const AddressInfo = ({ address }) => {
  // Combine city, state, and postalCode for the second row
  const cityStatePostal = [address?.city, address?.state, address?.postalCode].filter(Boolean).join(', ');

  return (
    <div>
      <h4>Address:</h4>
      {/* First row: address1 and optionally address2 */}
      <p>{address?.address1}{address?.address2 ? `, ${address.address2}` : ''}</p>
      
      {/* Second row: city, state, and postal code */}
      {cityStatePostal && <p>{cityStatePostal}</p>}
    </div>
  );
};

const ContactInfo = ({ contacts }) => {
  // Function to format telephone numbers
  const formatPhoneNumber = (phoneNumber) => {
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
  };

  // Ensure contacts is an array before mapping
  if (!Array.isArray(contacts) || contacts.length === 0) {
    return null;
  }

  return (
    <>
      {contacts.map((contact, index) => (
        <div key={index}>
          <p>Contact Channel: {contact.communicationMode}</p>
          <p>Number: {contact.communicationMode === 'Telephone' ? formatPhoneNumber(contact.communicationNumber) : contact.communicationNumber}</p>
        </div>
      ))}
    </>
  );
};



function QuicksearchData({ patientData, onClose }) {
  const eligibilityData = patientData?.eligibilityData || {}; 
  const planStatus = patientData?.eligibilityData?.planStatus?.[0]?.status || "Status Unknown";
  const insuranceName = patientData?.eligibilityData?.payer?.name || "Insurance Name Unknown";
  const { firstName, lastName, dob, gender } = patientData?.eligibilityData?.subscriber || {};

  return (
    <div className='results'>
      <div className='resultsContainer'>
        <span onClick={onClose} className="filesCloseButton">X</span>
        <button onClick={() => downloadPDFReport(firstName, lastName)}>Download PDF</button>

        <div id="capture" className={styles.dataContainer}>
          {eligibilityData && eligibilityData !== "Patient not found" && (
             <>
              {/* Plan Status and Payer Name */}
              <div className={styles.Overview}>
                <h2>{insuranceName} - {planStatus}</h2>
              </div>
              {/* Subscriber Information */}
              <div className={styles.SubscriberInfo}>
                <h2>{`${firstName} ${lastName}`}</h2>
                <p>{dob}{'      '}{gender}</p>
              </div>
              <div className={styles}>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                  <div>
                <PlanStatus planStatus={eligibilityData.planStatus} />
                <PlanInfo planInfo={eligibilityData.planInformation} />
                </div>
                <div>
                <SubscriberInfo subscriber={eligibilityData.subscriber} />
                </div>
                </div>
                <DependentInfo dependents={eligibilityData.dependents} />
                <BenefitsInfo benefitsInformation={eligibilityData.benefitsInformation} />
                <ErrorsInfo errors={eligibilityData.errors} />
                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'flex-end', marginTop: '20px' }}>
                  <img src={logo} alt="Logo" style={{ width: '40px' }} />
                <div></div> 
              </div>              
            </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const SubscriberInfo = ({ subscriber }) => {
  if (!subscriber) return null; // Return null if subscriber data is not available

  const {
    firstName, lastName, middleName, memberId,
    entityType, groupNumber, entityIdentifier,
    Address1, Address2, City, State, Zip
  } = subscriber; // Corrected to use subscriber instead of data

  return (
    <div>
      <SectionTitle title="Subscriber" />
      <InfoRow label="First Name" value={firstName} />
      <InfoRow label="Last Name" value={lastName} />
      <InfoRow label="Middle Name" value={middleName} />
      <InfoRow label="Member ID" value={memberId} />
      <InfoRow label="Entity Type" value={entityType} />
      <InfoRow label="Group Number" value={groupNumber} />
      <InfoRow label="Entity Identifier" value={entityIdentifier} />
      {Address1 && (
        <>
          <SectionTitle title="Address" />
          <AddressInfo address={{ address1: Address1, address2: Address2, city: City, state: State, postalCode: Zip }} />
        </>
      )}
    </div>
  );
};


const DependentInfo = ({ dependents }) => {
  if (!dependents || dependents.length === 0) return null;

  return (
    <div>
      <SectionTitle title="Dependents" />
      {dependents.map((dependent, index) => (
        <div key={index}>
          <InfoRow label="First Name" value={dependent.firstName} />
          <InfoRow label="Last Name" value={dependent.lastName} />
          <InfoRow label="Middle Name" value={dependent.middleName} />
          <InfoRow label="Date of Birth" value={dependent.dateOfBirth} />
          <InfoRow label="Gender" value={dependent.gender} />
          <InfoRow label="Member ID" value={dependent.memberId} />
          <InfoRow label="Relation to Subscriber" value={dependent.relationToSubscriber} />
          <InfoRow label="Entity Type" value={dependent.entityType} />
          <InfoRow label="Group Number" value={dependent.groupNumber} />
          <InfoRow label="Entity Identifier" value={dependent.entityIdentifier} />
          {dependent.address1 && <SectionTitle title="Address" />}
          <AddressInfo address={{ address1: dependent.address1, address2: dependent.address2, city: dependent.city, state: dependent.state, postalCode: dependent.postalCode }} />
        </div>
      ))}
    </div>
  );
};

const PlanInfo = ({ planInfo }) => {
  if (!planInfo) return null; 
  const { planNumber, groupNumber, groupDescription, planBegin } = planInfo;

  return (
    <div>
      <InfoRow label="Plan Number" value={planNumber} />
      <InfoRow label="Group Number" value={groupNumber} />
      <InfoRow label="Group Description" value={groupDescription} />
      <InfoRow label="Plan Begin" value={planBegin} />
    </div>
  );
};


const PlanStatus = ({ planStatus }) => {
  if (!planStatus || planStatus.length === 0) return null;

  return (
    <div>
      <SectionTitle title="Plan Status" />
      {planStatus.map((status, index) => (
        <div key={index}>
          <InfoRow label="Status Code" value={status.statusCode} />
          <InfoRow label="Status" value={status.status} />
          <InfoRow label="Plan Details" value={status.planDetails} />
          <InfoRow label="Service Type Codes" value={status.serviceTypeCodes.join(', ')} />
        </div>
      ))}
    </div>
  );
};

// Helper function to format values based on their type
const formatValue = (value, type) => {
  switch (type) {
    case 'amount':
      return value !== null ? `$${parseFloat(value).toFixed(2)}` : '';
    case 'percent':
      return value !== null ? `${(parseFloat(value) * 100).toFixed(2)}%` : '';
    case 'boolean':
      return value ? "Yes" : "No";
    default:
      return value;
  }
};

const renderBenefitsTable = (benefits) => {
  // Determine which columns should be included based on the data
  const columnData = [
    { key: 'coverageLevel', label: 'Coverage Level', format: 'text' },
    { key: 'insuranceType', label: 'Insurance Type', format: 'text' },
    { key: 'planCoverage', label: 'Plan Coverage', format: 'text' },
    { key: 'benefitAmount', label: 'Benefit Amount', format: 'amount' },
    { key: 'benefitPercent', label: 'Benefit Percent', format: 'percent' },
    { key: 'inPlanNetworkIndicator', label: 'In-Plan Network Indicator', format: 'text' },
    { key: 'authOrCertIndicator', label: 'Authorization or Certification Required', format: 'boolean' }
  ];

  // Filter columns that have data in any of the benefits
  const activeColumns = columnData.filter(column =>
    benefits.some(benefit => benefit[column.key] != null)
  );

  return (
    <table className={styles.BenefitsTable}>
      
      <thead>
        <tr>
          {activeColumns.map(column => <th key={column.key}>{column.label}</th>)}
        </tr>
      </thead>
      <tbody>
        {benefits.map((benefit, index) => (
          <tr key={index}>
            {activeColumns.map(column => (
              <td key={column.key}>{formatValue(benefit[column.key], column.format)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};



const renderInfoRow = (label, value, key) => {
  // Placeholder function - you should replace this with actual implementation
  return value ? <div key={key}><strong>{label}:</strong> {value}</div> : null;
};

const BenefitsInfo = ({ benefitsInformation }) => {
  if (!benefitsInformation || benefitsInformation.length === 0) return null;

  // Group benefits by name
  const groupedBenefits = benefitsInformation.reduce((acc, benefit) => {
    if (!acc[benefit.name]) acc[benefit.name] = [];
    acc[benefit.name].push(benefit);
    return acc;
  }, {});

  return (
    <>
      <SectionTitle title="Benefits Information" />
      <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
      <div className={styles.BenefitsInfo}>
        {Object.entries(groupedBenefits).map(([benefitName, benefits], index, array) => {
          // Define shouldDisplayInTable here based on your criteria, example given below
          const shouldDisplayInTable = benefits.length > 1;

          return (
            <React.Fragment key={index}>
              <div className={styles.BenefitsCard}>
                <h3 className={styles.BenefitName}>{benefitName}</h3>
                {shouldDisplayInTable ? (
                  // Assuming renderBenefitsTable is a function that properly formats and displays benefits in a table format
                  renderBenefitsTable(benefits)
                ) : (
                  benefits.map((benefit, benefitIndex) => (
                    <React.Fragment key={benefitIndex}>
                    
                      {/* Render individual benefit details if not using a table */}
                      {renderInfoRow("Coverage Level", benefit.coverageLevel, `coverage-${benefitIndex}`)}
                      {renderInfoRow("Insurance Type", benefit.insuranceType, `insuranceType-${benefitIndex}`)}
                      {renderInfoRow("Plan Coverage", benefit.planCoverage, `planCoverage-${benefitIndex}`)}
                      {renderInfoRow("Benefit Amount", benefit.benefitAmount, `benefitAmount-${benefitIndex}`)}
                      {renderInfoRow("Benefit Percent", benefit.benefitPercent, `benefitPercent-${benefitIndex}`)}
                      {renderInfoRow("In-Plan Network Indicator", benefit.inPlanNetworkIndicator, `inPlanNetworkIndicator-${benefitIndex}`)}
                      {renderInfoRow("Authorization or Certification Required", benefit.authOrCertIndicator ? "Yes" : "No", `authOrCertIndicator-${benefitIndex}`)}
                {benefit.additionalInformation && benefit.additionalInformation.length > 0 && (
                  <div className={styles.AdditionalInformation}>
                    <h4>Additional Information:</h4>
                    {benefit.additionalInformation.map((info, infoIndex) => (
                      <p key={`additional-${benefitIndex}-${infoIndex}`}>{info.description}</p>
                    ))}
                  </div>
                )}

                {benefit.benefitsDateInformation && (
                  <div className={styles.DateInformation}>
                    <h4>Date Information:</h4>
                    {Object.entries(benefit.benefitsDateInformation).map(([key, value]) => (
                      renderInfoRow(key.charAt(0).toUpperCase() + key.slice(1), value, `dateInfo-${benefitIndex}-${key}`)
                    ))}
                  </div>
                )}

                {benefit.benefitsRelatedEntity && (
                  <div className={styles.RelatedEntity}>
                    <h4>Primary Entity:</h4>
                    {renderInfoRow("Entity Name", benefit.benefitsRelatedEntity.entityName, `entityName-${benefitIndex}`)}
                    <AddressInfo address={benefit.benefitsRelatedEntity.address} />
                    <ContactInfo contacts={benefit.benefitsRelatedEntity.contactInformation} />
                  </div>
                )}

                {benefit.benefitsRelatedEntities && benefit.benefitsRelatedEntities.length > 0 && (
                  <div className={styles.RelatedEntities}>
                    <h4>Related Entities:</h4>
                    {benefit.benefitsRelatedEntities.map((entity, entityIndex) => (
                      <div key={`relatedEntity-${benefitIndex}-${entityIndex}`} className={styles.RelatedEntityCard}>
                        {renderInfoRow("Entity Name", entity.entityName, `entityName-${benefitIndex}-${entityIndex}`)}
                        <AddressInfo address={entity.address} />
                        <ContactInfo contacts={entity.contactInformation} />
                      </div>
                    ))}
                  </div>
                )}
                   </React.Fragment>
                  ))
                )}
              </div>
              {index < array.length - 1 && (
                <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};


const ErrorsInfo = ({ errors }) => {
  // Check if there are any errors to display
  if (!errors || errors.length === 0) return null;

  return (
    <div>
      <SectionTitle title="Errors" />
      {errors.map((error, index) => (
        <div key={index}>
          <InfoRow label="Field" value={error.field} />
          <InfoRow label="Code" value={error.code} />
          <InfoRow label="Description" value={error.description} />
          <InfoRow label="Follow-up Action" value={error.followupAction} />
          <InfoRow label="Location" value={error.location} />
          <InfoRow label="Possible Resolutions" value={error.possibleResolutions} />
        </div>
      ))}
    </div>
  );
};


export default QuicksearchData;
