import React, { useState, useEffect } from 'react';
import { storage, auth, db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import useUID from '../../General/useUID';

const UserIcon = () => {
  const [initials, setInitials] = useState('');
  const [uid] = useUID();

  useEffect(() => {
    const fetchUserName = async () => {
      if (uid) {
        const userDocRef = doc(db, 'users', uid);
        try {
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            // Assuming the fields are "firstName" and "lastName"
            const firstName = userData.firstName;
            const lastName = userData.lastName;
            const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
            setInitials(initials);
          } else {
            console.log('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserName();
  }, [uid]);

  return (
    <div className='usercircleContainer'>
      {uid ? (
        <span className="initialsCircle">
          {initials}
        </span>
      ) : (        
      <span className="initialsCircle">
        <FontAwesomeIcon icon={faUser} />        
        </span>
      )}
    </div>
  );
};

export default UserIcon;
