import React, { useEffect, useState, useRef } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import SignaturePad from "./SignaturePad";
import { auth, db } from "../../../firebase";
import "./Signatures.css";
import {
  collection,
  query,
  doc,
  getDocs,
  where,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import useUID from "../../General/useUID";

const functions = getFunctions();

export default function Signature({ onBack, onNext, onApplySignature }) {
  const [signatures, setSignatures] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [saveSignature, setSaveSignature] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const signatureRef = useRef(null);
  const [uid] = useUID();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (!uid) return;
    getSignatures();
  }, [uid]);

  useEffect(() => {
    setIsLoading(true);
    if (!uid) return; // do not proceed if uid is not available
    const listen = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const currentDate = new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          const currentTime = new Date().toLocaleTimeString("en-US", {
            timeZone: "UTC",
            hour12: false,
          });
          setUserName(
            `${userData?.firstName} ${userData?.lastName} ${currentDate} ${currentTime} UTC`
          );
          setIsLoading(false);
        }
      }
    });

    return () => {
      setIsLoading(false);
      listen();
    };
  }, [uid]);

  useEffect(() => {
    if (signatures.length > 0) {
      const sortedSignatures = signatures.sort(
        (a, b) => b.createdDate - a.createdDate
      );
      const latestSignature = sortedSignatures[0];
      setSelectedSignature(latestSignature);
    }
  }, [signatures]);

  const getSignatures = async () => {
    setIsLoading(true);
    try {
      const signaturesSnapshot = await getDocs(
        query(collection(db, "signatures"), where("userId", "==", uid))
      );

      const signaturesList = signaturesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdDate: formatTimestamp(doc.data().createdDate),
        userName: doc.data().userName || "Unnamed", // Fallback for signatures without a user name
      }));

      setSignatures(signaturesList);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching signatures:", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString();
  };

  const handleBackClick = () => {
    onBack();
  };

  const handleSignatureSelect = (signatureId) => {
    const selected = signatures.find(
      (signature) => signature.id === signatureId
    );
    setSelectedSignature(selected);
  };

  const handleSignatureCreate = async (signatureData) => {
    setIsLoading(true);
    try {
      // Add the user's name to the signature data
      const signatureWithUserName = {
        ...signatureData,
        userName: userName, // Save user name along with signature data
      };

      const signaturesRef = collection(db, "signatures");
      const newSignatureDocRef = doc(signaturesRef);

      await setDoc(newSignatureDocRef, signatureWithUserName).then(() => {
        getSignatures();
      });

      //Logs
      const addLogFunction = httpsCallable(functions, "addLog");
      await addLogFunction({
        uid,
        message: "Signature created with user name.",
      });
      setIsLoading(false);
      return newSignatureDocRef.id;
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding signature to Firestore:", error);
      throw error;
    }
  };

  const handleApplySignature = async () => {
    setIsLoading(true);
    let signatureData = null;

    if (!selectedSignature && !signatureRef.current) {
      setIsLoading(false);
      return; // Return early if no signature is available
    }

    if (selectedSignature) {
      signatureData = selectedSignature;
    } else {
      if (!signatureRef.current) return;

      signatureData = {
        userId: uid,
        imageData: signatureRef.current.toDataURL(),
        createdDate: new Date(),
        userName,
      };

      if (saveSignature) {
        await handleSignatureCreate(signatureData);
      }
    }

    if (signatureData) {
      onApplySignature(signatureData);
      onNext();
      setIsLoading(false);
    }
  };

  const handleDeleteSignature = async (signatureId) => {
    setIsLoading(true);
    try {
      const signatureDocRef = doc(collection(db, "signatures"), signatureId);
      await deleteDoc(signatureDocRef);

      const updatedSignatures = signatures.filter(
        (signature) => signature.id !== signatureId
      );
      setSignatures(updatedSignatures);
      setSelectedSignature(null);
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting signature:", error);
      setIsLoading(false);
    }
  };

  const handleSignatureRefChange = (ref) => {
    signatureRef.current = ref;
  };

  return (
    <div className="signature-container">
      <div className="signature-list">
        <h2>Your Signatures</h2>
        <select
          onChange={(e) => handleSignatureSelect(e.target.value)}
          value={selectedSignature ? selectedSignature.id : "create"}
        >
          {signatures.map((signature) => (
            <option key={signature.id} value={signature.id} disabled={isLoading}>
              {signature.userName
                ? `${signature.userName}`
                : `Signature - ${signature.createdDate}`}
            </option>
          ))}
          <option value="create">+ Create Signature</option>
        </select>
        <div className="button-group-signature">
          <button
            onClick={handleApplySignature}
            disabled={!selectedSignature && isSignatureEmpty || isLoading}
            className="next-button"
          >
            Save note with signature
          </button>
          <button onClick={handleBackClick} className="back-button" disabled={isLoading}>
            Back
          </button>
        </div>
      </div>
      <div className="signature-pad">
        {selectedSignature ? (
          <>
            <h2>Existing Signature</h2>
            <div className="signature-preview">
              <img src={selectedSignature.imageData} alt="Selected Signature" />
              <button
                onClick={() => handleDeleteSignature(selectedSignature.id)}
                className="back-button"
                disabled={isLoading}
              >
                Delete Signature
              </button>
            </div>
          </>
        ) : (
          <>
            <h2>Create a New Signature</h2>
            <SignaturePad
              onSignatureRefChange={handleSignatureRefChange}
              isSignatureEmpty={(isEmpty) => setIsSignatureEmpty(isEmpty)}
              onSaveSignatureChange={(saveSignature) =>
                setSaveSignature(saveSignature)
              }
            />
          </>
        )}
      </div>
    </div>
  );
}
