import React from "react";
import Switch from "react-switch";

const GeneralSwitch = ({
  itemFields,
  fieldIndex,
  handleChange,
  label,
  property,
}) => {
  const handleSwitchChange = (checked) => {
    handleChange({ target: { value: checked } }, fieldIndex, property);
  };

  return (
    <>
      {itemFields?.[fieldIndex]?.label && (
        <label className="mt-3">{label}</label>
      )}
      <Switch
        className="ml-3"
        checked={itemFields?.[fieldIndex]?.[property] || false}
        onChange={handleSwitchChange}
        onColor="#00316f"
        offColor="#ccc"
      />
    </>
  );
};

export default GeneralSwitch;
