import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faUserMd, faHospital, faClinicMedical, faHandHoldingMedical,
  faStethoscope, faUserNurse, faAmbulance, faHeartbeat,
  faTooth, faEye, faBone, faSyringe, faPills, faBaby, faUserFriends, faRadiation, faAllergies, faVirus,
  faXRay, faMicroscope, faBrain, faLungs, faDna, faKeyboard 
} from '@fortawesome/free-solid-svg-icons';
import '../ipa.css';
import { doc, getDoc, setDoc, collection, addDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase"; 
import useUID from '../../General/useUID';

function StepOne({ nextStep, setCanProceed }) {
  const [selectedProviderTypes, setSelectedProviderTypes] = useState([]);
  const [uid] = useUID();

  useEffect(() => {
    // Enable the Next button if at least one provider type is selected
    setCanProceed(selectedProviderTypes.length > 0);
  }, [selectedProviderTypes, setCanProceed]);

  useEffect(() => {
    const fetchSelectedProviderTypes = async () => {
      const docRef = doc(db, "users", uid, "applications", "ipaApplication");
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists() && docSnap.data().providerType) {
        // Fetch the providerType field, which should be an array of selected types
        const providerTypes = docSnap.data().providerType;
        setSelectedProviderTypes(providerTypes);
      } else {
        // Initialize with empty array or default value as necessary
        setSelectedProviderTypes([]);
      }
    };
  
    if (uid) {
      fetchSelectedProviderTypes();
    }
  }, [uid]); // Dependency array includes uid to refetch if uid changes

  useEffect(() => {
    // Enable the Next button if at least one provider type is selected
    setCanProceed(selectedProviderTypes.length > 0);
  }, [selectedProviderTypes, setCanProceed]);
  

  const toggleProviderType = async (type) => {
    setSelectedProviderTypes(prev => {
      const updatedSelections = prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type];
      
      // Update Firestore document with the new selection
      const docRef = doc(db, "users", uid, "applications", "ipaApplication");
      updateDoc(docRef, { providerType: updatedSelections });

      return updatedSelections;
    });
  };

  const renderProviderCard = (type, icon, label) => (
    <div
      className={`provider-card ${selectedProviderTypes.includes(type) ? 'selected' : ''}`}
      onClick={() => toggleProviderType(type)}
    >
      <FontAwesomeIcon icon={icon} style={{ height: "40px" }} />
      <h3>{label}</h3>
    </div>
  );

  return (
      <>
        <h2 style={{textAlign: "left"}} >Let's figure out what kind of provider you are.</h2>
        <p style={{textAlign: "left"}} className='typography-label-alt'>Please select the cards that relate to your practice.</p>
      <form className="provider-type-selection-form">
        
        <div className="provider-type-cards">
          {renderProviderCard('organization', faBuilding, 'Organization')}
          {renderProviderCard('individualProvider', faUserMd, 'Individual Provider')}
          {renderProviderCard('hospital', faHospital, 'Hospital')}
          {renderProviderCard('fqhc', faClinicMedical, 'FQHC')}
        </div>
        <div className="provider-type-cards">
          {renderProviderCard('managedHealth', faHandHoldingMedical, 'Managed Health')}
          {renderProviderCard('privatePractice', faStethoscope, 'Private Practice')}
          {renderProviderCard('nursingHome', faUserNurse, 'Nursing Home')}
          {renderProviderCard('emergencyServices', faAmbulance, 'Emergency Services')}
        </div>
        <div className="provider-type-cards">
          {renderProviderCard('dentistry', faTooth, 'Dentistry')}
          {renderProviderCard('ophthalmology', faEye, 'Ophthalmology')}
          {renderProviderCard('orthopedics', faBone, 'Orthopedics')}
          {renderProviderCard('pharmacy', faPills, 'Pharmacy')}
        </div>
        <div className="provider-type-cards">
          {renderProviderCard('familyCare', faUserFriends, 'Family Care')}
          {renderProviderCard('radiology', faRadiation, 'Radiology')}
          {renderProviderCard('allergology', faAllergies, 'Allergology')}
          {renderProviderCard('infectiousDiseases', faVirus, 'Infectious Diseases')}
          
        </div>
        <div className="provider-type-cards">
         {renderProviderCard('diagnosticImaging', faXRay, 'Diagnostic Imaging')}
          {renderProviderCard('pediatrics', faBaby, 'Pediatrics')}
          {renderProviderCard('mentalHealth', faBrain, 'Mental Health')}
          {renderProviderCard('cardiology', faHeartbeat, 'Cardiology')}
        </div>

        <div className="provider-type-cards">
          {renderProviderCard('laboratorySciences', faMicroscope, 'Laboratory Sciences')}
          {renderProviderCard('neurology', faBrain, 'Neurology')}
          {renderProviderCard('pulmonology', faLungs, 'Pulmonology')}
          {renderProviderCard('manualInput', faKeyboard, 'Manual Input')}
        </div>
      </form>
      </>
  );
}

export default StepOne;
