import React, { useEffect, useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

function SignaturePad({
  onSignatureRefChange,
  isSignatureEmpty,
  onSaveSignatureChange,
}) {
  const signatureRef = useRef(null);
  const [isSaveSignatureChecked, setIsSaveSignatureChecked] = useState(true);

  useEffect(() => {
    if (signatureRef.current) {
      const isEmpty = signatureRef.current.isEmpty();
      isSignatureEmpty(isEmpty);
    }
  }, [signatureRef, isSignatureEmpty]);

  useEffect(() => {
    onSaveSignatureChange(isSaveSignatureChecked);
  }, [isSaveSignatureChecked, onSaveSignatureChange]);

  const handleClear = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      isSignatureEmpty(true); // Update the state to indicate the signature is empty
    }
  };

  const handleToggleSaveSignature = () => {
    setIsSaveSignatureChecked(!isSaveSignatureChecked);
  };

  const handleCanvasStart = () => {
    if (signatureRef.current) {
      const isEmpty = signatureRef.current.isEmpty();
      isSignatureEmpty(isEmpty);
    }
  };

  const handleSignatureChange = () => {
    if (signatureRef.current) {
      const isEmpty = signatureRef.current.isEmpty();
      isSignatureEmpty(isEmpty); // Update the state when signature changes
      onSignatureRefChange(signatureRef.current);
    }
  };

  return (
    <div>
      <SignatureCanvas
        ref={signatureRef}
        penColor="black"
        className="signature-canvas"
        canvasProps={{
          width: 200 * 1.618,
          height: 200,
          className: "signature-canvas",
        }}
        onEnd={handleSignatureChange} // Call handleSignatureChange when drawing ends
      />
      <button onClick={handleClear} className="next-button">
        Clear
      </button>

      <button
        className={`checkbox-button ${
          isSaveSignatureChecked ? "selected-button" : ""
        }`}
        onClick={handleToggleSaveSignature}
      >
        {isSaveSignatureChecked ? "✓" : " "}
      </button>
      <span>Save Signature</span>
    </div>
  );
}

export default SignaturePad;
