import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase'; 
import { collection, query, onSnapshot, orderBy } from 'firebase/firestore';
import { Table, Spinner, Alert, Button } from 'react-bootstrap'; // Using React Bootstrap for basic UI
import useUID from '../useUID';
import Topbar from '../Topbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal'; // Import React Modal
import { JSONTree } from 'react-json-tree';

const ApiQueueStatusPage = () => {
  const [queueData, setQueueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uid] = useUID(); // Get the current user's UID
  const [selectedResponse, setSelectedResponse] = useState(null); // State to track the selected response for the modal
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const navigate = useNavigate();

  useEffect(() => {
    if (!uid) return; // Wait until the UID is available

    const apiQueueRef = collection(db, 'users', uid, 'apiQueue'); // Use the current user's UID
    const q = query(apiQueueRef, orderBy('timestamp', 'desc')); // Order by timestamp in descending order

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setQueueData(data);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching API queue data:', error);
      setError(error.message);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [uid]);

  const handleViewResponse = (response) => {
    setSelectedResponse(response);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedResponse(null);
  };

  if (loading) {
    return <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>;
  }

  if (error) {
    return <Alert variant="danger">Error: {error}</Alert>;
  }

  return (
    <div className='background'>
      <Topbar />
      <div className="QueuePageContainer">
        <h2>API Queue Status</h2>
        <Table striped bordered hover style={{ width: '90vw' }}>
          <thead>
            <tr>
              <th>API Type</th>
              <th>Date</th>
              <th>Endpoint</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {queueData.map((item) => (
              <tr key={item.id}>
                <td>{item.apiType || 'N/A'}</td>
                <td>{item.timestamp ? new Date(item.timestamp.toDate()).toLocaleString() : 'N/A'}</td> {/* Check if timestamp exists and convert correctly */}
                <td>{item.callbackInfo?.length > 0 ? item.callbackInfo[0].URL : 'N/A'}</td> {/* Extract the first URL from callbackInfo */}
                <td>
                  <FontAwesomeIcon 
                    icon={faCircle} 
                    style={{ color: item.status === 'processed' ? 'green' : item.status === 'failed' ? 'red' : 'grey' }} 
                  /> {item.status}
                </td>
                <td>
                  {item.patientId && (
                    <Button
                      variant="primary"
                      onClick={() => navigate(`/patients/${item.patientId}`)}
                    >
                      Go to Encounter <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  )}
                   {item.responses && (
                    <Button 
                      variant="secondary" 
                      onClick={() => handleViewResponse(item.responses)}
                    >
                      View Response
                    </Button>
                  )}
                </td>

              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modal to display JSON response */}
      <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="View Response"
            className='confirmModal'
        >
            <h3>Server Response</h3>
            <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {selectedResponse ? (
                    <JSONTree data={selectedResponse} />
                ) : (
                    <p>No response available</p>
                )}
            </div>
            <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal>
    </div>
  );
};

export default ApiQueueStatusPage;
