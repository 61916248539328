import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileInvoiceDollar,
    faUserCheck,
    faShieldAlt,
    faLock,
    faUsers,
    faBrain,
    faClipboardList,
    faHospital,
    faChartBar,
    faHeadset,
    faCalendarAlt,
    faVideo
} from '@fortawesome/free-solid-svg-icons';

const FeaturesList = () => {
    const featuresList = [
        { name: 'Simple Claims System', icon: faFileInvoiceDollar },
        { name: 'Instant Eligibility', icon: faUserCheck },
        { name: 'Advanced Cybersecurity', icon: faShieldAlt },
        { name: 'HIPAA Compliance', icon: faLock },
        { name: 'Multiple Users', icon: faUsers },
        { name: 'Medically Trained AI', icon: faBrain },
        { name: 'Custom Encounters Forms', icon: faClipboardList },
        { name: 'Clinics with Custom Intake Forms', icon: faHospital },
        { name: 'Comprehensive Reporting and Analytics', icon: faChartBar },
        { name: 'US-Based Customer Support', icon: faHeadset },
        { name: 'Appointment System', icon: faCalendarAlt },
        { name: 'Telehealth with Zoom', icon: faVideo },
    ];

    return (
        <div className="features-section">
            <h2 className="section-title">Features Included in All Plans</h2>
            <p className={`section-description`}>All plans come with these powerful features</p>

            <div className="features-grid">
                {featuresList.map((feature, index) => (
                    <div key={index} className="feature-item">
                        <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
                        <p>{feature.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesList;