import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { Link, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { htmlToText } from 'html-to-text';
import Header from '../Website/subcomponents/Header';
import Footer from '../Website/subcomponents/Footer';
import SearchBar from './SearchBar';
import './SearchResults.css';

const SearchResults = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTermFromURL = queryParams.get('q');

  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState(searchTermFromURL || '');

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesCollection = collection(db, 'articles');

      const searchQuery = query(articlesCollection, orderBy('title'));

      const articleSnapshot = await getDocs(searchQuery);

      const articleList = articleSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(article => isMatch(article, searchTerm));

      setArticles(articleList);
    };

    fetchArticles();
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(searchTermFromURL || '');
  }, [searchTermFromURL]);

  const handleSearch = (searchTerm) => {
    const queryParams = new URLSearchParams({ q: searchTerm });
    setSearchTerm(searchTerm);
    window.history.replaceState(null, '', `?${queryParams.toString()}`);
  };

  const isMatch = (article, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const { title, keywords, article: articleContent } = article;

    // Check if the article title contains the search term
    if (title.toLowerCase().includes(lowerCaseSearchTerm)) {
      return true;
    }

    // Check if any of the keywords contain the search term
    if (keywords && keywords.some(keyword => keyword.toLowerCase().includes(lowerCaseSearchTerm))) {
      return true;
    }

    // Check if the article content contains the search term
    if (articleContent && articleContent.toLowerCase().includes(lowerCaseSearchTerm)) {
      return true;
    }

    return false;
  };

  return (
    <div className="">
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={handleSearch} />
      <div className="search-container">
        <ul>
          {articles.map((article) => (
            <li key={article.id}>
              <Link to={`/article/${article.id}`}>
                <h2>{article.title}</h2>
              </Link>
              {article.article && (
                <p>
                  {htmlToText(article.article.match(/<p>(.*?)<\/p>/)?.[1], {
                    wordwrap: null,
                    ignoreHref: true,
                    ignoreImage: true,
                    hideLinkHrefIfSameAsText: true,
                    uppercaseHeadings: false,
                    singleNewLineParagraphs: true,
                    ignoreTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div']
                  })?.slice(0, 100)}
                  ...
                </p>
              )}
              <Link to={`/article/${article.id}`}>Read More</Link>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default SearchResults;
