import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import { getDatabase, ref, onValue, off } from "firebase/database"; // Import Realtime Database functions

const DiagnosisCodesFrequencyTable = () => {
  const [loading, setLoading] = useState(true);
  const [diagnosisCodeData, setDiagnosisCodeData] = useState([]);
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (!uid) return;

    setLoading(true);
    const database = getDatabase();
    const diagnosisCodeRef = ref(
      database,
      `aggregateFrequencyData/${uid}/diagnosisCodeData`
    );

    const unsubscribe = onValue(
      diagnosisCodeRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the object to an array if needed
          const formattedData = Array.isArray(data)
            ? data
            : Object.values(data);
          setDiagnosisCodeData(formattedData);
        } else {
          setDiagnosisCodeData([]);
        }
        setLoading(false);
      },
      (error) => {
        // console.error(
        //   "Error fetching diagnosis code data from Realtime Database:",
        //   error
        // );
        setLoading(false);
      }
    );

    return () => off(diagnosisCodeRef, "value", unsubscribe); // Clean up the subscription
  }, [uid]);

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="FrequencyTableContainer">
      <h3>Diagnosis Codes</h3>
      <table>
        <thead>
          <tr>
            <th>Diagnosis Code</th>
            <th>Frequency</th>
          </tr>
        </thead>
        <tbody>
          {diagnosisCodeData.map((item, index) => (
            <tr key={index}>
              <td>{item.code}</td>
              <td>{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DiagnosisCodesFrequencyTable;
