import React, { useState, useEffect } from 'react';
import SingleClaim from "../../Claims/Tools/SingleClaim";
import '../PatientFiles.modules.css';
import HelpArticleLink from "../../Articles/HelpArticleLink";

function PatientFilesClaimsSend({ patientId, firstName, middleName, lastName, dob, gender, dos, memberid, address1, address2, city, state, zip, tradingPartnerName, onClose }) {
    
  function convertDobFormat(dob) {
    if (!dob) return '';
    const [month, day, year] = dob.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  

  return (
    <div className='actionContainer'>
        <div className="ClaimContainer">
            <button onClick={onClose} className="filesCloseButton">X</button>
            <div className='singleClaimContainer'>
                <SingleClaim
                initialFirstName={firstName}
                initialMiddleName={middleName}
                initialLastName={lastName}
                initialDob={convertDobFormat(dob)}
                initialGender={gender}
                initialmemberId={memberid}
                initialaddress1={address1}
                initialaddress2={address2}
                initialcity={city}
                initialstate={state}
                initialzip={zip}
                initialTradingPartnerName={tradingPartnerName}
                patientId={patientId}
                />
                 <div className="help-article-wide">
                  <HelpArticleLink 
                  article={{ 
                  title: 'Billing from Patients', 
                  link: 'https://popularishealth.com/article/Billing-for-Patients' 
                  }} 
                />
            </div>
            </div>
        </div>
    </div>
  );
}

export default PatientFilesClaimsSend;
