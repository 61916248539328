import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, off } from "firebase/database";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";

const Summary = () => {
  const [loading, setLoading] = useState(true);
  const [totalClaimsAmount, setTotalClaimsAmount] = useState(0);
  const [totalClaimsCount, setTotalClaimsCount] = useState(0);
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;

    setLoading(true);
    const database = getDatabase();
    const summaryRef = ref(database, `aggregateClaimsSummary/${uid}`); // Replace with your actual database path

    const unsubscribe = onValue(
      summaryRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setTotalClaimsAmount(data.totalAmount);
          setTotalClaimsCount(data.claimsCount);
        }
        setLoading(false);
      },
      (error) => {
        // console.error("Error fetching claims summary:", error);
        setLoading(false);
      }
    );

    return () => off(summaryRef, "value", unsubscribe); // Clean up the subscription
  }, [uid]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", { style: "decimal" }).format(number);
  };

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Calculating your account summary.</h4>
      </div>
    );
  }

  return (
    <div className="summary-container">
      <h3 style={{ textAlign: "center" }}>Summary</h3>
      <div className="summary-item">
        <p>Total Sent Claims Amount:</p>
        <h3>${formatNumber(totalClaimsAmount.toFixed(2))}</h3>
      </div>
      <div className="summary-item">
        <p>Total Claims Sent:</p>
        <h3>{totalClaimsCount}</h3>
      </div>
    </div>
  );
};

export default Summary;
