import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PayerSelection from "../../../Payers/PayerSelection";
import "../../AddPatient/AddPatient.modules.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../firebase"; // Import Firebase storage

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function InsuranceInformation({ onInsuranceInfoChange }) {
  let { clinic } = useParams() || {};

  const [memberId, setMemberId] = useState("");
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [insuranceCardImage, setInsuranceCardImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);

  const handleMemberIdChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setMemberId(sanitizedValue);

    if (onInsuranceInfoChange) {
      onInsuranceInfoChange({
        memberId: sanitizedValue,
        selectedPayer,
        insuranceCardImage,
      });
    }
  };

  const uploadInsuranceCard = async (file) => {
    const storageRefPath = storageRef(storage, `PatientDocuments/${clinic}/${file.name}`);
    await uploadBytes(storageRefPath, file);
    const url = await getDownloadURL(storageRefPath);
    setInsuranceCardImage(url);

    if (onInsuranceInfoChange) {
      onInsuranceInfoChange({
        memberId,
        selectedPayer,
        insuranceCardImage: url,
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      uploadInsuranceCard(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePayerSelect = (payer) => {
    setSelectedPayer(payer);
    if (onInsuranceInfoChange) {
      onInsuranceInfoChange({
        memberId,
        selectedPayer: payer,
        insuranceCardImage,
      });
    }
  };

  const handlePayerUnselect = () => {
    setSelectedPayer(null);
  };

  const checkCameraPermission = async () => {
    if (navigator.permissions) {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "camera",
        });
        if (permissionStatus.state === "denied") {
          alert(
            "Camera permission has been denied. Please allow camera access in browser settings."
          );
        }
        return permissionStatus.state;
      } catch (error) {
        console.error("Error checking camera permission:", error);
      }
    } else {
      console.warn("Permissions API is not supported on this browser.");
      return "unknown";
    }
  };

  const onCameraClick = async () => {
    if (isMobile) {
      const videoElement = document.getElementById("videoPreview");
      try {
        const constraints = {
          video: {
            facingMode: "environment",
          },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        videoElement.onloadedmetadata = () => {
          videoElement.play();
        };

        videoElement.srcObject = stream;

        videoElement.addEventListener("error", (errorEvent) => {
          console.error("Video element error:", errorEvent);
        });

        stream.getVideoTracks()[0].addEventListener("ended", () => {
          console.warn("The video track ended unexpectedly!");
        });

        videoElement.style.display = "block";
        document.getElementById("captureButton").style.display = "block";

        document.getElementById("startCameraButton").style.display = "none";
      } catch (error) {
        console.error("Error accessing camera:", error);
        alert(
          "Couldn't access the camera. Please enable camera permissions in the browser settings."
        );
      }
    } else {
      document.getElementById("fileInput").click();
    }
  };

  const capturePhoto = () => {
    const videoElement = document.getElementById("videoPreview");
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      const capturedFile = new File([blob], "InsuranceCardfromIntake.jpeg", {
        type: "image/jpeg",
      });

      setFile(capturedFile);

      const dataURL = URL.createObjectURL(blob);
      setPreviewImage(dataURL);

      uploadInsuranceCard(capturedFile);
    }, "image/jpeg");

    const tracks = videoElement.srcObject.getTracks();
    tracks.forEach((track) => track.stop());

    document.getElementById("videoPreview").style.display = "none";
    document.getElementById("captureButton").style.display = "none";
  };

  return (
    <>
      <div className="centerHeader">
        <h3 className="mt-4">Insurance</h3>
      </div>
      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="memberId">Member ID</label>
          <input
            type="text"
            name="memberId"
            value={memberId}
            onChange={handleMemberIdChange}
          />
        </div>
      </div>
      <div className="centerHeader">
        <h3 className="mt-4">Select an insurance payer.</h3>
      </div>
      <div className="input-group-payerSelection">
        {!selectedPayer && (
          <PayerSelection
            mode={"all"}
            onSelect={handlePayerSelect}
          />
        )}
        {selectedPayer && (
          <div className="payerSelectorContainer">
            <h3>Selected Payer:</h3>
            <p>{selectedPayer.name}</p>
            <button onClick={handlePayerUnselect}>Change</button>
          </div>
        )}
      </div>
      <div className="input-group">
        {isMobile ? (
          <>
            {previewImage ? (
              <div className="preview-image-container">
                <img src={previewImage} id="previewImage" alt="Preview" />
                <button onClick={() => setPreviewImage(null)}>
                  <FontAwesomeIcon icon={faCamera} />
                  Retake Photo of Insurance ID Card
                </button>
              </div>
            ) : (
              <>
                <video
                  id="videoPreview"
                  width="500"
                  height="600"
                  autoPlay
                  muted
                  playsInline
                  style={{ display: "none" }}
                ></video>

                <button id="startCameraButton" onClick={onCameraClick}>
                  <FontAwesomeIcon icon={faCamera} />
                  Take picture of Insurance ID Card
                </button>

                <button
                  id="captureButton"
                  onClick={capturePhoto}
                  style={{ display: "none" }}
                >
                  Capture
                </button>
              </>
            )}

            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              accept="image/*"
              capture="camera"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  handleFileChange(e);
                }
              }}
            />
          </>
        ) : (
          <>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              accept="image/*"
              capture="camera"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  handleFileChange(e);
                }
              }}
            />

            <button onClick={onCameraClick} className="cameraButton">
              <FontAwesomeIcon icon={faCamera} />
              {file ? " Change Photo" : " Take/Select Photo of Insurance Card"}
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default InsuranceInformation;
