import React, { useState } from "react";
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase";
import useUID from "../../General/useUID";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";

const functions = getFunctions();
const encryptFunction = httpsCallable(functions, "encrypt");

function Medications({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [medication, setMedication] = useState({
    medicationName: "",
    dosage: "",
    frequency: "",
    startDate: "",
    endDate: "",
    notes: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setMedication({
      ...medication,
      [e.target.name]: e.target.value,
    });
  };

  const isFormEmpty = () => {
    return (
      !medication.medicationName || !medication.dosage || !medication.frequency
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      alert(
        "Please fill in at least the medication name, dosage, and frequency."
      );
      return;
    }

    setLoading(true);

    // Encrypt the data before sending it to Firestore
    try {
      const encryptedResponse = await encryptFunction(medication);
      const encryptedData = encryptedResponse.data; // Assuming this is the structure of your encrypted data

      const medicalHistoryRef = collection(
        doc(db, "patients", uid, "patientData", patientId),
        "medicalHistory"
      );

      // Storing encrypted data along with the encryption IV
      await addDoc(medicalHistoryRef, {
        type: "Medications",
        timestamp: serverTimestamp(),
        ciphertext: encryptedData.ciphertext, // Store the encrypted ciphertext
        iv: encryptedData.iv, // Store the IV needed for decryption
      });

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        activity: "Medical history added",
        activityType: "medicalHistory",
        uid: uid,
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      setMedication({
        medicationName: "",
        dosage: "",
        frequency: "",
        startDate: "",
        endDate: "",
        notes: "",
      });
      onClose();
    } catch (error) {
      console.error("Error during encryption or adding document: ", error);
    } finally {
      setLoading(false); // Stop loading after the async operation
    }
  };

  return (
    <div className={styles.medicationForm}>
      <form onSubmit={handleSubmit}>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="medicationName">Medication Name:</label>
            <input
              type="text"
              id="medicationName"
              name="medicationName"
              value={medication.medicationName}
              onChange={handleChange}
              placeholder="Enter medication name"
            />
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="dosage">Dosage:</label>
            <input
              type="text"
              id="dosage"
              name="dosage"
              value={medication.dosage}
              onChange={handleChange}
              placeholder="Enter dosage"
            />
          </div>
          <div className="input-field">
            <label htmlFor="frequency">Frequency:</label>
            <input
              type="text"
              id="frequency"
              name="frequency"
              value={medication.frequency}
              onChange={handleChange}
              placeholder="e.g., once daily, every 8 hours"
            />
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={medication.startDate}
              onChange={handleChange}
            />
          </div>
          <div className="input-field">
            <label htmlFor="endDate">End Date:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={medication.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="notes">Notes:</label>
            <textarea
              id="notes"
              name="notes"
              value={medication.notes}
              onChange={handleChange}
              placeholder="Special instructions or additional information"
            />
          </div>
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HexagonSpinner />
          </div>
        ) : (
          <button
            type="submit"
            className={styles.submitButton}
            disabled={loading}
          >
            Add Medication
          </button>
        )}
      </form>
    </div>
  );
}

export default Medications;
