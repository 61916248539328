import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faGripVertical, faCog } from "@fortawesome/free-solid-svg-icons";

const FormElementSideBar = ({ 
  toggleExpandableArea,
  setFormElementsData,
  index
}) => {
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleDelete = () => {
    setFormElementsData((prevElements) =>
      prevElements.filter((_, idx) => idx !== index)
    );
  };

  return (
    <div className="SideContainer">
      <button 
        className={`SidebarButton TrashButton ${hoveredButton === 'delete' ? 'expanded' : ''}`}
        onClick={handleDelete}
        onMouseEnter={() => setHoveredButton('delete')}
        onMouseLeave={() => setHoveredButton(null)}
      > 
        <FontAwesomeIcon icon={faTrash} />
        <span className="hover-text">Delete</span>
      </button>
      <div className="item m-3">
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
      <button 
        className={`SidebarButton ExpandableButton ${hoveredButton === 'edit' ? 'expanded' : ''}`}
        onClick={toggleExpandableArea}
        onMouseEnter={() => setHoveredButton('edit')}
        onMouseLeave={() => setHoveredButton(null)}
      >
        <FontAwesomeIcon icon={faCog} />
        <span className="hover-text">Edit</span>
      </button>
    </div>
  );
};

export default FormElementSideBar;