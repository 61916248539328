import React, { useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { confirmPasswordReset } from 'firebase/auth';
import { auth, db } from "../../firebase";
import styles from './AUTH.css'; // Ensure your CSS module is correctly linked
import winstonPic from '../General/Dogs/winston.png'; // Verify the path to your image
import Footer from '../General/Home/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!newPassword) {
      setError('Please enter a new password.');
      return;
    }
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccess(true);
      setTimeout(() => navigate('/signin'), 3000); // Redirect to sign-in page after 3 seconds
    } catch (error) {
      setError('Failed to reset password. Please try the link again or contact support.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (success) {
    return (
      <div className="background">
        <div className={styles["AUTH-container"] + " auth-margin-top"}>
          <div>Password successfully reset. Redirecting to sign-in page...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="background">
      <div className="AUTH-container auth-margin-top">
        <div className="side-by-side">
          <div className="left-content">
            <form style={{ alignItems: "normal" }} onSubmit={handlePasswordReset}>
              <h1 style={{textAlign: "left", marginBottom: "2rem"}} className='typography-headline'>Reset Password</h1>
              <p style={{textAlign: "left", marginBottom: "2rem"}} className='typography-label-alt-dark'>Please enter your new password below.</p>
              <div className="input-group-row">
                <div className="input-field" style={{ position: 'relative', marginBottom: '1rem' }}>
                    <label htmlFor="newPassword">New Password:</label>
                    <input
                    id="newPassword"
                    type={showPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    style={{ paddingRight: '40px', width: "15rem" }} // Make room for the icon
                    />
                    <div className='eye-slash-container'>
                        <FontAwesomeIcon className='eye-slash' icon={showPassword ? faEye : faEyeSlash}     onClick={togglePasswordVisibility} />
                    </div>
                </div>
                </div>
              {error && <div className='error-message dark-text'>{error}</div>}
              <div className="resetPassword">
                 <button type="submit">Reset Password</button>
              </div>
              <p><Link to="/signin" className="signin-btn">Back to Sign In</Link></p>
            </form>
          </div>
          <div className="right-content">
            <div className="side-by-side">
              <img className="dog-image" src={winstonPic} alt="Winston the Dog" />
              <div className="dog-text">
                <h2 className="dog-h2">Winston</h2>
                <p className='typography-label-alt-dark'>Dogs of Popularis</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordReset;
