import React, { useState, useEffect } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../../../firebase.js";
import Hex from '../Hex.jsx';
import AuthDetails from '../../Auth/AuthDetails.jsx';
import styles from './HomePage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faMagnifyingGlassDollar, faUserPlus, faPlus, faFileCircleCheck, faHeart, faBrain, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import Footer from "./Footer.jsx";
import Topbar from "../Topbar.jsx";
import HexagonSpinner from "../Animations/Hexspinner.jsx";
import useUID from '../useUID.jsx'
import AddPatient from '../../PatientFiles/AddPatient/AddPatient.jsx'
import Modal from 'react-modal'

const HomePage = ({ onToggleTheme }) => {
  const [uid, subUserUID] = useUID();
  const [userRole, setUserRole] = useState(null);
  const [state] = React.useReducer(reducer, {
    board: createBoard(),
    currentSide: "A"
  });
  const navigate = useNavigate();
  const user = auth.currentUser;
  const now = new Date();
  const currentHour = now.getHours();
  let greeting;
  if (currentHour < 12) {
    greeting = "Good morning";
  } else if (currentHour < 18) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }

  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [isAddPatientModalVisible, setIsAddPatientModalVisible] = useState(false);

  
function createBoard() {
  const rowLengthList = userRole === 'nurse' ? [2, 1] : [2, 1];
  return rowLengthList.map(length => new Array(length).fill(0));
}

function put(board, rowIndex, cellIndex, side) {
  const newBoard = board.map(row => [...row]);
  newBoard[rowIndex][cellIndex] = side;
  return newBoard;
}

function changeSide(side) {
  return side === "A" ? "B" : "A";
}

function reducer(state, action) {
  switch (action.type) {
    case "put":
      return {
        ...state,
        board: put(
          state.board,
          action.payload.rowIndex,
          action.payload.cellIndex,
          state.currentSide
        ),
        currentSide: changeSide(state.currentSide)
      };
    default:
      return state;
  }
}

useEffect(() => {
  if (!uid) return; // Exit if no UID is available to avoid errors

  let unsubscribeUser;
  let unsubscribeSubUser;

  const userDetailsRef = doc(db, "users", uid);
  unsubscribeUser = onSnapshot(userDetailsRef, (doc) => {
    if (doc.exists()) {
      const data = doc.data();
      setUserDetails(data);
      if (!subUserUID) {
        setUserRole(data.role); // Set role from the parent user document
        setFirstName(data.firstName);
        setLastName(data.lastName);
      }
      setLoading(false);
    } else {
      console.error("User document does not exist!");
      setLoading(false);
    }
  });

  if (subUserUID) {
    const subUserDetailsRef = doc(db, "users", uid, "subusers", subUserUID);
    unsubscribeSubUser = onSnapshot(subUserDetailsRef, (subDoc) => {
      if (subDoc.exists()) {
        const subData = subDoc.data();
        setUserDetails(subData);
        setUserRole(subData.role); // Set role from the subuser document
        setFirstName(subData.firstName);
        setLastName(subData.lastName);
      } else {
        console.error("Subuser document does not exist!");
      }
    });
  }

  // Clean up function to unsubscribe from onSnapshot listeners
  return () => {
    unsubscribeUser && unsubscribeUser();
    unsubscribeSubUser && unsubscribeSubUser();
  };
}, [uid, subUserUID]);

// Function to toggle the modal's visibility remains the same
const toggleAddPatientModal = () => {
  setIsAddPatientModalVisible(!isAddPatientModalVisible);
};


return (
  <div className={styles.background}>
    <Topbar />
    <div className={styles.container}>
      <div className={styles.mainContent}>
      <div className={styles.addPatientButtonContainer}>
          <button title='Add a Patient' onClick={toggleAddPatientModal} className={styles.addPatientButton}>
            <FontAwesomeIcon icon={faUserPlus} />
          </button>
        </div>

        <Modal
          isOpen={isAddPatientModalVisible}
          onRequestClose={toggleAddPatientModal} // This allows closing the modal by clicking outside it or pressing the ESC key
          // Here you can add more props to style the modal as needed
        >
          <button onClick={toggleAddPatientModal} className="filesCloseButton">X</button>
          <AddPatient onClose={toggleAddPatientModal} />
        </Modal>
        <div className={styles.title}>
          <h2>{`${greeting}, ${firstName ?? ""}.`}</h2>
        </div>

        {loading ? (
          <div style={{ width: "100vw", height: "100vh", backgroundColor: "white" }}></div>
        ) : (
          userRole === 'nurse' ? (
            <div className={styles.HexagonContainer}>
              {/* AI Hex Component */}
              <Hex
                key="ai-hex"
                side="A"
                onClick={() => navigate('/ai')}
              >
                <div>
                  <FontAwesomeIcon icon={faBrain} style={{ height: "3rem", marginBottom: ".5rem"  }} />
                  <h4>AI</h4>
                </div>
              </Hex>

              {/* Patients Hex Component */}
              <Hex
                key="patients-hex"
                side="A"
                onClick={() => navigate('/patients')}
              >
                <div>
                  <FontAwesomeIcon icon={faHeart} style={{ height: "3rem", marginBottom: ".5rem"  }} />
                  <h4>Patients</h4>
                </div>
              </Hex>
            </div>
          ) : (
            // Render the original board for other roles
            state.board.map((row, rowIndex) => (
              <div
              className={styles.HexagonContainer}
              key={`row-${rowIndex}`}
              >
                {row.map((side, cellIndex) => (
                  <Hex
                    key={`${rowIndex}-${cellIndex}`}
                    side={side}
                    onClick={() => {
                      if (rowIndex === 1 && cellIndex === 0) {
                        navigate('/ai');
                      } else if (rowIndex === 0 && cellIndex === 0) {
                        navigate('/claims');
                      } else if (rowIndex === 0 && cellIndex === 1) {
                        navigate('/patients');
                      } else {
                        return;
                      }
                    }}
                  >
                      {rowIndex === 1 && cellIndex === 0 && (
                        <div>
                          <FontAwesomeIcon
                            icon={faBrain}
                            style={{ height: "3rem", marginBottom: ".5rem" }}
                          />
                          <h4>AI</h4>
                        </div>
                      )}
                      {rowIndex === 0 && cellIndex === 0 && userRole !== 'nurse' && (
                        <div>
                            <FontAwesomeIcon
                                icon={faFileInvoiceDollar}
                                style={{ height: "3rem", marginBottom: ".5rem" }}
                            />
                            <h4>Claims</h4>
                        </div>
                    )}
                      {rowIndex === 0 && cellIndex === 1 && (
                        <div>
                          <FontAwesomeIcon
                            icon={faHeart}
                            style={{ height: "3rem", marginBottom: ".5rem"  }}
                          />
                        <h4>Patients</h4>
                        </div>
                      )}
                     
                     </Hex>
                ))}
              </div>
            ))
          )
        )}
      </div>
    </div>
    <Footer />
  </div>
);
}
  
  export default HomePage;
