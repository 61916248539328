import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { doc, updateDoc, getDoc } from "firebase/firestore";

function StepEleven({ onNext, prevStep, setCanProceed }) {
  const [uid] = useUID();
  const [yesNoAnswers, setYesNoAnswers] = useState({
    licenseDenied: null,
    reprimanded: null,
    DEARegistrationIssues: null,
    DEAInvestigation: null,
    deniedHospitalPrivileges: null,
    voluntarilyRelinquishedHospitalPrivileges: null,
    disciplinaryProceedingsHospital: null,
    deniedMedicareMedicaid: null,
    reprimandedMedicareMedicaid: null,
    medicalPartnershipIssues: null,
    complaintsFiled: null,
    liabilityJudgments: null,
    liabilityClaimSettlements: null,
    deniedLiabilityInsurance: null,
    convictedOfCrime: null,
    refusedParticipationManagedCare: null,
    reportedToNationalPractitionerDatabank: null,
    physicalMentalHealthImpairment: null,
    alcoholChemicalSubstancesImpairment: null,
    limitationsImpairmentsAmeliorated: null,
    usingIllegalDrugs: null,
    participatingInRehabilitation: null,
    // Additional questions can be added here with null as default.
  });

  useEffect(() => {
    async function fetchData() {
      if (!uid) return;
      const infoRef = doc(db, `users/${uid}/applications/ipaApplication`);
      try {
        const docSnap = await getDoc(infoRef);
        if (docSnap.exists() && docSnap.data().ConfidentialInfo) {
          // Ensure existing data or set defaults to null
          const fetchedAnswers = docSnap.data().ConfidentialInfo.yesNoAnswers || {};
          // Initialize all answers with null if not present
          const initializedAnswers = Object.keys(yesNoAnswers).reduce((acc, key) => {
            acc[key] = fetchedAnswers.hasOwnProperty(key) ? fetchedAnswers[key] : null;
            return acc;
          }, {});
          setYesNoAnswers(initializedAnswers);
        }
      } catch (error) {
        console.error("Error fetching confidential information:", error);
      }
    };
    fetchData();
  }, [uid, yesNoAnswers]);

  useEffect(() => {
    const allAnswered = Object.values(yesNoAnswers).every(answer => answer !== null);
    setCanProceed(allAnswered);
  }, [yesNoAnswers]);

  const handleYesNoChange = async (question, answer) => {
    const updatedAnswers = { ...yesNoAnswers, [question]: answer };
    setYesNoAnswers(updatedAnswers);

    if (!uid) return;
    const infoRef = doc(db, `users/${uid}/applications/ipaApplication`);
    try {
      await updateDoc(infoRef, { ConfidentialInfo: { yesNoAnswers: updatedAnswers } });
    } catch (error) {
      console.error("Error updating confidential information:", error);
    }
  };

  const questions = [
    { key: 'licenseDenied', text: 'Has your license to practice in any jurisdiction ever been denied, restricted, limited, suspended, even if the suspension was stayed, or revoked, either voluntarily, or involuntarily?' },
    { key: 'reprimanded', text: 'Have you ever been reprimanded, disciplined, counseled, or been subject to similar action by any state, licensing agency with respect to your license to practice?' },
    { key: 'DEARegistrationIssues', text: 'Has your DEA or state controlled substance registration ever been restricted, limited, suspended, even if the suspension was stayed, or revoked, either voluntarily or involuntarily?' },
    { key: 'DEAInvestigation', text: 'Are you currently under any investigation with respect to your DEA or state controlled substance registration?' },
    { key: 'deniedHospitalPrivileges', text: 'Have you ever been denied hospital privileges or have you ever had any hospital privileges revoked, suspended, reduced, or non-renewed?' },
    { key: 'voluntarilyRelinquishedHospitalPrivileges', text: 'Have you ever voluntarily relinquished or voluntarily limited any hospital privileges?' },
    { key: 'disciplinaryProceedingsHospital', text: 'Have any disciplinary proceedings ever been instituted against you or any disciplinary actions now pending with respect to your hospital privileges or your license?' },
    { key: 'deniedMedicareMedicaid', text: 'Have you ever been denied participation in Medicare, Medicaid, or any other governmental or quasi governmental health related program?' },
    { key: 'reprimandedMedicareMedicaid', text: 'Have you ever been reprimanded, sanctioned, excluded, suspended, even if the suspension was stayed, debarred, or disqualified from participating in Medicare, Medicaid, or any other governmental or quasi governmental health related program?' },
    { key: 'medicalPartnershipIssues', text: 'Have you ever been requested to resign, withdraw, or terminate your position with a medical partnership, professional association, health maintenance organization, medical practice, either public or private?' },
    { key: 'complaintsFiled', text: 'Have any complaints ever been filed against you with a medical society or licensing authority?' },
    { key: 'liabilityJudgments', text: 'Have any professional liability judgments ever been entered against you?' },
    { key: 'liabilityClaimSettlements', text: 'Have any professional liability claim settlements, not involving litigation or arbitration, ever been paid by you or paid on your behalf?' },
    { key: 'deniedLiabilityInsurance', text: 'Have you ever been denied professional liability insurance coverage or had your professional liability insurance coverage canceled by your carrier?' },
    { key: 'convictedOfCrime', text: 'Have you ever been convicted of a crime, other than a minor traffic offense, or do you have any criminal charges pending other than minor traffic offenses?' },
    { key: 'refusedParticipationManagedCare', text: 'Have you ever been refused participation in the network of a managed care organization or PPO or have been disciplined by or terminated from such a plan or organization?' },
    { key: 'reportedToNationalPractitionerDatabank', text: 'Has any information pertaining to you ever been reported to the National Practitioner Databank?' },
    { key: 'physicalMentalHealthImpairment', text: 'Is your physical or mental health such that it may impair your ability to practice within the scope of privileges which you have applied with or without reasonable accommodation?' },
    { key: 'alcoholChemicalSubstancesImpairment', text: 'Does your use of alcohol or other chemical substances in any way limit or impair your ability to practice medicine with reasonable skill or safety? Are limitations or impairments caused by your medical condition, reduced or ameliorated because you receive ongoing treatment, with or without medication, or participate in a monitoring program?' },
    { key: 'limitationsImpairmentsAmeliorated', text: 'Are the limitations or impairments caused by your medical condition, reduced or ameliorated because of the field of practice, the setting, or the manner in which you have chosen to practice?' },
    { key: 'usingIllegalDrugs', text: 'Are you currently using illegal drugs or controlled or dangerous substances?' },
    { key: 'participatingInRehabilitation', text: 'If yes, are you currently participating in a supervised rehabilitation program or professional assistance program which monitors you in order to ensure you are not engaging in the illegal use of controlled, dangerous substances?' },
  ];
  

  return (
    <div>
      <h2>Confidential Professional Information</h2>
      {questions.map(({ key, text }) => (
        <div key={key} className="yes-no-question">
          <p>{text}</p>
          <div style={{ flexDirection: "column", display: "flex", marginBottom: "2rem" }}>
            <label>
              <input
                type="radio"
                className='custom-checkbox'
                name={key}
                checked={yesNoAnswers[key] === true}
                onChange={() => handleYesNoChange(key, true)}
              /> Yes
            </label>
            <label>
              <input
                type="radio"
                className='custom-checkbox'
                name={key}
                checked={yesNoAnswers[key] === false}
                onChange={() => handleYesNoChange(key, false)}
              /> No
            </label>
            <label>
              <input
                type="radio"
                className='custom-checkbox'
                name={key}
                checked={yesNoAnswers[key] === null}
                onChange={() => handleYesNoChange(key, null)}
                style={{display: "none"}}
              />
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}

export default StepEleven;