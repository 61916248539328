import React, { useState, useEffect } from "react";
import HexagonSpinner from "../../../General/Animations/Hexspinner";
import useUID from "../../../General/useUID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faEdit,
  faPenFancy,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../../../../firebase";
import { collection, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";

import Modal from "react-modal";
import { Button } from "react-bootstrap";
import FormEditor from "../FormEditor/FormEditor";
import FormDisplay from "./FormDisplay";
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faSolidHeart } from '@fortawesome/free-solid-svg-icons';

function FormSelection({
  onFormInputChange = () => { },
  selectedFormId = null,
  setSelectedFormId = null,
  setSelectedFormDataTranfer,
  formInputValuesInit = [],
  selectedFormDataInit = null,
}) {
  // Use local states
  const [layouts, setLayouts] = useState([]);
  const [uid] = useUID();
  const [showConfirmDeleteModal, setshowConfirmDeleteModal] = useState(false);
  const [showConfirmSwitchModal, setShowConfirmSwitchModal] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState(null);
  const [showFormEditor, setShowFormEditor] = useState(null);
  const [editFormId, setEditFormId] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [selectedFormData, setSelectedFormData] = useState(null);
  const [formInputValues, setFormInputValues] = useState(formInputValuesInit);
  const [formTempInputValues, setTempFormInputValues] = useState([]);
  const [tempFormData, setTempFormData] = useState(null);
  const [favoritedFormId, setFavoritedFormId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleFormInputChange = (values) => {
    if (typeof onFormInputChange === "function" && !isSaving) {
      onFormInputChange(values);
      setFormInputValues(values);
    }
  };

  useEffect(() => {
    setSelectedFormData(selectedFormDataInit);
  }, [selectedFormDataInit]);

  const fetchLayouts = async () => {
    try {
      const formsCollectionRef = collection(db, `users/${uid}/forms`);
      const formsQuerySnapshot = await getDocs(formsCollectionRef);
  
      const allLayouts = [];
      let favoritedForm = null;
  
      formsQuerySnapshot.forEach((formDoc) => {
        const formData = formDoc.data();
        if (!formData.deleted) {
          const layout = {
            id: formDoc.id,
            ...formData,
            isFavorite: formData.isFavorite || false,
          };
          allLayouts.push(layout);
  
          if (layout.isFavorite) {
            favoritedForm = layout;
            setFavoritedFormId(layout.id);
          }
        }
      });
    
      setLayouts(allLayouts);
  
      if (favoritedForm && setSelectedFormId) {
        setSelectedFormId(favoritedForm.id);
        setSelectedFormDataTranfer(favoritedForm.formElements);
        setSelectedFormData(favoritedForm.formElements);
      } else {
        // If no form is favorited, don't select any form
        setSelectedFormId(null);
        setSelectedFormDataTranfer(null);
        setSelectedFormData(null);
      }
    } catch (error) {
      console.error("Error fetching form data:", error);
      setLayouts([]);
      setSelectedFormId(null);
      setSelectedFormDataTranfer(null);
      setSelectedFormData(null);
    }
  
    setIsLoading(false);
  };

  useEffect(() => {
    if (!uid) return;
    fetchLayouts();
    setFormInputValues(formInputValuesInit);
  }, [uid]);

  useEffect(() => {
    if (selectedFormId) {
      const selectedForm = layouts.find(
        (layout) => layout.id === selectedFormId
      );

      if (selectedForm) {
        setSelectedFormData(selectedForm.formElements);
      } else {
        setSelectedFormDataTranfer(formInputValuesInit);
        setSelectedFormData(formInputValuesInit);
      }
    }
  }, [selectedFormId]);

  const onClose = () => {
    setShowFormEditor(null);
  };

  const confirmDelete = async (formId) => {
    try {
      setIsSaving(true);
      const formDocRef = doc(db, `users/${uid}/forms`, formId);
      await deleteDoc(formDocRef);
      setshowConfirmDeleteModal(false);
      setProviderToDelete(null);
      await fetchLayouts();
    } catch (error) {
      console.error("Error deleting form:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const cancelDelete = () => {
    setshowConfirmDeleteModal(false);
    setProviderToDelete(null);
  };

  const handleAddNewProvider = () => {
    setShowFormEditor(true);
    setEditFormId(null);
  };

  const handleEdit = (formId) => {
    setShowFormEditor(true);
    setEditFormId(formId);
    if (setSelectedFormId) {
      setSelectedFormId(formId);
    }
  };

  const handleSelectForm = (formData) => {
    if (formInputValues.length >= 1) {
      setTempFormInputValues(formInputValues);
      setFormInputValues([]);
      setTempFormData(formData);
      setShowConfirmSwitchModal(true);
      return;
    }

    if (
      formData === null ||
      (selectedFormId !== null && selectedFormId === formData?.id)
    ) {
      setSelectedFormId(null);
      setSelectedFormDataTranfer(null);
      setSelectedFormData(null);
    } else {
      if (setSelectedFormId) {
        setSelectedFormId(formData.id);
        setSelectedFormDataTranfer(formData.formElements);
      }

      setSelectedFormData(formData.formElements);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await fetchLayouts();
      const updatedFormData = layouts.find(
        (layout) => layout.id === selectedFormId
      );

      if (updatedFormData) {
        handleSelectForm(updatedFormData);
      }

      setShowFormEditor(null);

      if (setSelectedFormId) {
        setSelectedFormDataTranfer(updatedFormData.formElements || null);
        setSelectedFormId(updatedFormData?.id || null);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handelCancelSwitch = () => {
    setFormInputValues(formTempInputValues);
    setShowConfirmSwitchModal(false);
  };

  const handelSwitchFormComfirm = async () => {
    await setFormInputValues([]);
    await setSelectedFormDataTranfer([]);
    await setSelectedFormData([]);
    await setSelectedFormId(null);
    await handleSelectForm(tempFormData);
    setShowConfirmSwitchModal(false);
  };

  const handleFavorite = async (formId) => {
    try {
      setIsSaving(true);
      const updatedLayouts = layouts.map(layout => ({
        ...layout,
        isFavorite: layout.id === formId ? !layout.isFavorite : false
      }));

      setLayouts(updatedLayouts);

      const newFavoriteId = updatedLayouts.find(layout => layout.isFavorite)?.id || null;
      setFavoritedFormId(newFavoriteId);

      // Update all forms in the database
      for (const layout of updatedLayouts) {
        const formDocRef = doc(db, `users/${uid}/forms`, layout.id);
        await updateDoc(formDocRef, { isFavorite: layout.isFavorite });
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {showFormEditor && (
        <div className="actionContainer">
          <div className="popupContainer">
            <div className="popupContent">
              <button className="filesCloseButton" onClick={onClose}>
                X
              </button>
              <div className="container content-center w-100">
                <FormEditor formId={editFormId} handleSave={handleSave}  isEncounter={1} />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid-container w-100">
        {isLoading ? (
          <div className="grey-card h-250px w-100 content-center py-4">
            <HexagonSpinner />
            <h4 className="headerForm">Your providers are loading.</h4>
          </div>
        ) : (
          layouts.map((layout) => (
            <div
              key={layout.id}
              className={
                (layout.id === selectedFormId
                  ? "blue-card card-selected"
                  : "white-card") +
                " white-card card w-100 h-250px content-center text-center py-4"
              }
              onClick={() => handleSelectForm(layout)}
            >
              <FontAwesomeIcon className="mb-3 mx-0" icon={faPenFancy} />
              <h4 className="headerForm">
                {layout.title || "Custom Form test"}
              </h4>
              <h5>Last Updated:</h5>
              <h5>
                {layout.updatedAt
                  ? new Date(
                    layout.updatedAt.seconds * 1000
                  ).toLocaleDateString()
                  : "-"}
              </h5>
              <div className="button-group">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFavorite(layout.id);
                  }}
                  className="secondary"
                  disabled={isSaving}
                >
                  <FontAwesomeIcon icon={layout.isFavorite ? faSolidHeart : faRegularHeart} />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(layout.id);
                  }}
                  className="secondary"
                  disabled={isSaving}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setshowConfirmDeleteModal(true);
                    setProviderToDelete(layout);
                  }}
                  className="secondary"
                  disabled={isSaving}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))
        )}
        <div
          className="white-card w-100 h-250px content-center py-4"
          onClick={handleAddNewProvider}
        >
          <FontAwesomeIcon icon={faPlus} style={{ height: "32px" }} />
          <h4 className="mt-3">Add a Layout</h4>
        </div>
      </div>

      {selectedFormData && (
        <FormDisplay
          formData={selectedFormData}
          onFormInputChange={handleFormInputChange}
          formInputValuesInit={formInputValuesInit}
          disabled={isSaving}
        />
      )}

      <Modal
        isOpen={showConfirmDeleteModal}
        onRequestClose={cancelDelete}
        className="confirmModal content-center text-center"
        overlayClassName="confirmModalOverlay"
      >
        <h2>Delete Form?</h2>
        <p>Are you sure you want to delete this form?</p>
        <div className="confirmButtons display-flex">
          <Button variant="delete" onClick={cancelDelete} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            variant="danger"
            className="danger"
            onClick={() => confirmDelete(providerToDelete.id)}
            disabled={isSaving}
          >
            Delete
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={showConfirmSwitchModal}
        onRequestClose={cancelDelete}
        className="confirmModal content-center text-center"
        overlayClassName="confirmModalOverlay"
      >
        <h2>Are you sure you want to switch form?</h2>
        <p>This action will clear out the currently selected custom from?</p>
        <div className="confirmButtons display-flex">
          <Button variant="delete" onClick={handelCancelSwitch} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            variant="danger"
            className="danger"
            onClick={() => handelSwitchFormComfirm()}
            disabled={isSaving}
          >
            Clear Form & Switch
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default React.memo(FormSelection);