import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../../firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

const CallbackSelection = ({ uid, onSelect }) => {
    const [apiSpecs, setApiSpecs] = useState([]);
    const [selectedCallbacks, setSelectedCallbacks] = useState([]);

    const apiSpecsRef = collection(db, 'APISpecs');

    useEffect(() => {
        if (!uid) return;

        const q = query(apiSpecsRef, where("UserID", "==", uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const specs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setApiSpecs(specs);
            if (specs.length > 0 && selectedCallbacks.length === 0) {
                setSelectedCallbacks([specs[0].id]); // Auto-select the first callback
            }
        });

        return () => unsubscribe();
    }, [uid]);

    const handleCallbackSelection = (id) => {
        setSelectedCallbacks(prevState => 
            prevState.includes(id) 
                ? prevState.filter(callbackId => callbackId !== id)
                : [...prevState, id]
        );
    };

    // Use a ref to store the onSelect callback to avoid it being a dependency in useEffect
    const onSelectRef = React.useRef(onSelect);

    useEffect(() => {
        onSelectRef.current = onSelect;
    }, [onSelect]);

    useEffect(() => {
        if (apiSpecs.length > 0) {
            const selectedSpecs = apiSpecs.filter(spec => selectedCallbacks.includes(spec.id));
            onSelectRef.current(selectedSpecs);
        }
    }, [selectedCallbacks, apiSpecs]);

    return (
        <div className='callbacks-container'>
            {apiSpecs.length > 0 && (
                <label className="mt-4">Select a destination for the encounter via API (optional):</label>
            )}
            {apiSpecs.map((spec) => (
                <div 
                    onClick={() => handleCallbackSelection(spec.id)} 
                    key={spec.id} 
                    className={`Card ${selectedCallbacks.includes(spec.id) ? 'selected' : ''}`}
                >
                    <div className="callbacks-content-list">
                        <FontAwesomeIcon 
                            icon={selectedCallbacks.includes(spec.id) ? faCheckCircle : faCircle} 
                            className="selection-icon" 
                        />
                        <h4>{spec.Name || 'Unnamed Callback'}</h4>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CallbackSelection;
