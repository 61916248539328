import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const TypingText = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let i = 0;
    let typingTimeout;

    const typeNextCharacter = () => {
        if (i < text.length) {
          setDisplayedText(text.substring(0, i+1));
          i++;
          const randomDelay = Math.floor(Math.random() * (25 - 22) + 3);
          typingTimeout = setTimeout(typeNextCharacter, randomDelay);
        }
      };
      

    typeNextCharacter();

    // Clean up timeout on component unmount
    return () => clearTimeout(typingTimeout);
  }, [text]);

  return  <ReactMarkdown linkTarget="_blank">
            {displayedText}
          </ReactMarkdown>;
};

export default TypingText;
