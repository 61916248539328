import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { faHeadset, faThumbsUp, faCircleNotch, faBrain, faHeart, faCog, faHome, faChartLine, faCircleUser, faGrip, faFileInvoiceDollar, faFileCircleCheck, faClockRotateLeft, faServer } from '@fortawesome/free-solid-svg-icons'; // Added faServer for the API queue button
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../popularis_logos/Popularis_logo_wide.png';
import claims from '../../popularis_logos/Popularis_Claims.png';
import gpt from '../../popularis_logos/Popularis_AI.png';
import patientfiles from '../../popularis_logos/Popularis_Patients.png';
import useUID from './useUID';
import './Topbar.css';
import AuthDetails from './../Auth/AuthDetails';
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../firebase";
import useEmailVerified from './useEmailVerified';
import { httpsCallable, getFunctions } from 'firebase/functions';

const Topbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAccountDropdown, setShowAccountDropdown] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [accountTier, setAccountTier] = useState(''); // Track the user's account tier
    const [userRole, setUserRole] = useState(null);
    const [emailVerified, setEmailVerified] = useState(null); // Initialize as null to represent unknown verification status
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);
    const [verificationCheckLoading, setVerificationCheckLoading] = useState(true);
    const isEmailVerified = useEmailVerified();
    const functions = getFunctions();
    const [uid, subUserUID] = useUID();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const sendVerificationEmail = async () => {
        const sendVerificationEmailFn = httpsCallable(functions, 'sendVerificationEmail');
        try {
            setVerificationEmailSent(true);
            await sendVerificationEmailFn({ email, firstName, lastName, uid });
        } catch (error) {
            console.error("Error sending verification email:", error);
        }
    };

    useEffect(() => {
        setVerificationCheckLoading(true);

        let unsubscribe = () => { };

        if (uid) {
            let userRef;
            if (subUserUID) {
                userRef = doc(db, "users", uid, "subusers", subUserUID);
            } else {
                userRef = doc(db, "users", uid);
            }

            unsubscribe = onSnapshot(userRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setFirstName(userData.firstName || '');
                    setLastName(userData.lastName || '');
                    setEmail(userData.email || '');
                    setAccountTier(userData.AccountTier || 'Freebie');
                    setUserRole(userData.role || 'nurse');
                    setEmailVerified(subUserUID ? true : (userData.emailVerified || false));
                } else {
                    console.error("User document does not exist.");
                }
                setVerificationCheckLoading(false);
            }, (error) => {
                console.error("Error fetching user document:", error);
                setVerificationCheckLoading(false);
            });
        } else {
            setVerificationCheckLoading(false);
        }

        return () => unsubscribe();
    }, [uid, subUserUID]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showEmailVerificationPrompt = emailVerified === false && !verificationEmailSent && !verificationCheckLoading;

    const getLogo = () => {
        if (windowWidth >= 500) {
            if (location.pathname === '/claims' || location.pathname.startsWith('/claims/')) {
                return claims;
            }
            if (location.pathname.startsWith('/patients')) {
                return patientfiles;
            }
            switch (location.pathname) {
                case '/ai':
                    return gpt;
                default:
                    return logo;
            }
        } else {
            return logo;
        }
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setShowAccountDropdown(false);
    };

    const toggleAccountDropdown = () => {
        setShowAccountDropdown(!showAccountDropdown);
        setShowDropdown(false);
    };

    const shouldShowApiQueueLink = accountTier === "Enterprise" || subUserUID; // Show API Queue if the user is on an Enterprise account or is a subuser

    return (
        <div className="topbar">
            <div className="leftNav">
                <Link to="/home" onClick={() => navigate('/home')} style={{ textDecoration: 'none' }}>
                    <img src={getLogo()} alt="Logo" className="logo" />
                </Link>
            </div>
            <div className="rightNav">
                {showEmailVerificationPrompt && (
                    <div title="Please verify your email to send claims or run eligibility." className="emailVerificationFlag" onClick={sendVerificationEmail}>
                        Email Not Verified - Click to Verify
                    </div>
                )}
                {!isEmailVerified && verificationEmailSent && (
                    <div className="emailVerificationFlag">Verification Email Sent</div>
                )}
                {isEmailVerified && accountTier === "Freebie" && !subUserUID && (
                    <button style={{ marginTop: ".75rem" }} className="upgradeButton" onClick={() => navigate("/payment")}>
                        Upgrade Account
                    </button>
                )}
                <button title='View messages in your organization' className="dropdownButton" onClick={() => navigate('/messages')}>
                    <FontAwesomeIcon icon={faMessage} size="lg" />
                </button>
                <button title='Apps' className="dropdownButton" onClick={toggleDropdown}>
                    <FontAwesomeIcon icon={faGrip} size="lg" />
                </button>
                {showDropdown && (
                    <div className="dropdown">
                        <Link to="/home" className="dropdownLink">
                            <FontAwesomeIcon icon={faHome} size="lg" />
                            <span className="dropdownLinkText">Home</span>
                        </Link>
                        <Link to="/patients" className="dropdownLink">
                            <FontAwesomeIcon icon={faHeart} size="lg" />
                            <span className="dropdownLinkText">Patients</span>
                        </Link>
                        <Link to="/claims" className="dropdownLink">
                            <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />
                            <span className="dropdownLinkText">Claims</span>
                        </Link>
                        <Link to="/ai" className="dropdownLink">
                            <FontAwesomeIcon icon={faBrain} size="lg" />
                            <span className="dropdownLinkText">AI</span>
                        </Link>
                    </div>
                )}
                <button className="dropdownButton" onClick={toggleAccountDropdown}>
                    <FontAwesomeIcon icon={faCircleUser} size="3x" />
                </button>
                {showAccountDropdown && (
                    <div className="dropdown">
                        <div className='authText'>
                            <div>
                                <span className="nameBold">{firstName} {lastName}</span>
                            </div>
                            <div>
                                <span>{email}</span>
                            </div>
                        </div>
                        <Link to="/settings" className="dropdownLink">
                            <FontAwesomeIcon icon={faCog} size="lg" />
                            <span className="dropdownLinkText">Settings</span>
                        </Link>
                        <Link to="/logs" className="dropdownLink">
                            <FontAwesomeIcon icon={faChartLine} size="lg" />
                            <span className="dropdownLinkText">Logs</span>
                        </Link>
                        {shouldShowApiQueueLink && (
                            <Link to="/apiqueue" className="dropdownLink">
                                <FontAwesomeIcon icon={faServer} size="lg" />
                                <span className="dropdownLinkText">API Queue</span>
                            </Link>
                        )}
                        <Link to="/usersupport" className="dropdownLink">
                            <FontAwesomeIcon icon={faHeadset} size="lg" />
                            <span className="dropdownLinkText">Support</span>
                        </Link>
                        <a className="dropdownLink">
                            <AuthDetails />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Topbar;
