import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, off } from "firebase/database";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useUID from "../../General/useUID";

const GeneralClaimVolume = () => {
  const [loading, setLoading] = useState(true);
  const [claimChartData, setClaimChartData] = useState([]);
  const [timeFrame, setTimeFrame] = useState("1m");
  const [uid, subUserUID] = useUID(); // Ensure subUserUID is used appropriately or removed if not necessary

  useEffect(() => {
    if (!uid) return;

    setLoading(true);
    const database = getDatabase();
    const histogramRef = ref(database, `aggregateClaimsHistogram/${uid}`);

    const unsubscribe = onValue(
      histogramRef,
      (snapshot) => {
        const rawData = snapshot.val();
        if (rawData) {
          // Sort and format data
          const sortedAndFormattedData = rawData
            .map((item) => ({
              ...item,
              date: formatDate(item.date), // Format date
            }))
            .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort data by date

          setClaimChartData(sortedAndFormattedData);
        } else {
          setClaimChartData([]);
        }
        setLoading(false);
      },
      (error) => {
        // console.error("Error fetching claim volume data:", error);
        setLoading(false);
      }
    );

    return () => off(histogramRef, "value", unsubscribe);
  }, [uid, timeFrame]); // Ensure timeFrame is used appropriately or adjust dependency array

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{label}</p>
          <p>Total Amount: ${payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return <div></div>; // You can use HexagonSpinner here if it's intended for loading indication
  }

  return (
    <div className="SingleChart">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3 style={{ textAlign: "center" }}>Claim Volume</h3>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
      >
        <select
          value={timeFrame}
          onChange={(e) => setTimeFrame(e.target.value)}
        >
          <option value="1w">1 Week</option>
          <option value="1m">1 Month</option>
          <option value="1y">1 Year</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={claimChartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis tickFormatter={(value) => `$${value}`} />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="totalAmount"
            stroke="#00316f"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

// Helper function to format date strings
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
};

export default GeneralClaimVolume;
