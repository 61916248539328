import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import PayerSelection from "../../Payers/PayerSelection";

function PayerMatchingModal({ onClose, initialMode }) {
  const [payerMatches, setPayerMatches] = useState([]);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [payerName, setPayerName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(initialMode || "all"); // If no initial mode, default to 'all'
  const [showAddPayerForm, setShowAddPayerForm] = useState(false);
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;
    fetchPayerMatches();
  }, [uid, mode]); // Re-fetch when uid or mode changes

  const handleModeChange = (newMode) => {
    setMode(newMode);
    resetForm(); // Reset form when mode changes
  };

  const handlePayerSelect = (payer) => {
    setSelectedPayer(payer);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!payerName.trim()) {
      alert("Please provide a name for the matched payer.");
      return;
    }

    if (!selectedPayer || !selectedPayer.name) {
      alert("Please select a valid payer from the list.");
      return;
    }

    await addDoc(collection(db, `users/${uid}/payerMatches`), {
      name: payerName.trim(),
      payer: selectedPayer, // Save the raw payer data
    });

    fetchPayerMatches();
    resetForm();
  };

  const fetchPayerMatches = async () => {
    setIsLoading(true);
    const payerMatchesCollection = collection(db, `users/${uid}/payerMatches`);
    const payerMatchesSnapshot = await getDocs(payerMatchesCollection);
    const payerMatchesData = payerMatchesSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })).filter((match) => mode === "all" || (mode === "claims" && match.payer.CPID) || (mode === "eligibility" && match.payer.RealtimePayerID));
    setPayerMatches(payerMatchesData);
    setIsLoading(false);
  };

  const handleDelete = async (matchId) => {
    const matchRef = doc(db, `users/${uid}/payerMatches`, matchId);

    try {
      await deleteDoc(matchRef);
      fetchPayerMatches();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleAddPayerClick = () => {
    setShowAddPayerForm(true);
  };

  const handleShowEligibilityMatches = () => {
    setMode("eligibility"); // Update mode to show only eligibility matches
    resetForm(); // Reset form when switching to eligibility mode
  };

  const handleBackClick = () => {
    resetForm(); // Reset form when back button is clicked
  };

  const resetForm = () => {
    setSelectedPayer(null);
    setPayerName("");
    setShowAddPayerForm(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="filesCloseButton" onClick={onClose}>X</button>
        <div className="padded-top">
          {/* Mode toggle buttons */}
          <button
            onClick={() => handleModeChange("all")}
            className={`mode-button ${mode === "all" ? "selected-mode" : ""}`}
          >
            All
          </button>
          <button
            onClick={() => handleModeChange("claims")}
            className={`mode-button ${mode === "claims" ? "selected-mode" : ""}`}
          >
            Claims
          </button>
          <button
            onClick={handleShowEligibilityMatches}
            className={`mode-button ${mode === "eligibility" ? "selected-mode" : ""}`}
          >
            Eligibility
          </button>
        </div>
        {showAddPayerForm ? (
          <>
            <button className="backButton" onClick={handleBackClick}>Back</button>
            {selectedPayer ? (
              <form onSubmit={handleSubmit} className="padded-top">
                <h2>Please type the payer's name in your spreadsheet.</h2>
                <p>This is the name that we will match to our system payer whenever you upload a batch file.</p>
                <input
                  value={payerName}
                  onChange={(e) => setPayerName(e.target.value.toUpperCase())}
                  placeholder={`Name for ${selectedPayer.name}`}
                />
                <button type="submit">Save Match</button>
              </form>
            ) : (
              <PayerSelection onSelect={handlePayerSelect} required mode={mode} />
            )}
          </>
        ) : (
          <>
            <button className="padded-top" onClick={handleAddPayerClick}>Add Payer Match</button>
            {!isLoading && (
              <div className="payerMatchedTable padded-top">
                <h4>Existing Matches:</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Name in Spreadsheet</th>
                      <th>Name in System</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payerMatches.map((match) => (
                      <tr key={match.id}>
                        <td>{match.name}</td>
                        <td>{match.payer.name}</td>
                        <td>
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => handleDelete(match.id)}
                            style={{ cursor: "pointer", color: "red" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {isLoading && (
              <div className="loading-container">
                <HexagonSpinner />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PayerMatchingModal;
