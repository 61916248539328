import React, { useState, useEffect, useCallback } from "react";
import styles from "../Claims/Claims.css";
import { db } from "../../firebase";
import {
  startAfter,
  limit,
  collection,
  getDocs,
  addDoc,
  orderBy,
  doc,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons"; // For regular (outline) heart
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons"; // For solid heart
import useUID from "../General/useUID";
import algoliasearch from "algoliasearch/lite";

const algoliaClient = algoliasearch(
  process.env.REACT_APP_AGNOLIA_CLIENT,
  process.env.REACT_APP_AGNOLIA_API
);

const algoliaIndex = algoliaClient.initIndex(
  process.env.REACT_APP_AGNOLIA_INDEX
);

const ITEMS_PER_PAGE = 100; // Adjust number of items per page as needed

function PayerSelection({ onSelect, TradingPartnerName, mode }) {
  const [uid, subUserUID] = useUID();
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [payers, setPayers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [favoritePayers, setFavoritePayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePayers, setHasMorePayers] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [hasAutoSelected, setHasAutoSelected] = useState(false);
  const [favoritesFetched, setFavoritesFetched] = useState(false);
  const [showFavorites, setShowFavorites] = useState(mode !== "website"); // Show favorites by default unless in "website" mode

  useEffect(() => {
    if (mode === "website") fetchData(1);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await fetchFavoritePayers();
  
      // Set showFavorites to true only after fetching favorites
      if (favoritesFetched && favoritePayers.length > 0) {
        setShowFavorites(true);
      }
  
      await fetchData(1);
      setIsLoading(false);
    };
  
    if (uid && mode !== "website") {
      loadData();
    }
  }, [uid, mode, favoritesFetched, favoritePayers.length]); // React to changes in uid, mode, favoritesFetched, or the number of favoritePayers
  
  
  useEffect(() => {
    if (uid && favoritesFetched && favoritePayers.length > 0) {
      fetchData(currentPage);
    }
  }, [favoritePayers.length, uid, currentPage, favoritesFetched]);
  

  useEffect(() => {
    if (!searchTerm && !isMounted) {
      setCurrentPage(1);
    }

    searchPayers();
  }, [searchTerm, selectedState]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchData(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (TradingPartnerName) {
      autoSelectPayer(TradingPartnerName);
    }
  }, [TradingPartnerName, payers]);

  useEffect(() => {
    setHasAutoSelected(false);
  }, [TradingPartnerName]);

  const fetchData = async (page) => {
    setIsLoading(true);

    try {
      let queryRef = collection(db, "Payers");

      // Initial query setup with orderBy
      if (page === 1) {
        queryRef = query(queryRef, orderBy("payerName"), limit(ITEMS_PER_PAGE));
      } else if (lastDoc) {
        // For subsequent pages, start after the last document from the previous fetch
        queryRef = query(
          queryRef,
          orderBy("payerName"),
          startAfter(lastDoc),
          limit(ITEMS_PER_PAGE)
        );
      }

      const querySnapshot = await getDocs(queryRef);
      const payerData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (payerData.length > 0) {
        const mergedPayers = mergePayersWithFavorites(
          payerData,
          favoritePayers
        );

        setPayers((prevPayers) => (page === 1 ? mergedPayers : [...prevPayers, ...mergedPayers]));
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setHasMorePayers(false);
      }
    } catch (error) {
      console.error("Error fetching payers:", error);
    }

    setIsLoading(false);
  };

  const fetchFavoritePayers = async () => {
    if (!uid) {
      console.error("User ID is undefined");
      return; // Early return if UID is undefined
    }

    const userDocRef = doc(db, "users", uid);
    const payersCollectionRef = collection(userDocRef, "favoritePayers");
    try {
      const querySnapshot = await getDocs(payersCollectionRef);
      const fetchedFavoritePayers = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          payerName: data.payerName,
          ...(data.CPID && { CPID: data.CPID }),
          ...(data.RealtimePayerID && {
            RealtimePayerID: data.RealtimePayerID,
          }),
        };
      });

      setFavoritePayers(fetchedFavoritePayers); // Update state directly
      setFavoritesFetched(true); // Indicate that favorites have been fetched
    } catch (error) {
      console.error("Error fetching favorite payers:", error);
      setFavoritesFetched(false); // Optionally reset if fetching fails
    }
  };

  const mergePayersWithFavorites = (payerData, favoritePayers) => {
    // Create a Set for quick lookup of favorite payer names
    const favoritePayerNames = new Set(
      favoritePayers.map((fav) => fav.payerName.toUpperCase())
    );

    // Add missing favorites to the payerData
    const updatedPayerData = [...payerData];
    favoritePayers.forEach((favorite) => {
      if (
        !updatedPayerData.some(
          (payer) =>
            payer.payerName.toUpperCase() === favorite.payerName.toUpperCase()
        )
      ) {
        // If the favorite payer is not found in the current list, add it
        updatedPayerData.unshift({
          ...favorite,
          isFavorite: true, // Mark this entry as a favorite explicitly
        });
      }
    });

    // Sort payers so favorites are at the top
    const sortedPayers = updatedPayerData.sort((a, b) => {
      const aIsFavorite = favoritePayerNames.has(a.payerName.toUpperCase());
      const bIsFavorite = favoritePayerNames.has(b.payerName.toUpperCase());
      if (aIsFavorite && !bIsFavorite) return -1;
      if (!aIsFavorite && bIsFavorite) return 1;
      return a.payerName.localeCompare(b.payerName);
    });

    // Mark payers as favorite or not for UI indication
    const markedFavorites = sortedPayers.map((payer) => ({
      ...payer,
      isFavorite: favoritePayerNames.has(payer.payerName.toUpperCase()),
    }));

    return markedFavorites;
  };

  const searchPayers = async () => {
    setIsLoading(true);
    setShowFavorites(false); // Switch to "Show All Payers" mode on search

    try {
      // Step 1: Fetch IDs from Firestore based on state
      let payerIds = [];
      if (selectedState) {
        const stateQuery = query(
          collection(db, "Payers"),
          where("State", "==", selectedState)
        );
        const querySnapshot = await getDocs(stateQuery);
        payerIds = querySnapshot.docs.map((doc) => doc.id); // Assuming doc.id can be correlated with an attribute in Algolia
      }

      // Step 2: Search in Algolia using these IDs
      let filters = "";
      if (payerIds.length > 0) {
        // Construct an Algolia filter query using the IDs
        filters = `objectID:${payerIds.join(" OR objectID:")}`;
      }

      const { hits } = await algoliaIndex.search(searchTerm, {
        filters, // Use the constructed filter string
        hitsPerPage: 100,
      });

      setPayers(hits);
      setHasMorePayers(hits.length === 100);
    } catch (error) {
      console.error(
        "Error combining Firestore state filtering with Algolia search:",
        error
      );
    }

    setIsLoading(false);
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    // Check if it's the right time to load more payers
    if (scrollHeight - scrollTop <= clientHeight + 10 && hasMorePayers) {
      // Only load more if no current loading process is happening
      if (!isLoading) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleSelectEligibility = (payer) => {
    const nextPriority = selectedPayers.length + 1;
    const updatedSelectedPayers = [
      ...selectedPayers,
      { payer, priority: nextPriority },
    ];
    setSelectedPayers(updatedSelectedPayers);
    onSelect(
      updatedSelectedPayers.map((sp) => ({
        name: sp.payer.payerName,
        tradingPartnerId: sp.payer.RealtimePayerID,
        priority: sp.priority,
      }))
    );
  };

  const handleUnselect = (payer) => {
    const updatedSelectedPayers = selectedPayers.filter(
      (sp) => sp.payer !== payer
    );
    updatedSelectedPayers.forEach((sp, index) => {
      sp.priority = index + 1;
    });
    setSelectedPayers(updatedSelectedPayers);
    onSelect(
      updatedSelectedPayers.map((sp) => ({
        name: sp.payer.payerName,
        tradingPartnerId: sp.payer.RealtimePayerID,
        priority: sp.priority,
      }))
    );
  };

  const handleClick = (payer) => {
    if (mode === "eligibility") {
      const isSelected = selectedPayers.some((sp) => sp.payer === payer);
      if (isSelected) {
        handleUnselect(payer);
      } else {
        handleSelectEligibility(payer);
      }
    } else if (mode === "claims") {
      if (selectedPayer && selectedPayer.payerName === payer.payerName) {
        setSelectedPayer(null);
        onSelect(null);
      } else {
        setSelectedPayer(payer);
        onSelect({ name: payer.payerName, tradingPartnerId: payer.CPID });
      }
    } else if (mode === "all") {
      // In "all" mode, toggle detailed view for the payer
      if (selectedPayer && selectedPayer.payerName === payer.payerName) {
        setSelectedPayer(null);
        onSelect(null);
      } else {
        setSelectedPayer(payer);
        onSelect({
          name: payer.payerName,
          ...payer,
        });
      }
    }
  };

  const handleChangeClick = () => {
    setSelectedPayer(null); // Reset the selected payer
  };

  const fetchAllPayers = async () => {
    const queryRef = query(collection(db, "Payers"), orderBy("payerName"));
    const querySnapshot = await getDocs(queryRef);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const autoSelectPayer = useCallback(
    async (TradingPartnerName) => {
      if (hasAutoSelected) return;
      setIsLoading(true);
  
      try {
        const allPayers = await fetchAllPayers();
        if (!allPayers || allPayers.length === 0) {
          console.error("No payers found in the database.");
          setIsLoading(false);
          return;
        }
  
        const upperCaseTradingPartnerName = TradingPartnerName.toUpperCase();
        const foundPayer = allPayers.find(
          (payer) => payer.payerName.toUpperCase() === upperCaseTradingPartnerName
        );
  
        if (foundPayer) {
          switch (mode) {
            case "eligibility":
              handleSelectEligibility(foundPayer);
              break;
            case "claimStatus":
            case "claims":
            case "all":
              setSelectedPayer(foundPayer);
              onSelect({ name: foundPayer.payerName, tradingPartnerId: foundPayer.CPID });
              break;
            default:
              console.error("Unsupported mode:", mode);
              break;
          }
          setHasAutoSelected(true);
        } else {
          console.error("Payer not found for name:", TradingPartnerName);
        }
      } catch (error) {
        console.error("Error in autoSelectPayer:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [hasAutoSelected, mode, handleSelectEligibility, setSelectedPayer]
  );
  
  
  const modifyFavorite = useCallback(
    async (payer, add = true) => {
      try {
        const userDocRef = doc(db, "users", uid);
        if (add) {
          // Assuming payerDataToModify includes all necessary fields
          await addDoc(collection(userDocRef, "favoritePayers"), {
            payerName: payer.payerName,
            CPID: payer.CPID,
            RealtimePayerID: payer.RealtimePayerID,
          });
          // Update favoritePayers state to include the new favorite
          setFavoritePayers((prev) => [
            ...prev,
            {
              payerName: payer.payerName,
              CPID: payer.CPID,
              RealtimePayerID: payer.RealtimePayerID,
            },
          ]);
          // Switch to "Show Favorites" mode
          setShowFavorites(true);
        } else {
          // Remove the payer from favorites
          const payersCollectionRef = collection(userDocRef, "favoritePayers");
          const q = query(
            payersCollectionRef,
            where("payerName", "==", payer.payerName)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (docSnapshot) => {
            await deleteDoc(
              doc(db, "users", uid, "favoritePayers", docSnapshot.id)
            );
          });
          // Update favoritePayers state to exclude the removed favorite
          setFavoritePayers((prev) =>
            prev.filter((fp) => fp.payerName !== payer.payerName)
          );
        }
      } catch (error) {
        console.error(
          `Error ${add ? "adding" : "removing"} payer to favorites:`,
          error
        );
      }
    },
    [uid]
  );

  const sortedAndFilteredPayersForDisplay = React.useMemo(() => {
    // Step 1: Filter payers based on the favorite mode toggle
    let filteredPayers = showFavorites ? favoritePayers : payers;

    // Step 2: Mode-specific filtering
    if (mode === "eligibility" || mode === "claimStatus") {
      filteredPayers = filteredPayers.filter((payer) => payer.RealtimePayerID);
    } else if (mode === "claims") {
      filteredPayers = filteredPayers.filter((payer) => payer.CPID);
    } else if (mode === "all" || mode === "website") {
      // In 'all' or 'website' mode, display all payers with any available details
      filteredPayers = filteredPayers.map((payer) => ({
        ...payer,
        CPID: payer.CPID || "Not Available",
        RealtimePayerID: payer.RealtimePayerID || "Not Available",
      }));
    }

    // Step 3: Prioritize selected payers for eligibility mode
    if (mode === "eligibility") {
      const selectedIds = new Set(
        selectedPayers.map((sp) => sp.payer.RealtimePayerID)
      );
      const selectedPayersFirst = selectedPayers.map((sp) => sp.payer);
      const nonSelectedPayers = filteredPayers.filter(
        (payer) => !selectedIds.has(payer.RealtimePayerID)
      );

      // Combine them back with selected payers at the top
      return [...selectedPayersFirst, ...nonSelectedPayers];
    }

    return filteredPayers;
  }, [payers, selectedPayers, mode, showFavorites, favoritePayers]);

  return (
    <div
      className="payerContainer"
      style={{
        maxHeight: "20rem",
        overflowY: "scroll",
        margin: "1rem",
        overflowX: "hidden"      }}
      onScroll={handleScroll}
    >
      {(!selectedPayer || mode === "eligibility") && (
        <div className="stickyContainer" style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Search Payers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="payerSearch"
              style={{ marginRight: "1rem", whiteSpace: "nowrap" }}
            />
            <button
              onClick={() => setShowFavorites((prev) => !prev)}
              className={`${styles.payerButton} ${showFavorites ? styles.selectedButton : ""} button-favorite`}
              style={{ whiteSpace: "nowrap" }}
            >
              <FontAwesomeIcon icon={showFavorites ? fasHeart : farHeart} /> {showFavorites ? "Show All Payers" : "Show Favorites"} 
            </button>
          </div>
      )}

      <table className={styles.table}>
        <thead className="payertableHeader thread">
          <tr>{mode === "eligibility" && <th>Priority</th>}</tr>
        </thead>

        {mode !== "eligibility" && selectedPayer ? (
          <div>
            <p>Selected Payer: {selectedPayer.payerName}</p>
            <button onClick={handleChangeClick}>Change</button>
          </div>
        ) : (
          <tbody>
            {sortedAndFilteredPayersForDisplay.map((payer, index) => {
              const isSelected = selectedPayers.some((sp) => sp.payer === payer);
              const isFavorited = payer.isFavorite;
              const priority =
                (selectedPayers || []).find((sp) => sp.payer === payer)
                  ?.priority || "—";

              return (
                <tr
                  key={index}
                  onClick={() => handleClick(payer)}
                  className={isSelected ? styles.selected : ""}
                >
                  <td>
                    <label htmlFor={`payer-${index}`}>{payer.payerName}</label>
                  </td>
                  {/* Additional columns for eligibility mode */}
                  {mode === "eligibility" && <td>{priority}</td>}
                  <td className="payerFaintHeart">
                    {showFavorites ? (
                      <FontAwesomeIcon
                        icon={fasHeart}
                        className="faintHeart"
                        onClick={(e) => {
                          e.stopPropagation();
                          modifyFavorite(payer, false); // Removing from favorites
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        title="Make a favorite payer"
                        className="faintHeart"
                        icon={farHeart}
                        onClick={(e) => {
                          e.stopPropagation();
                          modifyFavorite(payer, true); // Adding to favorites
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}

        {/* {isLoading && (
              <div className="loadingContainer">
                <HexagonSpinner />
              </div>
            )} */}
      </table>
    </div>
  );
}

export default PayerSelection;
