import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNotesMedical,
  faSyringe,
  faFlask,
  faPills,
  faHeartbeat,
  faPeopleRoof,
} from "@fortawesome/free-solid-svg-icons";
import FamilyHistory from "./FamilyHistory";
import ImmunizationsAllergies from "./ImmunizationsAllergies";
import LabandTest from "./LabandTest";
import Medications from "./Medications";
import Vitals from "./Vitals";
import PatientHistory from "./PatientHistory";
import styles from "../PatientView.module.css";
import PatientMedicalHistoryView from "./MedicalHistoryView";
import HelpArticleLink from "../../Articles/HelpArticleLink";

const sections = [
  { name: "Patient History", component: PatientHistory, icon: faNotesMedical },
  { name: "Family History", component: FamilyHistory, icon: faPeopleRoof },
  {
    name: "Immunizations and Allergies",
    component: ImmunizationsAllergies,
    icon: faSyringe,
  },
  { name: "Lab and Test Results", component: LabandTest, icon: faFlask },
  { name: "Medication", component: Medications, icon: faPills },
  { name: "Vitals", component: Vitals, icon: faHeartbeat },
];

function MedicalHistory({ patientId }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  const openModal = (section) => {
    setActiveSection(section.name);
    setModalOpen(true);
    setShowOptions(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleAddMedicalHistoryClick = () => {
    setShowOptions(true);
  };

  const renderSectionForm = () => {
    const section = sections.find((s) => s.name === activeSection);
    if (!section) return null;

    const SectionComponent = section.component;
    return (
      <SectionComponent
        patientId={patientId}
        onClose={closeModal} // Passing the onClose function here
      />
    );
  };

  return (
    <div>
      <h3>Medical History</h3>
      <div
        style={{
          height: "1px",
          backgroundColor: "black",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>
      {!showOptions && (
        <button onClick={handleAddMedicalHistoryClick}>
          <FontAwesomeIcon icon={faNotesMedical} /> Add Medical History
        </button>
      )}

      {showOptions && (
        <div>
          {sections.map((section) => (
            <button key={section.name} onClick={() => openModal(section)}>
              <FontAwesomeIcon icon={section.icon} /> {section.name}
            </button>
          ))}

          <HelpArticleLink
            article={{
              title: "Medical History",
              link: "https://popularishealth.com/article/Medical-History",
            }}
          />
        </div>
      )}

      {modalOpen && (
        <div className="overlay">
          <div className="modalBox">
            <div className={styles.medicalHistoryContainer}>
              <button onClick={closeModal} className="filesCloseButton">
                X
              </button>
              <h3>{activeSection}</h3>
              {renderSectionForm()}
            </div>
          </div>
        </div>
      )}
      <PatientMedicalHistoryView patientId={patientId} />
    </div>
  );
}

export default MedicalHistory;
