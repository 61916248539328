import { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";

const useUID = () => {
  const [uid, setUID] = useState(null);
  const [subuserUID, setSubuserUID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUIDsFromSession = () => {
      const parentUID = sessionStorage.getItem('parentUID');
      const effectiveUID = sessionStorage.getItem('effectiveUID');
      
      if (parentUID && effectiveUID && parentUID !== effectiveUID) {
        setUID(parentUID);
        setSubuserUID(effectiveUID); // Setting subuserUID only if it differs from parentUID
      } else if (parentUID) {
        setUID(parentUID); // Use parentUID if no distinction is needed
      } else if (effectiveUID) {
        setUID(effectiveUID); // Use effectiveUID as a fallback
      } else {
        setError("No UID found in session storage.");
      }
      setIsLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUIDsFromSession();
      } else {
        setIsLoading(false);
        setError("No user is authenticated");
      }
    });

    return () => unsubscribe();
  }, []);

  // Returning values in an array format
  return [uid, subuserUID, isLoading, error];
};

export default useUID;
