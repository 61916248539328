import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HelpArticleLink = ({ article }) => {
  const [showSupport, setShowSupport] = useState(false);

  const handleNeedHelpClick = () => {
    // Toggle visibility of the support link/button
    setShowSupport(!showSupport);
  };

  return (
    <div className='helper-container'>
      <span className='need-help' onClick={handleNeedHelpClick}>
        Need Help?
      </span>
      {showSupport && (
        <Link to="/userSupport" style={{ marginRight: '10px' }}>
          Contact Support
        </Link>
      )}
      <a href={article.link} target="_blank" rel="noopener noreferrer">
        Related Article: {article.title}
      </a>
    </div>
  );
};

export default HelpArticleLink;
