import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { httpsCallable, getFunctions } from 'firebase/functions';
import '../MainStyles.css';

const functions = getFunctions();

const APICredentialsButton = () => {
  const [showCredentials, setShowCredentials] = useState(false);
  const [apiKey, setApiKey] = useState(null);

  const uid = auth.currentUser.uid;

  useEffect(() => {
    async function fetchApiKey() {
      const q = query(collection(db, 'APIKeys'), where("clientId", "==", uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          setApiKey(doc.data().apiKey);
        }
      });
    }

    fetchApiKey();
  }, [uid]);

  const generateApiKey = async () => {
    const generateApiKeyFunction = httpsCallable(functions, "generateApiKey");
    const result = await generateApiKeyFunction();
    setApiKey(result.data.apiKey);
  };

  return (
    <div className='apiButton'>
      {apiKey ? (
        <div>
          <button className="api-credentials-button" onClick={() => setShowCredentials(!showCredentials)}>
            <FontAwesomeIcon icon={faKey} /> API Credentials
          </button>
          {showCredentials && (
            <div>
              <div>Client ID: {uid}</div>
              <div>API Key: {apiKey}</div>
              <button onClick={generateApiKey}>Regenerate API Key</button>
            </div>
          )}
        </div>
      ) : (
        <button onClick={generateApiKey}>Generate API Key</button>
      )}
    </div>
  );
};

export default APICredentialsButton;
