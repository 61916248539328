import { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { format } from 'date-fns';
import { auth, db } from "../../../firebase";
import AuthDetails from "../../Auth/AuthDetails";
import Footer from "../Home/Footer";
import Sidebar from "../Topbar";
import styles from "../MainStyles.css";
import useUID from "../useUID";
import HexagonSpinner from "../Animations/Hexspinner";

function Logs() {
  const [requests, setRequests] = useState([]);
  const [logs, setLogs] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [loading, setLoading] = useState(true); // New state to track loading
  const [uid, subUserUID, isLoading, error] = useUID();
  const [view, setView] = useState('logs'); // Set logs as the default view

  useEffect(() => {
    if (!uid) return;

    // Fetching API requests
    const requestsRef = collection(db, "users", uid, "API");
    const requestQuery = query(requestsRef, orderBy("timestamp", "desc"));
    const unsubscribeRequests = onSnapshot(requestQuery, (querySnapshot) => {
      let requestsList = [];
      let total = 0;
      querySnapshot.forEach((doc) => {
        const requestData = doc.data();
    
        if (requestData.APIRequests) {
          const apiRequestsCount = parseInt(requestData.APIRequests, 10);
          total += apiRequestsCount;
        }
    
        requestsList.push({
          timestamp: requestData.timestamp,
          apiRequests: requestData.APIRequests
        });
      });
    
      setRequests(requestsList);
      setTotalRequests(total);
      setLoading(false); // Set loading to false when data is fetched
    });

    // Fetching logs
    const logsRef = collection(db, "users", uid, "logs");
    const logsQuery = query(logsRef, orderBy("timestamp", "desc"));
    const unsubscribeLogs = onSnapshot(logsQuery, (querySnapshot) => {
      let logsList = [];
      querySnapshot.forEach((doc) => {
        const logData = doc.data();
        logsList.push({
          timestamp: logData.timestamp,
          message: logData.message
        });
      });

      setLogs(logsList);
      setLoading(false); // Set loading to false when data is fetched
    });

    return () => {
      unsubscribeRequests();
      unsubscribeLogs();
    };
  }, [uid]);

  return (
    <div className="background">
      <Sidebar />

      <div className="contentContainer">
        <div className="toggleButtons">
          <button
            className={`button ${view === 'logs' ? 'selected' : ''}`}
            onClick={() => setView('logs')}
          >
            View User Logs
          </button>
          <button
            className={`button ${view === 'apiRequests' ? 'selected' : ''}`}
            onClick={() => setView('apiRequests')}
          >
            View API Requests
          </button>
        </div>

        {loading ? (
          <HexagonSpinner /> // Show spinner while loading
        ) : (
          <>
            <div className={`dashboardContainer ${view === 'apiRequests' ? '' : 'hidden'}`}>
              <div className="apiRequestsSection">
                <h3>API Requests Made</h3>
                <div className="requestsContainer">
                  {requests.map((request, index) => (
                    <div key={index} className="requestContainer">
                      <p className="timestamp">
                        {format(request.timestamp.toDate(), 'yyyy-MM-dd HH:mm:ss')}
                      </p>
                      <p className={styles.apiRequests}>
                        API requests: {JSON.stringify(request.apiRequests)}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="totalRequestsContainer">
                  <p>Total Requests: {totalRequests}</p>
                </div>
              </div>
            </div>

            <div className={`dashboardContainer ${view === 'logs' ? '' : 'hidden'}`}>
              <div className="logsSection">
                <h3>User Logs</h3>
                <div className="logsContainer">
                  {logs.map((log, index) => (
                    <div key={index} className="logContainer">
                      <p className="timestamp">
                        {format(log.timestamp.toDate(), 'yyyy-MM-dd HH:mm:ss')}
                      </p>
                      <p style={{ fontSize: "14px" }} className={styles.message}>
                        {log.message}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Logs;
