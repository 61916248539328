import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { collection, addDoc, getDocs, updateDoc, doc } from "firebase/firestore";

function StepEight({ onNext, prevStep }) {
    const [uid] = useUID();
    const [references, setReferences] = useState([]);

    useEffect(() => {
        if (!uid) return;

        const fetchReferences = async () => {
            const colRef = collection(db, `users/${uid}/applications/ipaApplication/references`);
            try {
                const querySnapshot = await getDocs(colRef);
                const referencesData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setReferences(referencesData);
            } catch (error) {
                console.error("Error fetching references:", error);
            }
        };

        fetchReferences();
    }, [uid]);

    const addReferences = async () => {
        const colRef = collection(db, `users/${uid}/applications/ipaApplication/references`);
        try {
            const docRef = await addDoc(colRef, { name: '', email: '', relationship: '' });
            setReferences([...references, { id: docRef.id, name: '', email: '', relationship: '' }]);
        } catch (error) {
            console.error("Error adding reference:", error);
        }
    };

    const handleReferencesChange = async (index, field, value) => {
        const newReferences = [...references];
        newReferences[index] = { ...newReferences[index], [field]: value };
        setReferences(newReferences);

        const docRef = doc(db, `users/${uid}/applications/ipaApplication/references`, newReferences[index].id);
        try {
            await updateDoc(docRef, { [field]: value });
        } catch (error) {
            console.error("Error updating reference:", error);
        }
    };

    return (
        <div>
            <h2>Add References for Your Practice</h2>
            {references.map((reference, index) => (
                <div key={index} className="input-group-row">
                    <div className="input-field">
                        <label>Name:</label>
                        <input
                            type="text"
                            value={reference.name}
                            onChange={(e) => handleReferencesChange(index, 'name', e.target.value)}
                        />
                    </div>
                    <div className="input-field">
                        <label>Email or Phone:</label>
                        <input
                            type="text"
                            value={reference.email}
                            onChange={(e) => handleReferencesChange(index, 'email', e.target.value)}
                        />
                    </div>
                    <div className="input-field">
                        <label>Relationship:</label>
                        <input
                            type="text"
                            value={reference.relationship}
                            onChange={(e) => handleReferencesChange(index, 'relationship', e.target.value)}
                        />
                    </div>
                </div>
            ))}
            <button style={{width: '15rem', marginBottom: "2rem"}} onClick={addReferences} className='ipa-button-add'>+ Add Reference</button>
        </div>
    );
}

export default StepEight;
