import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  collection,
  onSnapshot,
  doc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import useUID from "../../General/useUID";
import NewReport from "./NewReport";
import styles from "../../PatientFiles/PatientFiles.modules.css";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import HexagonSpinner from "../../General/Animations/Hexspinner";

function ClaimsReportsView({ patientId, claimId }) {
  const [claimReports, setClaimReports] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [uid] = useUID();
  const [showNewReportForm, setShowNewReportForm] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingReportData, setEditingReportData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [statusCodes, setStatusCodes] = useState([]);

  useEffect(() => {
    if (!uid || !patientId) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const claimReportsRef = collection(
      doc(db, "patients", uid, "patientData", patientId),
      "reports"
    );

    const unsubscribe = onSnapshot(
      claimReportsRef,
      (querySnapshot) => {
        let reports = querySnapshot.docs.map((doc) => {
          const reportData = doc.data();
          return { id: doc.id, ...reportData };
        });

        setClaimReports(reports);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching claim reports:", error);
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [uid, patientId]);

  const toggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleDeleteClick = (reportId) => {
    setShowDeleteModal(true);
    setReportToDelete(reportId);
  };

  const handleDeleteReport = async () => {
    if (reportToDelete) {
      await deleteDoc(
        doc(
          db,
          "patients",
          uid,
          "patientData",
          patientId,
          "reports",
          reportToDelete
        )
      );
      setShowDeleteModal(false);
      setReportToDelete(null);
    }
  };

  const handleEditReport = (reportData) => {
    setIsEditMode(true);
    setEditingReportData(reportData);
    setShowNewReportForm(true);
  };

  useEffect(() => {
    // Fetch status codes
    fetchStatusCodes();
  }, []); // Empty dependency array to ensure it runs only once

  const fetchStatusCodes = async () => {
    const statusCodesRef = collection(db, "ClaimStatusCodes");
    try {
      const querySnapshot = await getDocs(statusCodesRef);
      const codes = querySnapshot.docs
        .map((doc) => ({
          number: doc.id,
          description: doc.data().Description,
        }))
        .sort((a, b) => parseInt(a.number) - parseInt(b.number));
      setStatusCodes(codes);
    } catch (error) {
      console.error("Error fetching status codes:", error);
    }
  };

  const getStatusDescription = (code) => {
    const codeObj = statusCodes.find((c) => c.number === code);
    return codeObj ? codeObj.description : code;
  };

  const formatDate = (dateStr) => {
    if (dateStr && dateStr.length === 8) {
      return `${dateStr.substring(4, 6)}/${dateStr.substring(
        6,
        8
      )}/${dateStr.substring(0, 4)}`;
    }
    return dateStr; // Return original string if it doesn't match expected format
  };

  return (
    <div className="claimsReportsContainer">
      <button className="primary" onClick={() => setShowNewReportForm(true)}>
        {" "}
        <FontAwesomeIcon className="mr-3" icon={faPlus} /> New Report
      </button>
      {showNewReportForm && (
        <NewReport
          patientId={patientId}
          claimId={claimId}
          onClose={() => setShowNewReportForm(false)}
          isEditMode={isEditMode}
          existingReportData={editingReportData}
        />
      )}

      {isLoading ? (
        <HexagonSpinner />
      ) : claimReports.length > 0 ? (
        claimReports.map((report) => (
          <div
            key={report.id}
            className={`patientViewCard ${styles.patientViewCard}`}
            onClick={() => toggleExpand(report.id)}
          >
            {/* Displaying basic report information */}
            <h4>{getStatusDescription(report.claimStatusCode)}</h4>
            <p>
              Total Payment Amount: {"$"}
              {report.totalActualProviderPaymentAmount}
            </p>

            {expandedId === report.id && (
              <div className={styles.expandedSection}>
                {/* Display NPI if not null/undefined */}
                {report.npi && <p>NPI: {report.npi}</p>}

                {/* Display Control Number if not null/undefined */}
                {report.controlNumber && (
                  <p>Control Number: {report.controlNumber}</p>
                )}

                <p>
                  Claim Status Code:{" "}
                  {getStatusDescription(report.claimStatusCode)}
                </p>

                {/* Format and display Service Date */}
                <p>Service Date: {formatDate(report.serviceDate)}</p>

                {/* Display Procedure Code if not null/undefined */}
                {report.adjudicatedProcedureCode && (
                  <p>Procedure Code: {report.adjudicatedProcedureCode}</p>
                )}

                {/* Display Charge Amount if not an empty string */}
                {report.lineItemChargeAmount !== "" && (
                  <p>
                    Charge Amount: {"$"}
                    {report.lineItemChargeAmount}
                  </p>
                )}

                {/* Display Provider Payment Amount if not an empty string */}
                {report.lineItemProviderPaymentAmount !== "" && (
                  <p>
                    Provider Payment Amount: {"$"}
                    {report.lineItemProviderPaymentAmount}
                  </p>
                )}

                {/* Display adjustments if they exist */}
                {report.adjustments &&
                  report.adjustments.map((adjustment, index) => (
                    <div key={index}>
                      {/* Display Adjustment Amount if not 0 */}
                      {adjustment.adjustmentAmount !== 0 && (
                        <p>
                          Adjustment Amount: {"$"}
                          {adjustment.adjustmentAmount}
                        </p>
                      )}

                      {/* Display Adjustment Reason Code if not null/undefined */}
                      {adjustment.adjustmentReasonCode && (
                        <p>
                          Adjustment Reason Code:{" "}
                          {adjustment.adjustmentReasonCode}
                        </p>
                      )}

                      {/* Display Group Code if not null/undefined */}
                      {adjustment.claimAdjustmentGroupCodeValue && (
                        <p>
                          Group Code: {adjustment.claimAdjustmentGroupCodeValue}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            )}

            <button onClick={() => handleEditReport(report)}>
              Edit Report
            </button>
            <button onClick={() => handleDeleteClick(report.id)}>
              Delete Report
            </button>

            <Modal
              isOpen={showDeleteModal}
              onRequestClose={() => setShowDeleteModal(false)}
              className="confirmModal"
            >
              <h3>Are you sure you want to delete this report?</h3>
              <div className="confirmButtons">
                <button
                  className="secondaryButton"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button className="primaryButton" onClick={handleDeleteReport}>
                  Delete
                </button>
              </div>
            </Modal>
          </div>
        ))
      ) : (
        <p>No claim reports available for this patient.</p>
      )}
    </div>
  );
}

export default ClaimsReportsView;
