import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import styles from "../Claims.css";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const ReportsGenerator = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [claimsData, setClaimsData] = useState([]);
  const [uid] = useUID();
  const [searchInput, setSearchInput] = useState("");
  const [selectedPayers, setSelectedPayers] = useState(new Set());
  const [selectedProviders, setSelectedProviders] = useState(new Set());
  const [showFilters, setShowFilters] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFields, setSelectedFields] = useState({
    firstName: true,
    lastName: true,
    gender: true,
    dateOfBirth: true,
    memberId: true,
    controlNumber: true,
    patientControlNumber: true,
    address: true,
    city: true,
    state: true,
    postalCode: true,
    diagnosisCodes: true,
    provider: true,
    receiver: true,
    paymentResponsibilityLevelCode: true,
    serviceDate: true,
    procedureCodes: true,
    chargeAmount: true,
  });
  const [format, setFormat] = useState("xlsx");

  const calculateTotalChargeAmount = (serviceLines) => {
    return serviceLines.reduce((total, line) => {
      const chargeAmount = parseFloat(
        line.professionalService?.lineItemChargeAmount || 0
      );
      return total + chargeAmount;
    }, 0);
  };

  useEffect(() => {
    if (!uid) return;

    const db = getDatabase();
    const claimsRef = ref(db, `fetchClaimsData/${uid}`);

    const unsubscribe = onValue(
      claimsRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const claimsArray = Object.keys(data).map((key) => {
            const claim = data[key];
            const totalChargeAmount = calculateTotalChargeAmount(
              claim.serviceLines
            );
            return {
              ...claim,
              id: key,
              totalChargeAmount,
            };
          });
          setClaimsData(claimsArray);
        } else {
          setClaimsData([]);
        }
        setLoading(false);
      },
      {
        onlyOnce: true,
      }
    );

    return () => unsubscribe();
  }, [uid]);

  const handleFilterChange = (value, type) => {
    const updateSet = new Set(
      type === "payer" ? selectedPayers : selectedProviders
    );
    if (updateSet.has(value)) {
      updateSet.delete(value);
    } else {
      updateSet.add(value);
    }
    type === "payer"
      ? setSelectedPayers(updateSet)
      : setSelectedProviders(updateSet);
  };

  const formatDOB = (dob) => {
    if (!dob || dob.length !== 8) return dob;
    return `${dob.substring(4, 6)}/${dob.substring(6, 8)}/${dob.substring(
      0,
      4
    )}`;
  };

  const getUniqueOptions = (data, key) => {
    return [...new Set(data.map((claim) => claim[key]))];
  };

  const uniquePayers = getUniqueOptions(claimsData, "receiver");
  const uniqueProviders = getUniqueOptions(claimsData, "provider");

  const filteredClaimsData = claimsData.filter((claim) => {
    const matchesSearch = searchInput.toLowerCase();
    const dobFormatted = formatDOB(claim.dateOfBirth);

    const matchesPayers =
      selectedPayers.size === 0 || selectedPayers.has(claim.receiver);
    const matchesProviders =
      selectedProviders.size === 0 || selectedProviders.has(claim.provider);

    const serviceDate = new Date(claim.serviceLines[0]?.serviceDate || "");
    const fromDateObj = fromDate ? new Date(fromDate) : null;
    const toDateObj = toDate ? new Date(toDate) : null;
    const isAfterFromDate = !fromDate || serviceDate >= fromDateObj;
    const isBeforeToDate = !toDate || serviceDate <= toDateObj;

    return (
      (claim.firstName.toLowerCase().includes(matchesSearch) ||
        claim.lastName.toLowerCase().includes(matchesSearch) ||
        claim.receiver.toLowerCase().includes(matchesSearch) ||
        claim.provider.toLowerCase().includes(matchesSearch) ||
        claim.memberId.toLowerCase().includes(matchesSearch) ||
        dobFormatted.includes(matchesSearch) ||
        claim.diagnosisCodes.some(
          (code) =>
            typeof code === "string" &&
            code.toLowerCase().includes(matchesSearch)
        )) &&
      matchesPayers &&
      matchesProviders &&
      isAfterFromDate &&
      isBeforeToDate
    );
  });

  const handleDownload = () => {
    const dataForDownload = filteredClaimsData.map((claim) => {
      const formattedClaim = {};
      if (selectedFields.firstName) formattedClaim["First Name"] = claim.firstName;
      if (selectedFields.lastName) formattedClaim["Last Name"] = claim.lastName;
      if (selectedFields.gender) formattedClaim["Gender"] = claim.gender;
      if (selectedFields.dateOfBirth) formattedClaim["Date of Birth"] = formatDOB(claim.dateOfBirth);
      if (selectedFields.memberId) formattedClaim["Member ID"] = claim.memberId;
      if (selectedFields.controlNumber) formattedClaim["Control Number"] = claim.controlNumber;
      if (selectedFields.patientControlNumber) formattedClaim["Patient Control Number"] = claim.patientControlNumber;
      if (selectedFields.address) formattedClaim["Address 1"] = claim.address?.address1;
      if (selectedFields.city) formattedClaim["City"] = claim.address?.city;
      if (selectedFields.state) formattedClaim["State"] = claim.address?.state;
      if (selectedFields.postalCode) formattedClaim["Postal Code"] = claim.address?.postalCode;
      if (selectedFields.diagnosisCodes) formattedClaim["Diagnosis Codes"] = claim.diagnosisCodes.join(", ");
      if (selectedFields.provider) formattedClaim["Provider"] = claim.provider;
      if (selectedFields.receiver) formattedClaim["Receiver"] = claim.receiver;
      if (selectedFields.paymentResponsibilityLevelCode) formattedClaim["Payment Responsibility Level Code"] = claim.paymentResponsibilityLevelCode;
      if (selectedFields.serviceDate) formattedClaim["Service Date"] = claim.serviceLines.map((line) => formatDOB(line?.serviceDate || "")).join(", ");
      if (selectedFields.procedureCodes) formattedClaim["Procedure Codes"] = claim.serviceLines.map((line) => line.professionalService?.procedureCode).join(", ");
      if (selectedFields.chargeAmount) formattedClaim["Charge Amount"] = claim.serviceLines.map((line) => `$${line.professionalService?.lineItemChargeAmount}`).join(", ");
      return formattedClaim;
    });

    if (format === "xlsx") {
      const ws = XLSX.utils.json_to_sheet(dataForDownload);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ClaimsData");
      XLSX.writeFile(wb, "FilteredClaimsData.xlsx");
    } else if (format === "json") {
      const blob = new Blob([JSON.stringify(dataForDownload, null, 2)], { type: "application/json" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "FilteredClaimsData.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Loading every detail of every last claim.</h4>
      </div>
    );
  }

  return (
    <div className="Claims-inner-content" style={{ display: "flex", alignItems: "flex-start" }}>
      {/* <button className="filesCloseButton" onClick={onClose}>X</button> */}
      <div className="centerHeader">
            <h3>Reports Generator</h3>
            <p>Provide your requirements for the report and click Generate at the bottom.</p>
          </div>
       <div className="pb-4">
        <h3>Filter by Payer</h3>
        {uniquePayers.map((payer) => (
          <div key={payer}>
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={selectedPayers.has(payer)}
              onChange={() => handleFilterChange(payer, "payer")}
            />
            {payer}
          </div>
        ))}
        </div>
        <div className="pb-4">
        <h3>Filter by Provider</h3>
        {uniqueProviders.map((provider) => (
          <div key={provider}>
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={selectedProviders.has(provider)}
              onChange={() => handleFilterChange(provider, "provider")}
            />
            {provider}
          </div>
        ))}
        </div>
        <div className="pb-4">
        <div className="dateFilters">
          <label htmlFor="fromDate">From Date:</label>
          <input
            type="date"
            id="fromDate"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <label htmlFor="toDate">To Date:</label>
          <input
            type="date"
            id="toDate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
      </div>

      
      <div className="field-selection">
        <h4>Select Fields to Include</h4>
        <div className="fields-grid">
            {Object.keys(selectedFields).map((field) => (
            <div key={field}>
                <input
                type="checkbox"
                checked={selectedFields[field]}
                onChange={() => setSelectedFields({ ...selectedFields, [field]: !selectedFields[field] })}
                className="custom-checkbox"
                />
                {field}
            </div>
            ))}
        </div>
        </div>


        <div className="pb-4">
            <h3>File Format</h3>
          <label>
            <input
              type="radio"
              name="format"
              value="xlsx"
              checked={format === "xlsx"}
              onChange={(e) => setFormat(e.target.value)}
              className="custom-checkbox"
            />
            XLSX
          </label>
          <label>
            <input
              type="radio"
              name="format"
              value="json"
              checked={format === "json"}
              className="custom-checkbox"
              onChange={(e) => setFormat(e.target.value)}
            />
            JSON
          </label>
        </div>
        <button  onClick={handleDownload}>
          <FontAwesomeIcon title={"Download selected"} icon={faDownload} /> Generate Report
        </button>
      </div>
      
  );
};

export default ReportsGenerator;
