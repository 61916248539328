export const convertDateToMMDDYYYY = (inputDate) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const durationRegex = /^\d+:\d+:\d+$/; // Check for duration format (HH:MM:SS)
  
    if (durationRegex.test(inputDate)) {
      // If the input is a duration string, return it as is
      return inputDate;
    }
  
    if (!dateRegex.test(inputDate)) {
      return inputDate;
    }
  
    const [year, month, day] = inputDate.split("-");
    return `${month}/${day}/${year}`;
  };
  
  export const convertDateToYYYYMMDD = (inputDate) => {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Check if date is in MM/DD/YYYY format
    if (!dateRegex.test(inputDate)) {
      return inputDate;
    }
    const [month, day, year] = inputDate.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };
  