import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import styles from "./AUTH.css"; // Ensure your CSS module is correctly linked
import dallasPic from "../General/Dogs/dallas.png"; // Verify the path to your image
import { Link } from 'react-router-dom';
import { auth, db } from "../../firebase";
import Footer from "../General/Home/Footer";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccessMessage("A password reset link has been sent to your email address.");
        setErrorMessage("");
      })
      .catch((error) => {
        setErrorMessage("Error sending password reset link");
        setSuccessMessage("");
      });
  };

  return (
    <div className="background">
    <div className="AUTH-container auth-margin-top">
      <div className="side-by-side">
        <div className="left-content">
        <form style={{ alignItems: "normal" }} onSubmit={handleSubmit}>
          <h1 style={{textAlign: "left", marginBottom: "2rem"}} className='typography-headline'>Forgot Password</h1>
          <p style={{textAlign: "left ", marginBottom: "2rem"}} className='typography-label-alt-dark'>It happens to the best of us, including Dallas. Please enter your email address and we will send you reset instructions.</p>
          
          <div className="input-group-row">
            <div className="input-field">
                <label htmlFor="address1">Email Address:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{width: "15rem"}}
                  required
                />
            </div>
          </div>
          {successMessage && <div className='dark-text'>{successMessage}</div>}
          {errorMessage && <div className='dark-text'>{errorMessage}</div>}
          <div className="resetPassword">
            <button type="submit">Reset Password</button>
          </div>
          <><p><Link to="/signin" className="signin-btn">Back to Sign In</Link></p></>
        </form>
        </div>
         <div className="right-content">
          <div className="side-by-side">
            <img className="dog-image" src={dallasPic} alt="Winston the Dog" />
            <div className="dog-text">
              <h2 className="dog-h2">Dallas</h2>
              <p className='typography-label-alt-dark'>Dogs of Popularis</p>
            </div>
          </div>
         </div>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default ForgotPassword;
