import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import Modal from "react-modal";
import useUID from "../../General/useUID";

const SFSDMessageViewer = ({ patientId, claimId }) => {
  const [messages, setMessages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uid] = useUID();

  useEffect(() => {
    if (!uid || !patientId || !claimId) return;

    const fetchSFReports = async () => {
      try {
        const sfReportsRef = collection(
          db,
          `patients/${uid}/patientData/${patientId}/claims/${claimId}/SF`
        );
        const sfQuery = query(sfReportsRef, orderBy("timestamp", "desc"));

        const querySnapshot = await getDocs(sfQuery);
        const fetchedMessages = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.payerMessage) {
            fetchedMessages.push({
              message: data.payerMessage,
              timestamp: data.timestamp.toDate().toLocaleString(),
            });
          }
        });

        setMessages(fetchedMessages);
      } catch (error) {
        console.error("Error fetching SF reports:", error);
      }
    };

    fetchSFReports();
  }, [patientId, claimId, uid]);

  return (
    <div>
      <h4>Latest SF Report Message</h4>
      <button onClick={() => setModalIsOpen(true)}>See all Messages</button>
      <p>
        {messages.length > 0 ? messages[0].message : "No message available"}
      </p>

      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <button
          className="filesCloseButton"
          onClick={() => setModalIsOpen(false)}
        >
          X
        </button>
        <div styles={{ marginTop: "1rem" }}>
          "<h3>All SF Report Messages</h3>
        </div>

        {messages.length === 0 && <p>No messages found.</p>}
        {messages.map((msg, index) => (
          <div key={index}>
            <p>
              <strong>Timestamp:</strong> {msg.timestamp}
            </p>
            <p>{msg.message}</p>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default SFSDMessageViewer;
