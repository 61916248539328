import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import useUID from "../../../General/useUID";
import { db } from "../../../../firebase";
import HexagonSpinner from "../../../General/Animations/Hexspinner";

const functions = getFunctions();
const decryptFunction = httpsCallable(functions, "decrypt");

function IntakeView({ patientId }) {
  const [formData, setFormData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [uid, subUserUID, isLoading, error] = useUID();


  useEffect(() => {
    if (!uid || !patientId) return;

    const fetchIntakeForms = async () => {
      try {
        setIsLoadingData(true);
        // Step 1: Find the clinic document with matching ownerId
        const clinicsRef = collection(db, "clinics");
        const clinicsQuery = query(clinicsRef, where("ownerId", "==", uid));
        const clinicSnapshot = await getDocs(clinicsQuery);

        if (!clinicSnapshot.empty) {
          const clinicDoc = clinicSnapshot.docs[0]; // Assuming one clinic per ownerId
          const clinicId = clinicDoc.id;

          // Step 2: Query the intakeForms subcollection within the clinic document
          const intakeFormsRef = collection(db, "clinics", clinicId, "intakeForms");
          const intakeFormsQuery = query(intakeFormsRef, where("patientId", "==", patientId));

          const intakeFormsSnapshot = await getDocs(intakeFormsQuery);

          if (!intakeFormsSnapshot.empty) {
            const intakeFormsData = await Promise.all(
              intakeFormsSnapshot.docs.map(async (doc) => {
                const { ciphertext, iv, timestamp } = doc.data();
                const decryptPayload = { ciphertext, iv };
                const decryptedResponse = await decryptFunction(decryptPayload);
                const decryptedIntake = decryptedResponse.data.data;
                setIsLoadingData(false);
                return (decryptedIntake.empty) ? [] : decryptedIntake;
              })
            );

            setFormData(intakeFormsData);
          } else {
            setIsLoadingData(false);
          }
        } else {
          setIsLoadingData(false);
        }
      } catch (error) {
        setIsLoadingData(false);
        console.error("Error fetching intake forms:", error);
      }
    };

    fetchIntakeForms();
  }, [patientId, uid]);

  return (
    <div className="encountersContainer">
      {formData.length > 0 && (
        <>
          <h3>Intake Form Results</h3>
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
        </>
      )}
      {isLoadingData ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <HexagonSpinner />
        </div>
      ) : (
        formData.map((form, formIndex) => (
          <div key={formIndex}>
            {form.map((input) => (
              <div key={input.id}>
                {input.fields.map((item, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    {item.isVisible && (
                      <div>
                        {['text', 'phone', 'email', 'date', 'time', 'choice', 'textarea'].includes(item.type) && (
                          <p>
                            <span style={{ opacity: 0.75 }}>{item.label}: </span>
                            <span className="opacity-100">{item.value}</span>
                          </p>
                        )}
                        {item.type === 'signature' && (
                          <div>
                            <p>
                              <span style={{ opacity: 0.75 }}>{item.label}: </span>
                            </p>
                            <img src={item.value} alt="signature" />
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
}

export default IntakeView;