import React, { useState, useEffect } from 'react';
import '../Website.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import Footer from '../subcomponents/Footer';
import Header from '../subcomponents/Header';
import { FaVideo, FaPhone, FaClipboardCheck, FaFileMedical, FaUserCheck, FaLaptopMedical } from 'react-icons/fa';
import Confetti from '../../General/Animations/Confetti';

const DemoRequestForm = ({ onClose, onSubmitted }) => {
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [date, setDate] = useState(localStorage.getItem('date') || '');
  const [time, setTime] = useState(localStorage.getItem('time') || '');
  const [message, setMessage] = useState(localStorage.getItem('message') || '');
  const [preferredContact, setPreferredContact] = useState(localStorage.getItem('preferredContact') || '');
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token') || localStorage.getItem('token');

  useEffect(() => {
    localStorage.setItem('name', name);
  }, [name]);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem('date', date);
  }, [date]);

  useEffect(() => {
    localStorage.setItem('time', time);
  }, [time]);

  useEffect(() => {
    localStorage.setItem('message', message);
  }, [message]);

  useEffect(() => {
    localStorage.setItem('preferredContact', preferredContact);
  }, [preferredContact]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = 'Name is required';
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
    }
    if (!date) errors.date = 'Preferred demo date is required';
    if (!time) errors.time = 'Preferred demo time is required';
    if (!preferredContact) errors.preferredContact = 'Preferred contact method is required';
    return errors;
  };

  const isWeekday = (date) => {
    const day = new Date(date).getDay();
    return day !== 0 && day !== 6; // 0 = Sunday, 6 = Saturday
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (!isWeekday(date)) {
      setErrors({ date: 'Please choose a weekday for the demo.' });
      return;
    }

    setIsLoading(true);
    const functions = getFunctions();
    const sendDemoRequest = httpsCallable(functions, 'sendEmailDemo');

    try {
      await sendDemoRequest({ name, email, date, time, message, preferredContact });
      setIsSubmitted(true);
      if (onSubmitted) onSubmitted();
    } catch (error) {
      console.error('Error sending demo request: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="AI-page">
      <Helmet>
        <title>Demo | Popularis</title>
        <meta name="description" content="Request a demo for Popularis." />
        <meta name="keywords" content="demo, Popularis" />
        <meta property="og:title" content="Demo Request" />
        <meta property="og:description" content="Request a demo for Popularis." />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
      </Helmet>
      <Header />
      <div>
        <main>
          <section>
            <h2 style={{ color: "", textAlign: "center", fontWeight: "lighter", paddingTop: "3rem" }}>What to Expect:</h2>
            <div className="expect-items">
              <div className="expect-item">
                <FaClipboardCheck size={50} />
                <p>Go over requirements</p>
              </div>
              <div className="expect-item">
                <FaUserCheck size={50} />
                <p>Eligibility and claims overview</p>
              </div>
              <div className="expect-item">
                <FaFileMedical size={50} />
                <p>Patient files overview</p>
              </div>
              <div className="expect-item">
                <FaLaptopMedical size={50} />
                <p>Use cases for Popularis</p>
              </div>
            </div>
          </section>

          <section>
            <div className="demoForm">
              {!isSubmitted ? (
                <>
                  <h3 className='text-center'>Request a Demo</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="input-group-row">
                      <div className="input-field">
                        <label htmlFor="name">Your Name</label>
                        <input
                          id="name"
                          type="text"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value);
                            setErrors({ ...errors, name: '' });
                          }}
                          required
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                      </div>

                      <div className="input-field">
                        <label htmlFor="email">Email:</label>
                        <input
                          id="email"
                          type="email"
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                            setErrors({ ...errors, email: '' });
                          }}
                          required
                        />
                        {errors.email && <p className="error-message">{errors.email}</p>}
                      </div>
                    </div>

                    <div className="input-group-row">
                      <div className="input-field">
                        <label htmlFor="date">Preferred Demo Date:</label>
                        <input
                          id="date"
                          type="date"
                          value={date}
                          onChange={(event) => {
                            setDate(event.target.value);
                            setErrors({ ...errors, date: '' });
                          }}
                          required
                        />
                        {errors.date && <p className="error-message">{errors.date}</p>}
                      </div>

                      <div className="input-field">
                        <label htmlFor="time">Preferred Demo Time (9 AM - 5 PM EST):</label>
                        <input
                          id="time"
                          type="time"
                          min="09:00"
                          max="17:00"
                          value={time}
                          onChange={(event) => {
                            setTime(event.target.value);
                            setErrors({ ...errors, time: '' });
                          }}
                          required
                        />
                        {errors.time && <p className="error-message">{errors.time}</p>}
                      </div>
                    </div>

                    <div className="input-group-row">
                      <div className="input-field">
                        <label htmlFor="preferredContact">Preferred Contact Method:</label>
                        <div>
                          <input
                            type="radio"
                            id="googleMeets"
                            name="preferredContact"
                            value="Google Meets"
                            className='custom-checkbox'
                            checked={preferredContact === 'Google Meets'}
                            onChange={(event) => setPreferredContact(event.target.value)}
                            required
                          />
                          <label htmlFor="googleMeets"><FaVideo size={20} /> Google Meets</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id="phoneCall"
                            name="preferredContact"
                            value="Phone Call"
                            className='custom-checkbox'
                            checked={preferredContact === 'Phone Call'}
                            onChange={(event) => setPreferredContact(event.target.value)}
                            required
                          />
                          <label htmlFor="phoneCall"><FaPhone size={20} /> Phone Call</label>
                        </div>
                        {errors.preferredContact && <p className="error-message">{errors.preferredContact}</p>}
                      </div>
                    </div>

                    <div className="input-group-row">
                      <div className="input-field">
                        <label htmlFor="message">Additional Message:</label>
                        <textarea
                          id="message"
                          rows="4"
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <button type="submit" disabled={isLoading}>
                      {isLoading ? 'Submitting...' : 'Request Demo'}
                    </button>
                  </form>
                </>
              ) : (
                <div className="confetti-container">
                  <Confetti />
                  <h1>Thank you for your demo request!</h1>
                  <h3 className='text-center'>We will get back to you soon.</h3>
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default DemoRequestForm;
