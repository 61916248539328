import React from 'react';

const NoSpaceInput = ({ onChange, ...props }) => {
  const handleChange = (event) => {
    // Prevent spaces from being entered.
    const valueWithoutSpaces = event.target.value.replace(/\s+/g, '');
    onChange({ ...event, target: { ...event.target, value: valueWithoutSpaces } });
  };

  return <input onChange={handleChange} {...props} />;
};

export default NoSpaceInput;
