import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { httpsCallable, getFunctions } from "firebase/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import HexagonSpinner from '../General/Animations/Hexspinner';
import './AUTH.css';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import Smalllogo from './smalllogo';

const VerifyEmailPage = () => {
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const { token } = useParams();
  const functions = getFunctions();
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    if (token) {
      const verifyTokensCollection = collection(db, 'emailVerifyTokens');
      const tokenQuery = query(verifyTokensCollection, where('token', '==', token));

      getDocs(tokenQuery)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const tokenDoc = querySnapshot.docs[0];
            const { uid } = tokenDoc.data();

            const verifyEmail = httpsCallable(functions, 'verifyEmail');
            verifyEmail({ uid })
              .then((result) => {
                setVerificationStatus('Email verified successfully!');
                navigate('/signin');
              })
              .catch((error) => {
                console.error("Verification error:", error);
                setVerificationStatus('Failed to verify email. Please try again or contact support.');
              });
          } else {
            setVerificationStatus('Invalid verification link.');
          }
        })
        .catch((error) => {
          console.error("Error fetching token document:", error);
          setVerificationStatus('Failed to verify email. Please try again or contact support.');
        });
    } else {
      setVerificationStatus('Invalid verification link.');
    }
  }, [token, navigate, db]);

  return (
    <div className="verification-container">
      <div className="verification-message">
      <div>
            <Smalllogo />
          </div>
        <h2>Email Verification</h2>
        {/* Conditionally render the spinner or mail icon based on status */}
        {verificationStatus === 'Verifying...' ? (
          <HexagonSpinner />
        ) : (
          <FontAwesomeIcon icon={faEnvelope} style={{ height: "5rem" }} /> // Large mail icon
        )}
        <p>{verificationStatus}</p>
      </div>
    </div>
  );
};

export default VerifyEmailPage;