import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import useUID from "../../General/useUID";

const NewReport = ({
  patientId,
  claimId,
  onClose,
  isEditMode,
  existingReportData,
}) => {
  const [uid] = useUID();
  const [reportData, setReportData] = useState({
    claimStatusCode: "",
    totalActualProviderPaymentAmount: "",
    npi: "",
    controlNumber: "",
    patientControlNumber: "",
    serviceDate: "",
    adjudicatedProcedureCode: "",
    lineItemChargeAmount: "",
  });
  const [procedureCodes, setProcedureCodes] = useState([]);
  const [statusCodes, setStatusCodes] = useState([]);
  const [serviceLines, setServiceLines] = useState([]);

  useEffect(() => {
    const fetchClaimData = async () => {
      const claimRef = doc(
        db,
        `patients/${uid}/patientData/${patientId}/claims/${claimId}`
      );
      try {
        const claimSnapshot = await getDoc(claimRef);
        if (claimSnapshot.exists()) {
          const claimData = claimSnapshot.data();
          const serviceLines =
            claimData.requestData?.claimInformation?.serviceLines || [];
          const codes = serviceLines.map(
            (line) => line.professionalService.procedureCode
          );

          setReportData((prevState) => ({
            ...prevState,
            npi: claimData.requestData?.providers[0].npi,
            controlNumber: claimData.requestData?.controlNumber,
            patientControlNumber:
              claimData.requestData?.claimInformation?.patientControlNumber,
            serviceDate:
              claimData.requestData?.claimInformation?.serviceLines[0]
                .serviceDate,
          }));
          setProcedureCodes(codes); // Set the procedure codes
        } else {
        }
      } catch (error) {
        console.error("Error fetching claim data:", error);
      }
    };

    const fetchStatusCodes = async () => {
      const statusCodesRef = collection(db, "ClaimStatusCodes");
      try {
        const querySnapshot = await getDocs(statusCodesRef);

        const codes = querySnapshot.docs
          .map((doc) => ({
            number: doc.id, // Document ID as status code number
            description: doc.data().Description,
          }))
          .sort((a, b) => parseInt(a.number) - parseInt(b.number)); // Sort by number after converting to integer

        setStatusCodes(codes);
      } catch (error) {
        console.error("Error fetching status codes:", error);
      }
    };

    if (uid && patientId && claimId) {
      fetchClaimData();
      fetchStatusCodes(); // Fetch status codes as well
    }
    // Pre-populate form if in edit mode
    if (isEditMode && existingReportData) {
      setReportData(existingReportData);
    }
  }, [uid, patientId, claimId, isEditMode, existingReportData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReportData((prevState) => {
      // If the procedure code changes, find the corresponding service line and update charge amount
      if (name === "adjudicatedProcedureCode") {
        const selectedLine = serviceLines.find(
          (line) => line.professionalService.procedureCode === value
        );
        const chargeAmount = selectedLine
          ? selectedLine.professionalService.lineItemChargeAmount
          : "";

        return {
          ...prevState,
          [name]: value,
          lineItemChargeAmount: chargeAmount, // Update charge amount
        };
      } else {
        return { ...prevState, [name]: value };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const claimReportsRef = collection(
      doc(db, "patients", uid, "patientData", patientId),
      "reports"
    );
    try {
      if (isEditMode) {
        // Update existing report
        const reportRef = doc(claimReportsRef, existingReportData.id);
        await updateDoc(reportRef, reportData);
      } else {
        // Create new report
        await addDoc(claimReportsRef, reportData);
      }
      onClose(); // Close the modal/form after successful submission
    } catch (error) {
      console.error("Error submitting report:", error);
      // Handle the error appropriately
    }
  };

  return (
    <div className="new-report-form">
      <h2>Create New Report</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group-row">
          <div className="input-field">
            <label>Status Code:</label>
            <select
              name="claimStatusCode"
              value={reportData.claimStatusCode}
              onChange={handleChange}
              className="select-dropdown" // Add this class
            >
              <option value="">Select Status Code</option>
              {statusCodes.map((code) => (
                <option
                  key={code.number}
                  value={code.number}
                  title={code.description}
                >
                  {code.number} - {code.description}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label>Procedure Code:</label>
            <select
              name="adjudicatedProcedureCode"
              value={reportData.adjudicatedProcedureCode}
              onChange={handleChange}
            >
              <option value="">Select Procedure Code</option>
              {procedureCodes.map((code, index) => (
                <option key={index} value={code}>
                  {code}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label>Total Payment Amount:</label>
            <input
              type="number"
              name="totalActualProviderPaymentAmount"
              value={reportData.totalActualProviderPaymentAmount}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <button type="submit">Create Report</button>
          <button type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewReport;
