import React, { useState } from "react";
import styles from "./Claims.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faFileCircleCheck,
  faMagnifyingGlassDollar,
  faTable,
  faCalendar,
  faFilePdf,
  faFileInvoiceDollar,
  faStaffSnake,
  faFileInvoice,
  faFileCsv
} from "@fortawesome/free-solid-svg-icons";
import BatchClaims from "./Tools/BatchClaims";
import ClaimsTable from "./Tools/ClaimsTable";
import ClaimsCalendar from "./Tools/ClaimsCalendar";
import { useNavigate } from "react-router-dom";
import PayerMatchingModal from "./Configuration/PayerMatching";
import SingleClaim from "./Tools/SingleClaim";
import ProvidersMenu from "./Configuration/ProvidersMenu";
import CodesMenu from "./Configuration/CodesMenu";
import PaymentPosting from "./Tools/PaymentPosting";
import ReportsGenerator from "./Tools/ReportsGenerator";
import BillableEncounters from "./Tools/BillableEncounters";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import QuickSearch from "../Eligibility/Quicksearch";

function ToolsBar() {
  const [isBatchClaimsOpen, setIsBatchClaimsOpen] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isEncountersOpen, setIsEncountersOpen] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [isPayerMatchingOpen, setIsPayerMatchingOpen] = useState(false);
  const [showProvidersMenu, setShowProvidersMenu] = useState(false);
  const [showCodesMenu, setShowCodesMenu] = useState(false);
  const [isToolsDropdownOpen, setIsToolsDropdownOpen] = useState(false);
  const [isConfigDropdownOpen, setIsConfigDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [isSingleClaimOpen, setIsSingleClaimOpen] = useState(false);
  const [isSinleEligibilityOpen, setIsSingleEligibilityOpen] = useState(false);

  // Define the toggle functions
  const toggleToolsDropdown = () => {
    setIsToolsDropdownOpen(!isToolsDropdownOpen);
    // Close the config dropdown if tools dropdown is being opened
    if (!isToolsDropdownOpen) setIsConfigDropdownOpen(false);
  };

  const toggleConfigDropdown = () => {
    setIsConfigDropdownOpen(!isConfigDropdownOpen);
    // Close the tools dropdown if config dropdown is being opened
    if (!isConfigDropdownOpen) setIsToolsDropdownOpen(false);
  };

  // Adjust other toggle functions to close both dropdowns
  const toggleBatchClaimsPopup = () => {
    setIsBatchClaimsOpen(!isBatchClaimsOpen);
    // Close both dropdowns
    setIsToolsDropdownOpen(false);
    setIsConfigDropdownOpen(false);
  };

  const togglePayerMatching = () => {
    setIsPayerMatchingOpen(!isPayerMatchingOpen);
    // Close both dropdowns
    setIsToolsDropdownOpen(false);
    setIsConfigDropdownOpen(false);
  };

  const toggleTablePopup = () => {
    setIsTableOpen(!isTableOpen);
    // Close both dropdowns
    setIsToolsDropdownOpen(false);
    setIsConfigDropdownOpen(false);
  };

  const toggleCalendarPopup = () => {
    setIsCalendarOpen(!isCalendarOpen);
    // Close both dropdowns
    setIsToolsDropdownOpen(false);
    setIsConfigDropdownOpen(false);
  };

  const toggleSingleClaim = () => {
    setIsSingleClaimOpen(!isSingleClaimOpen);
    // Close both dropdowns
    setIsToolsDropdownOpen(false);
    setIsConfigDropdownOpen(false);
  };

  const toggleSingleEligibility = () => {
    setIsSingleEligibilityOpen(!isSinleEligibilityOpen);
    // Close both dropdowns
    setIsToolsDropdownOpen(false);
    setIsConfigDropdownOpen(false);
  };

  const toggleEncountersPopup = () => {
    setIsEncountersOpen(!isEncountersOpen);
    // Close both dropdowns
    setIsToolsDropdownOpen(false);
    setIsConfigDropdownOpen(false);
  };

  const toggleReportsPopup = () => {
    setIsReportsOpen(!isReportsOpen);
    // Close both dropdowns
    setIsToolsDropdownOpen(false);
    setIsConfigDropdownOpen(false);
  };

  const closePatientDataPopup = () => {
    setIsBatchClaimsOpen(false);
  };

  // Function to capture the screen and generate PDF
  const downloadPdf = () => {
    const input = document.getElementById("dashboardContent");

    if (!input) {
      console.error("Element to capture not found");
      return;
    }

    html2canvas(input, {
      scale: 2, // Increase scale for higher resolution
      useCORS: true, // If you have images that are cross-origin
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width, canvas.height],
        });
        pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

        // Get current date and format it
        const today = new Date();
        const formattedDate = today
          .toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
          .replace(/ /g, "/"); // Replace spaces with slashes

        // Save the PDF with the formatted date in the filename
        pdf.save(`claimsReport_${formattedDate}.pdf`);
      })
      .catch((err) => console.error("Error: ", err));
  };

  const buttonClass = `MainActionButton ${isBatchClaimsOpen ? "selected" : ""}`;
  const buttonClassTable = `MainActionButton ${isTableOpen ? "selected" : ""}`;
  const buttonClassCalendar = `MainActionButton ${
    isCalendarOpen ? "selected" : ""
  }`;
  const buttonClassMatching = `MainActionButton ${
    isPayerMatchingOpen ? "selected" : ""
  }`;

  const dropdownItemClass = "dropdown-item";

  return (
    <div className="toolsBar">
      {/* <SearchBarClaims /> */}

      <div className="dropdownContainer">
        <button className="dropbtn" onClick={toggleToolsDropdown}>
          Tools
        </button>
        {isToolsDropdownOpen && (
          <div className="dropdownClaims">
            <button
              onClick={toggleEncountersPopup}
              className={buttonClass}
            >
              <FontAwesomeIcon
                icon={faStaffSnake}
                style={{ marginRight: "10px" }}
              />
              Billable Encounters
            </button>
            <button onClick={toggleTablePopup} className={buttonClassTable}>
              <FontAwesomeIcon icon={faTable} style={{ marginRight: "10px" }} />
              Claims Table
            </button>
            <button
              onClick={toggleCalendarPopup}
              className={buttonClass}
            >
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ marginRight: "10px" }}
              />
              Claims Calendar
            </button>
            <button onClick={toggleBatchClaimsPopup} className={buttonClass}>
              <FontAwesomeIcon
                icon={faLayerGroup}
                style={{ marginRight: "10px" }}
              />
              Batch Claims
            </button>
            <button onClick={toggleSingleClaim} className={buttonClass}>
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                style={{ marginRight: "10px" }}
              />
              Single Claim
            </button>
            
            <PaymentPosting />
            <button
              onClick={toggleReportsPopup}
              className={buttonClass}
            >
              <FontAwesomeIcon
                icon={faFileCsv}
                style={{ marginRight: "10px" }}
              />
              Reports Generator
            </button>
            <button onClick={toggleSingleEligibility} className={buttonClass}>
              <FontAwesomeIcon
                icon={faMagnifyingGlassDollar}
                style={{ marginRight: "10px" }}
              />
              Single Eligibility
            </button>
          </div>
        )}
      </div>

      <div className="dropdownContainer">
        <button className="dropbtn" onClick={toggleConfigDropdown}>
          Configuration
        </button>
        {isConfigDropdownOpen && (
          <div className="dropdownClaims">
            <button
              onClick={togglePayerMatching}
              className={buttonClassMatching}
            >
              <FontAwesomeIcon
                icon={faLayerGroup}
                style={{ marginRight: "10px" }}
              />
              Payer Matching
            </button>
            <ProvidersMenu
              showProviderSelection={showProvidersMenu}
              onClose={() => setShowProvidersMenu(false)}
            />

            <CodesMenu
              showBillingCodes={showCodesMenu}
              onClose={() => setShowCodesMenu(false)}
            />
          </div>
        )}
      </div>

      {isTableOpen && (
        <div className="claims-inner-container">
          <ClaimsTable onClose={() => setIsTableOpen(false)} />
        </div>
      )}
      {isCalendarOpen && (
        <div className="Claims-inner-container">
          <ClaimsCalendar onClose={() => setIsCalendarOpen(false)} />
        </div>
      )}
      {isPayerMatchingOpen && (
        <PayerMatchingModal onClose={() => setIsPayerMatchingOpen(false)} />
      )}
      {isBatchClaimsOpen && (
        <BatchClaims
          selectedPatientsData={patientData}
          onClose={closePatientDataPopup}
        />
      )}
      {isSingleClaimOpen && (
        <>
          <button onClick={toggleSingleClaim} className="filesCloseButton">
            X
          </button>
          <div className="Claims-inner-container">
          <button onClick={toggleSingleClaim} className="filesCloseButton">X</button>
          <SingleClaim onClose={toggleSingleClaim} />
        </div>
        </>
      )}
      {isSinleEligibilityOpen && (
        <div className="Claims-inner-container">
          <button onClick={toggleSingleEligibility} className="filesCloseButton">
            X
          </button>
          <QuickSearch onClose={toggleSingleEligibility} />
        </div>
      )}
       {isEncountersOpen && (
        <div className="Claims-inner-container">
          <button onClick={toggleEncountersPopup} className="filesCloseButton">
            X
          </button>
          <BillableEncounters onClose={toggleEncountersPopup} />
        </div>
      )}
       {isReportsOpen && (
        <div className="Claims-inner-container">
          <button onClick={toggleReportsPopup} className="filesCloseButton">
            X
          </button>
          <ReportsGenerator onClose={toggleReportsPopup} />
        </div>
      )}
   
      
      <button onClick={downloadPdf} className="pdf-download-button">
        <FontAwesomeIcon icon={faFilePdf} />
      </button>
    </div>
  );
}

export default ToolsBar;
