import React, { useState, useEffect } from 'react';
import { auth, db } from "../../../firebase";
import { collection, onSnapshot, doc, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { faTrash } from '@fortawesome/free-solid-svg-icons'; // Import any necessary FontAwesome icons
import useUID from '../../General/useUID';
import ClaimsViewEligibility from './ClaimsViewEligibility';
import ClaimsViewClaimStatus from '../../Claims/Subcomponents/ClaimsViewClaimStatus';
import styles from "../PatientView.module.css";
import ClaimStatusCategoryFlag from '../../ClaimStatus/ClaimStatusCategoryFlag'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

function ClaimsView({ patientId }) {
    const navigate = useNavigate();
    const [claims, setClaims] = useState([]);
    const [claimStatuses, setClaimStatuses] = useState({});
    const [openClaimId, setOpenClaimId] = useState(null);
    const [uid, subUserUID, isLoading, error] = useUID();
    const [latestReports, setLatestReports] = useState({});

    useEffect(() => {
        if (!uid) return;
        if (!patientId) {
            return;
        }
    
        const decryptFunction = httpsCallable(functions, "decrypt");
    
        const claimsRef = collection(
            doc(
                db, 
                'patients', uid, 
                'patientData', patientId
            ), 
            'claims'
        );
    
        // Create a query for the claims
        const claimsQuery = query(claimsRef);
    
        // Set up the real-time listener for claims
        const unsubscribeClaims = onSnapshot(claimsQuery, async (snapshot) => {
            try {
                const decryptedClaims = await Promise.all(snapshot.docs.map(async doc => {
                    const encryptedData = doc.data();
                    try {
                        const decryptedResponse = await decryptFunction(encryptedData);
                        const decryptedData = decryptedResponse.data;
                        return {
                            id: doc.id,
                            ...decryptedData,
                            timestamp: doc.data().timestamp
                        };
                    } catch (error) {
                        console.error('Error decrypting claim data:', error);
                        return null; // Return null if decryption fails
                    }
                }));
    
                // Filter out any null entries (failed decryptions)
                const validClaims = decryptedClaims.filter(claim => claim !== null);
                setClaims(validClaims);
            } catch (error) {
                console.error('Error processing claims:', error);
            }
        }, (error) => {
            console.error('Error fetching claims:', error);
        });
    
        // This return is for cleaning up the claims listener
        return () => {
            unsubscribeClaims();
        };
    }, [uid, patientId, db]);  // Include functions in dependencies if necessary
    
    
    useEffect(() => {
        if (!uid || !patientId) return;    
        const claimStatusRef = collection(
            doc(db, 'patients', uid, 'patientData', patientId),
            'claimStatus'
        );
    
        const unsubscribeStatus = onSnapshot(claimStatusRef, (statusSnapshot) => {
            if (!statusSnapshot.empty) {
                const newClaimStatuses = {};
                statusSnapshot.docs.forEach((statusDoc) => {
                    const statusData = statusDoc.data();
                    const associatedClaimID = statusData.associatedClaimID;
    
                    // Check if updatedAt exists and is a Timestamp
                    const updatedAt = statusData.mainStatus.updatedAt;
                    if (updatedAt && updatedAt.seconds) {
                        const updatedAtDate = new Date(updatedAt.seconds * 1000);
                        // Check if this status is newer than the one already stored
                        if (!newClaimStatuses[associatedClaimID] || 
                            new Date(newClaimStatuses[associatedClaimID].updatedAt.seconds * 1000) < updatedAtDate) {
                            newClaimStatuses[associatedClaimID] = {
                                ...statusData.mainStatus,
                                updatedAt: updatedAtDate // Store as a JavaScript Date object
                            };
                        }
                    }
                });
                setClaimStatuses(newClaimStatuses);
            } else {
            }
        }, (error) => {
            console.error('Error fetching claimStatuses:', error);
        });
    
        return () => {
            unsubscribeStatus();
        };
    }, [db, patientId, uid]);
    
    
    useEffect(() => {
        if (!uid || !patientId) return;
        claims.forEach(async (claim) => {
            const reportsRef = collection(db, 'patients', uid, 'patientData', patientId, 'claims', claim.id, 'reports');
            const q = query(reportsRef, orderBy('timestamp', 'desc'), limit(1)); // Query for the latest report

            const reportSnapshot = await getDocs(q);
            if (!reportSnapshot.empty) {
                const latestReportData = reportSnapshot.docs[0].data();
                setLatestReports(prevReports => ({
                    ...prevReports,
                    [claim.id]: latestReportData
                }));
            }
        });
    }, [uid, patientId, claims, db]);
    
    
    const handleToggle = (id) => { 
        if (openClaimId === id) {
            setOpenClaimId(null); 
        } else {
            setOpenClaimId(id); 
        }
    }

    const handleRunEligibility = (event, claimId) => {
        event.stopPropagation(); // Prevent click event from bubbling up to parent
    };
    
    const handleRunClaimStatus = (event, claimId) => {
        event.stopPropagation(); // Prevent click event from bubbling up to parent
    };

    
// Helper function to interpret claim status with expanded code coverage
const interpretClaimStatus = (report) => {
    if (!report) return ''; // Return empty string if no report is available
  
    const adjustmentCodes = report.adjustments?.map(adj => adj.adjustmentReasonCode) || [];
  
    // Define sets of codes for each category
    const deniedCodes = ['2', '3', '5']; // Example denied status codes
    const rejectedCodes = ['6', '7', '8']; // Example rejected status codes
    const paidCodes = ['1']; // Example paid status codes
    const partialPaymentCodes = ['9', '10']; // Example partial payment codes
    const acknowledgedCodes = ['11', '12', '13']; // Example acknowledged status codes
    const informationalCodes = ['14', '15', '16']; // Long, descriptive reason codes
  
    // Check for specific codes in adjustments and claim status
    if (deniedCodes.includes(report.claimStatusCode) || adjustmentCodes.some(code => deniedCodes.includes(code))) {
      return 'Denied';
    }
    if (rejectedCodes.includes(report.claimStatusCode) || adjustmentCodes.some(code => rejectedCodes.includes(code))) {
      return 'Rejected';
    }
    if (paidCodes.includes(report.claimStatusCode)) {
      return adjustmentCodes.length === 0 ? 'Paid' : 'Partial Payment';
    }
    if (partialPaymentCodes.includes(report.claimStatusCode) || adjustmentCodes.some(code => partialPaymentCodes.includes(code))) {
      return 'Partial Payment';
    }
    if (informationalCodes.includes(report.claimStatusCode)) {
      return 'Informational';
    }
    if (acknowledgedCodes.includes(report.claimStatusCode)) {
      return 'Acknowledged';
    }
  
    // Default to 'Unknown' for any codes not covered
    return 'Unknown';
  };

  // Utility function to sort claim statuses by updatedAt in descending order
    const sortStatusesByUpdatedAt = (statuses) => {
        return statuses.sort((a, b) => b.mainStatus.updatedAt.seconds - a.mainStatus.updatedAt.seconds);
    };

    const navigateToClaimDetails = (claimId) => {
        navigate(`/claims/${claimId}`);
    };

    // Utility function to convert YYYYMMDD to MM/DD/YYYY
    const formatDate = (dateString) => {
        if (!dateString || dateString.length !== 8) return dateString;
        return `${dateString.substring(4,6)}/${dateString.substring(6,8)}/${dateString.substring(0,4)}`;
    };

    
  return (
    <div className='claimsContainer'>
         {claims.length > 0 && (
                <>
                    <h3>Claims</h3>
                    <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
                </>
            )}
        {claims.map(claim => {
            const latestReport = latestReports[claim.id];
            const claimStatus = interpretClaimStatus(latestReport);
            const statusCategoryCode = claimStatuses[claim.id]?.statusCategoryCode || 'No status available.';

            return (
                <div 
                    key={claim.id} 
                    className='Card'
                    onClick={() => handleToggle(claim.id)}
                >

                <div className={styles.claimsTopView}>

                    {/* Text Container on the left side of the Card */}
                    <div className="claimsButtonsleft">
                        <h4>{claim.requestData.tradingPartnerName} {' | $'}{claim.requestData.claimInformation.claimChargeAmount}</h4>
                        <h4>
                            {claim.requestData.claimInformation.serviceLines.length === 1 ? 'Billing Code: ' : 'Billing Codes: '}
                            {claim.requestData.claimInformation.serviceLines.map(line => line.professionalService.procedureCode).join(', ')}
                        </h4>
                        
                        <p>{claimStatus || ''}</p>

                        <p><ClaimStatusCategoryFlag statusCategoryCode={statusCategoryCode} /></p>
                        <p>{'Sent: '}{claim.timestamp ? claim.timestamp.toDate().toLocaleString() : ''}</p>
                    </div>

                   {/* Button Container on the right side of the Card */}
                        <div className={styles.claimsButtonsRight}>
                        <ClaimsViewEligibility 
                            claimId={claim.id} 
                            patientId={patientId}

                            handleRunEligibility={handleRunEligibility}
                        />
                        <ClaimsViewClaimStatus 
                            claimId={claim.id} 
                            patientId={patientId}
                            
                            handleRunClaimStatus={handleRunClaimStatus}
                        />
                        <>
                        <button 
                            className="btn btn-outline-secondary"
                            onClick={() => navigateToClaimDetails(claim.id)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle } style={{ marginRight: '5px' }}/>
                            More Information
                        </button>

                        </>                        
                        </div>
                        
                </div>



                    {openClaimId === claim.id && (
                        <div className="additionalClaimDetails">
                            <p><strong>Provider:</strong> {claim.requestData.providers[0].organizationName}</p>
                            <p><strong>Service Provider:</strong> {claim.requestData.claimInformation.serviceFacilityLocation.organizationName}</p>

                            {/* Health Care Code Information */}

                             {/* Service Lines Table */}
                             <h4>Service Lines:</h4>
                             <table>
                                <thead className={`${styles.serviceLinesTable} ${styles.transparentTable}`}>
                                    <tr>
                                        <th>Date of Service</th>
                                        <th>Charge Amount</th>
                                        <th>Procedure Code</th>
                                        <th>Diagnosis Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {claim.requestData.claimInformation.serviceLines.map((line, index) => {
                                        // Assuming diagnosis codes are ordered the same way as service lines
                                        const diagnosisCode = claim.requestData.claimInformation.healthCareCodeInformation[index]?.diagnosisCode || 'N/A';

                                        return (
                                            <tr key={index}>
                                                <td>{formatDate(line.serviceDate)}</td>
                                                <td>{'$' + line.professionalService.lineItemChargeAmount}</td>
                                                <td>{line.professionalService.procedureCode}</td>
                                                <td>{diagnosisCode}</td> {/* Render the corresponding diagnosis code */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
export default ClaimsView;
