function ValidateSubform(formData, formInputValues) {
  for (let input of formData) {
    for (let field of input.fields) {
      if (field.required && formInputValues.length == 0) {
        return {
          isValid: false,
          message: `Please fill in the required field: ${field.label}`,
        };
      }
    }
  }

  for (let formInput of formInputValues) {
    for (let field of formInput.fields) {
      if (field.required && (!field.value || field.value === "")) {
        return {
          isValid: false,
          message: `Please fill in the required field: ${field.label}`,
        };
      }

      if (field.type === "phone" && field.value && field.value !== "") {
        const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$|^([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})([0-9]{4})$/;
      
        if (!phoneRegex.test(field.value)) {
          return {
            isValid: false,
            message: `Invalid phone number for ${field.label}. Please enter a valid North American phone number.`,
          };
        }
      }  
        
      if (field.type === "zip" && field.value && field.value !== "") {
        const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
      
        if (!zipRegex.test(field.value)) {
          return {
            isValid: false,
            message: `Invalid zip code for ${field.label}. Please enter a valid zip code between 5 and 9 characters in length.`,
          };
        }
      }

      if (field.type === "email" && field.value && field.value !== "") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
        if (!emailRegex.test(field.value)) {
          return {
            isValid: false,
            message: `Invalid email address for ${field.label}. Please enter a valid email address.`,
          };
        }
      }       
    

      if (field.validation && field.validation !== "None") {
        const validationType = field.validation;
        const valueToValidate = formInput.fields || "";

        // If value is null or undefined, treat it as an empty string
        switch (validationType) {
          case "Alphabetic":
            if (!/^[a-zA-Z]+$/.test(valueToValidate)) {
              return {
                isValid: false,
                message: `Invalid input for ${field.label}. Only alphabetic characters are allowed.`,
              };
            }
            break;
          case "AlphaNumeric":
            if (!/^[a-zA-Z0-9]+$/.test(valueToValidate)) {
              return {
                isValid: false,
                message: `Invalid input for ${field.label}. Only alphanumeric characters are allowed.`,
              };
            }
            break;
          case "Numeric":
            if (!/^[0-9]+$/.test(valueToValidate)) {
              return {
                isValid: false,
                message: `Invalid input for ${field.label}. Only numeric characters are allowed.`,
              };
            }
            break;
          case "Email":
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valueToValidate)) {
              return {
                isValid: false,
                message: `Invalid email address for ${field.label}. Please enter a valid email address.`,
              };
            }
            break;
          default:
            break;
        }
      } else if (field.type === "phone") {
        const valueToValidate = formInput.fields || "";
        // If the field is required and the input value is not empty, validate the phone number format
        if (field.required && valueToValidate.trim() !== "") {
          if (
            !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
              valueToValidate
            )
          ) {
            return {
              isValid: false,
              message: `Invalid phone number for ${field.label}. Please enter a valid phone number.`,
            };
          }
        }
      } else if (field.type === "email") {
        const valueToValidate = formInput.fields || "";
        // If the field is required and the input value is not empty, validate the phone number format
        if (field.required && valueToValidate.trim() !== "") {
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valueToValidate)) {
            return {
              isValid: false,
              message: `Invalid email address for ${field.label}. Please enter a valid email address.`,
            };
          }
        }
      }
    }
  }

  return { isValid: true };
}

export default ValidateSubform;
