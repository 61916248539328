import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import Header from '../subcomponents/Header';
import Footer from '../subcomponents/Footer';
import Hero from '../assets/finalHero2.mp4';
import { faHeart, faFileInvoiceDollar, faLock, faHeadset, faDesktop, faClock, faCheckCircle, faPlug, faClipboardList } from '@fortawesome/free-solid-svg-icons'; // Importing necessary icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import doc1 from '../assets/doc1.png';
import doc2 from '../assets/doc2.png';
import doc3 from '../assets/doc3.png';
import Static from '../assets/heroStatic.png';

const testimonials = [
  {
    image: doc1, // Use doc1 image here
    quote: "Popularis has streamlined our practice. It's a must-have for any provider.",
    name: "Dr. James Mandelbrot"
  },
  {
    image: doc2, // Use doc2 image here
    quote: "The seamless claims process and real-time eligibility checks have saved us countless hours.",
    name: "Dr. Alina Boran"
  },
  {
    image: doc3, // Use doc3 image here
    quote: "We have never had batch billing before, it is a game-changer.",
    name: "Nurse Practitioner Alexis Crews"
  },
];


const LandingPage = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState({});
  const sectionRefs = useRef({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem('email') ||
    new URLSearchParams(window.location.search).get('email') ||
    ''
  );
  const [stats, setStats] = useState([
    { value: 0, target: 99, label: 'Claims Acceptance Rate', suffix: '%' },
    { value: 60, target: 104, label: 'Claims Sent', prefix: '', suffix: 'k+' },
    { value: 30, target: 50, label: 'Eligibility Requests Made', suffix: 'k+' },
    { value: 0, target: 25, label: 'Patients Warehoused', prefix: '', suffix: 'k+' },
    { value: 0, target: 10, label: 'Charges Sent', prefix: '$', suffix: 'M+' },
    { value: 0, target: 100, label: 'Clinics Serviced', prefix: '', suffix: '+' },
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible((prev) => ({ ...prev, [entry.target.id]: entry.isIntersecting }));
        });
      },
      { threshold: 0.1 }
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    if (isVisible['stats']) {
      const interval = setInterval(() => {
        setStats(prevStats =>
          prevStats.map(stat => ({
            ...stat,
            value: stat.value < stat.target ? stat.value + 1 : stat.target
          }))
        );
      }, 30);

      return () => clearInterval(interval);
    }
  }, [isVisible['stats']]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleNavigation = (page) => {
    if (email) {
      if (!validateEmail(email)) {
        alert('Please enter a valid email address.');
      } else {
        navigate(`/${page}?email=${encodeURIComponent(email)}`);
      }
    } else {
      navigate(`/${page}`);
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="landing-page">
      <Helmet>
        <title>Popularis Health</title>
        <meta name="description" content="The Full Suite of Clinical and Administrative Tools for Healthcare." />
        <meta
          name="keywords"
          content="EMR, Medical Claims, Eligibility, patient eligibility, encrypted medical records, encrypted EMR, claim status, Medical billing, Patient management software, Cloud-based EMR solutions, Medical coding, Claims, Patient records, PHI, Popularis, Popularis Health, Healthcare, Intake forms, Patient eligibility, Insurance eligibility"
        />
        <meta property="og:title" content="Popularis Health - Modern Practice Management Software" />
        <meta
          property="og:description"
          content="Patients and Billing Simplified with Popularis Health. Start sending claims in 5 minutes with our full suite of clinical and administrative tools."
        />
        <meta property="og:url" content="https://www.popularishealth.com" />
      </Helmet>

      <Header />
      <main className="landing-content">
        {/* Hero Section */}
        <section className="hero-section" ref={(el) => (sectionRefs.current['hero'] = el)} id="hero">
          <div className="hero-wrapper">
            <div className='animated-hero'>
              <video
                src={Hero}
                autoPlay
                playsInline
                muted
                loop
                x-webkit-airplay="deny"
                title="Animation showing different user types such as doctor, nurse, tech, or lab worker surrounded by the icons of the apps the user uses most."
                width="388"
                height="388"
              ></video>
            </div>
            <div className='image-hero'>
              <image src={Static} alt="static" />
            </div>
            <div className='hero-text'>
              <h1>Simple and Modern Practice Management Software</h1>
            </div>
          </div>
          <div className='hero-signin'>
            <Link to="/signin" className="login-btn-hero">
              <>
                Sign In
              </>
            </Link>
          </div>
          <div className="hero-text">
            <p className={`hero-description ${isVisible['hero'] ? 'animate' : ''}`}>
              The one-stop-shop for patients & billing, with
              eligibility, custom forms, appointments, and more.
            </p>
          </div>
        </section>

        {/* Process Section */}
        <section className="process-section bg-white" ref={(el) => (sectionRefs.current['process'] = el)} id="process">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['process'] ? 'animate' : ''}`}>
              Simply, we do three things...
            </h2>
            <div className="process-steps">
              {[
                {
                  title: 'Manage Patients',
                  description: "User-friendly and clinically approved patient app lets providers focus on patient care.",
                  icon: faHeart, // Heart icon for patients
                },
                {
                  title: 'Get You Paid',
                  description: "Send batches of claims and ensure you're going to get paid with realtime eligibility with over 3,500 payers.",
                  icon: faFileInvoiceDollar, // Invoice icon for payments
                },
                {
                  title: 'Keep your data safe',
                  description: 'We are the first EMR to offer encryption for all data, ensuring top security and confidentiality.',
                  icon: faLock, // Lock icon for data safety
                },
              ].map((step, index) => (
                <div
                  className={`step-3 ${isVisible['process'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={step.icon} className="step-icon" /> {/* Use the updated icons */}
                  <h3 className="step-title">{step.title}</h3>
                  <p className="step-description">{step.description}</p>
                </div>
              ))}
            </div>
            <div className='cta-container-center'>
              <div className={`cta-buttons px-0 mt-4 ${isVisible['hero'] ? 'animate' : ''} ${isButtonVisible ? 'visible' : 'hidden'}`}>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  className="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="cta-button" onClick={() => handleNavigation('signup')}>
                  Try for Free
                </button>
                <button className="cta-button" onClick={() => handleNavigation('demo')}>
                  Book a Demo
                </button>
              </div>
              <div className="hero-disclaimer-container">
                <p className={`hero-disclaimer ${isVisible['hero'] ? 'animate' : ''}`}>
                  Immediate access - No credit card required
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="parallax-section bg-light"
          ref={(el) => (sectionRefs.current['parallax'] = el)}
          id="all-in-one"
        >
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['parallax'] ? 'animate' : ''}`}>
              All-in-One Patient Management
            </h2>
            <div className="all-in-one-content">
              {[
                  {
                    "number": "01",
                    "capsule": "PREVISIT",
                    "title": "Intake with Appointments",
                    "description": "Our custom intake forms integrate with appointments to streamline patient onboarding.",
                    "featureList": [
                      "Appointment and scheduling system",
                      "Templates customizable for any specialty",
                      "Mobile-friendly design for easy access",
                      "Branding with your company’s logo"
                    ]
                  },
                  {
                    "number": "02",
                    "capsule": "PREVISIT",
                    "title": "Eligibility",
                    "description": "Instant eligibility verification ensures that patients meet criteria before their visit.",
                    "featureList": [
                      "Run 1,500 patients at a time through batch eligibility",
                      "One-click eligibility verification from the patient's file",
                      "Check multiple insurance companies simultaneously for discovery"
                    ]
                  },
                  {
                    "number": "03",
                    "capsule": "DURING VISIT",
                    "title": "Encounters System",
                    "description": "The main method for clinical staff to document their interactions with patients.",
                    "featureList": [
                      "Custom forms allow a diverse set of encounters",
                      "Integration with billing codes for easy follow-up billing",
                      "API callbacks allow encounters to be sent to labs, reporting systems, or other third parties"
                    ]
                  },
                  {
                    "number": "04",
                    "capsule": "DURING VISIT",
                    "title": "Potential Diagnosis",
                    "description": "Our AI chatbot supports providers by assisting with the potential diagnosis of symptoms, reflecting industry consensus.",
                    "featureList": [
                      "Trained on a vast array of medical textbooks",
                      "Trained on industry-specific information such as current medical codes",
                      "Trained on legal and regulatory documentation in healthcare"
                    ]
                  },
                  {
                    "number": "05",
                    "capsule": "DURING VISIT",
                    "title": "Notes and Medical History",
                    "description": "Our AI doctor’s note generator and intuitive medical history entry streamline documentation.",
                    "featureList": [
                      "Generate traditional or AI-generated notes with simple prompting",
                      "Securely store a wide range of medical file types",
                      "Medical history, family history, immunizations and allergies, lab and test results, medications, vitals"
                    ]
                  },
                  {
                    "number": "06",
                    "capsule": "POST VISIT",
                    "title": "Generate Reports",
                    "description": "Easily generate reports in JSON, XLSX, or PDF format.",
                    "featureList": [
                      "Individual patient reports",
                      "Batch patient reports",
                      "Encounters reports",
                      "Billing reports"
                    ]
                  },
                  {
                    "number": "07",
                    "capsule": "POST VISIT",
                    "title": "Send Claims",
                    "description": "Simplify the claims process with automated submission and tracking.",
                    "featureList": [
                      "Batch similar claims with ease",
                      "Real-time claim status updates",
                      "Real-time billing dashboard"
                    ]
                  },
                  {
                    "number": "08",
                    "capsule": "POST VISIT",
                    "title": "Schedule Follow-ups",
                    "description": "Easily schedule follow-ups or initiate HIPAA-compliant Zoom calls.",
                    "featureList": [
                      "Send Zoom invitations directly from Popularis",
                      "HIPAA-compliant Zoom server for secure and private meetings",
                      "Patients can use our appointment system to easily schedule follow-ups"
                    ]
                  }
                ].map((point, index) => (
                <div
                  key={index}
                  className={`all-in-one-item ${isVisible['parallax'] ? 'animate' : ''}`}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <div className="number-container">
                    <span className="large-number">{point.number}</span>
                  </div>
                  <div className="capsule-container">
                    <div className="capsule-label">{point.capsule}</div>
                    <div className="section-content">
                      <h3 className="AIO-section-title">{point.title}</h3>
                      <p className="section-description">{point.description}</p>
                      {point.featureList && (
                        <ul className="feature-list">
                          {point.featureList.map((feature, idx) => (
                            <li key={idx}>{feature}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="stats-section bg-white" ref={(el) => (sectionRefs.current['stats'] = el)} id="stats">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['stats'] ? 'animate' : ''}`}>
              Our Impact in Numbers
            </h2>
            <div className="stats-grid">
              {stats.map((stat, index) => (
                <div
                  className={`stat-item ${isVisible['stats'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <h3 className="stat-value">
                    {stat.prefix}{stat.value}{stat.suffix}
                  </h3>
                  <p className="stat-label">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Why Popularis Section */}
        <section className="why-popularis-section bg-grey" ref={(el) => (sectionRefs.current['whyPopularis'] = el)} id="why-popularis">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['whyPopularis'] ? 'animate' : ''}`}>
              Why Popularis?
            </h2>
            <div className="popularis-features-grid">
              {[
                {
                  title: 'Intuitive Design',
                  description: 'Our interface is simple and easy to use, making your workflow seamless.',
                  icon: faDesktop, // Example icon for intuitive design
                },
                {
                  title: 'Saves Two Hours Daily',
                  description: 'Automated workflows save you up to two hours daily on administrative tasks.',
                  icon: faClock, // Example icon for time-saving
                },
                {
                  title: 'Instant Eligibility',
                  description: 'Check patient eligibility in real-time, reducing delays and improving efficiency.',
                  icon: faCheckCircle, // Example icon for instant eligibility
                },
                {
                  title: 'Batch Claims',
                  description: 'Send claims in bulk with ease, reducing the manual workload for billing staff.',
                  icon: faFileInvoiceDollar, // Example icon for batch claims
                },
                {
                  title: 'Easy API Integrations',
                  description: 'Integrate with external systems effortlessly using our powerful API.',
                  icon: faPlug, // Example icon for API integrations
                },
                {
                  title: 'Custom Intake Forms',
                  description: 'Create and customize patient intake forms tailored to your clinic’s needs.',
                  icon: faClipboardList, // Example icon for custom forms
                },
              ].map((feature, index) => (
                <div
                  className={`feature-card ${isVisible['whyPopularis'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
                  <h3 className="feature-title">{feature.title}</h3>
                  <p className="feature-description">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonial Section */}
        <section className="testimonial-section bg-white" ref={(el) => (sectionRefs.current['testimonials'] = el)} id="testimonials">
          <div className="testimonial-container">
            <h2 className="section-title px-5vw">What Our Clients Say</h2>
            <div className="carousel">
              {testimonials.map((testimonial, index) => (
                <div
                  className={`testimonial-card ${index === currentIndex ? 'active' : ''}`}
                  key={index}
                >
                  <div className="testimonial-image-container">
                    <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
                  </div>
                  <p className="testimonial-quote">"{testimonial.quote}"</p>
                  <p className="testimonial-name">- {testimonial.name}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <div
          className={`tile-support tile pt-4 px-4  bg-grey`}
          data-id="support"
        >
          <a href='/support'>
            <div className="tile-content tile-support">
              <div className="support-wrapper">
                <div className="support-icon-features">
                  <FontAwesomeIcon style={{ height: "100px" }} icon={faHeadset} />
                </div>
                <div>
                  <h1 className="typography-headline support-text">The Popularis Team is always by your side.</h1>
                  <p className="typography-label-alt-dark support-text">US-based support is always just a call, email, or text away.</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </main>
      <Footer />

      <style jsx>{`
        body {
          overflow-x: hidden;
          max-width: 100%;
          margin: 0px;
        }

        .landing-page {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
            sans-serif;
          color: #1d1d1f;
          background-color: #f1f1f1;
          -webkit-font-smoothing: antialiased;
        }

        .hero-section {
          background-color: #f1f1f1;
          color: #00316f;
          height: auto;
          position: relative;
          overflow: hidden;
          text-align: center;
        }

        .hero-container {
          max-width: 980px;
          margin: 0 auto;
          padding: 0 22px;
          position: relative;
          z-index: 1;
        }

        .hero-text {
          z-index: 1;
        }

        .hero-title {
          font-size: 72px;
          text-align: right;
          line-height: 1.05;
          font-weight: 500;
          letter-spacing: -0.015em;
          margin-bottom: 10px;
          margin-top: 10px;
        }

        .section-title {
          font-size: 48px;
          line-height: 1.08349;
          font-weight: 500;
          letter-spacing: -0.003em;
          text-align: left;
          margin-bottom: 50px;
          padding-bottom: 24px;
          border-bottom: 2px solid #333;
          color: #333;
        }
        .cta-buttons.hidden {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease, visibility 0.5s ease;
        }

        .cta-buttons.visible {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.5s ease, visibility 0.5s ease;
          animation: fadeIn 3.5s ease;
        }

        .cta-button {
          font-size: 18px;
          padding: 12px 30px;
          margin: 0 10px;
          background-color: #00316f;
          color: #fefefe;
          border: none;
          border-radius: 60px;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;
        }

        .cta-button:hover {
          background-color: #fefefe;
          color: #00316f;
        }

        .hero-disclaimer {
          font-size: 14px;
          color: inherit;
        }

        .animate {
          animation: fadeInUp 0.6s ease-out forwards;
        }

        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .stats-section {
          background-color: #f1f1f1;
          color: #00316f;
          text-align: center;
        }

        .stats-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Allows 1, 2, or 3 columns */
          gap: 40px;
          max-width: 1200px;
          margin: 0 auto;
        }        

        .stat-item {
          opacity: 0;
          animation: fadeInUp 0.6s ease-out forwards;
        }

        .stat-value {
          font-size: 48px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #00316f;
        }

        .stat-label {
          font-size: 24px;
          color: #1d1d1f;
        }


        .px-5vw {
            margin-left: 5vw; 
            margin-right: 5vw; 
        }
        .stat-value {
          font-size: 48px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #00316f;
        }

        .stat-label {
          font-size: 18px;
          color: #1d1d1f;
        }

        /* Updated Parallax Section Styles */
        .parallax-section {
          background-color: #f1f1f1;
          color: #00316f;
          overflow: hidden;
        }

        .parallax-content {
          display: flex;
          align-items: center;
          gap: 40px;
          flex-direction: row-reverse; /* Reverses the order of flex items */
        }

        .parallax-image-container {
          flex: 1;
          height: 400px;
          overflow: hidden;
          border-radius: 8px;
        }

        .parallax-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
        }

        .parallax-image-container:hover .parallax-image {
          transform: scale(1.05);
        }

        .data-points {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 30px;
        }

        .data-point {
          opacity: 0;
          animation: fadeInUp 0.6s ease-out forwards;
        }

        @media only screen and (max-width: 734px) {
          .hero-title {
            font-size: 56px;
          }

          .heroImageContainer {
            display: none;
          }

          .hero-description {
            font-size: 24px;
            line-height: 36px;
            margin-left: 5%;
            margin-right: 5%;
          }

          .support-text {
          text-align: center;
          }

          .tile-content {
            padding: 0px;
          }

          .support-icon-features {
            margin-right: 0rem;
          }

          .support-wrapper {
            flex-direction: column;
            text-align: center;
          }

          .cta-button {
            font-size: 16px;
            padding: 10px 20px;
          }

          .process-steps {
            flex-direction: column;
            align-items: center;
          }

          .step-3 {
            flex: 1 1 100%;
            max-width: 400px;
          }

          .stats-grid {
            grid-template-columns: repeat(2, 1fr);
          }

          .stat-value {
            font-size: 36px;
          }

          .stat-label {
            font-size: 16px;
          }

          .parallax-content {
            flex-direction: column-reverse;
          }

          .parallax-image-container {
            width: 100%;
            height: 300px;
            margin-bottom: 30px;
          }

          .data-points {
            width: 100%;
          }
        }

        .bg-grey {
          background: #f1f1f1;
        }

        .bg-white {
          background: #fefefe;
        }
      `}</style>
    </div>
  );
};

export default LandingPage;
