import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../subcomponents/Footer';
import Header from '../subcomponents/Header';
import Chat from '../../AI/chat'

const TryAI = () => {

   

    return (
        <div className="AI-page">
             <Helmet>
                <title>AI</title>
                <meta name="description" content="We are in an AI revolution." />
                <meta name="keywords" content="AI, AI in Healthcare" />
                <meta property="og:title" content="AI" />
                <meta property="og:description" content="We are in an AI revolution." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
            </Helmet>
            <Header />
            <div className='AI-Content'>
                <main>
                <section className="websiteblock">
                    <div className='AI-header-text'>
                <h1 style={{marginBottom: "1rem"}}>We are in an AI revolution.</h1>
                       <div className='AI-subheader-text'>
                        <h4>Neural networks, large language models, and vector databases have given way to a new way of computing and interacting with technology. Because AI is new, the possibilities are endless. For now, we offer a chatbot and a doctor's note assistant.</h4>
                        </div>
                    </div>
                    <div className="blue-block">
                                <div className="try-gpt-box">
                                    <Chat special={true} />
                            </div>
                        </div>
                    </section>
                </main>
            </div>
            <Footer />
        </div>
    );
};


export default TryAI;
