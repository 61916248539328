import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { collection, addDoc, getDocs, updateDoc, doc } from "firebase/firestore";

function StepTen({ onNext, prevStep, setCanProceed }) {
  const [uid] = useUID();
  const [insurances, setInsurances] = useState([]);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!uid) return;

    const fetchInsurances = async () => {
      const colRef = collection(db, `users/${uid}/applications/ipaApplication/insurances`);
      try {
        const querySnapshot = await getDocs(colRef);
        const insurancesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setInsurances(insurancesData);
      } catch (error) {
        console.error("Error fetching insurances:", error);
      }
    };

    fetchInsurances();
  }, [uid]);

  useEffect(() => {
    // Check if there is at least one insurance and all required fields are filled out
    const formIsValid = insurances.some(insurance => 
      insurance.insuranceType && insurance.name && insurance.policyNumber && insurance.coverageLimits && insurance.dateFrom && insurance.dateTo
    );
    setIsValid(formIsValid);
    setCanProceed(formIsValid); // Assuming setCanProceed is passed from parent to enable/disable the Next button
  }, [insurances, setCanProceed]);

  const handleInsuranceChange = async (index, field, value) => {
    const updatedInsurances = [...insurances];
    updatedInsurances[index][field] = value;
    setInsurances(updatedInsurances);

    if (updatedInsurances[index].id) {
      const insuranceRef = doc(db, `users/${uid}/applications/ipaApplication/insurances`, updatedInsurances[index].id);
      try {
        await updateDoc(insuranceRef, { [field]: value });
      } catch (error) {
        console.error("Error updating insurance:", error);
      }
    }
  };

  const addInsurance = async () => {
    const newInsurance = {
      insuranceType: 'presentPrimary',
      name: '',
      policyNumber: '',
      coverageLimits: '',
      dateFrom: '',
      dateTo: ''
    };

    const colRef = collection(db, `users/${uid}/applications/ipaApplication/insurances`);
    try {
      const docRef = await addDoc(colRef, newInsurance);
      setInsurances([...insurances, { ...newInsurance, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding new insurance:", error);
    }
  };

  return (
    <div>
       <h2>In healthcare we understand Antifragility.</h2>
        <p className='typography-label-alt'>Please list you practices insurance(s).</p> 
      {insurances.map((insurance, index) => (
        <div key={index} className="location-containers">
          <div className="input-group-row">
            <div className="input-field">
              <label>Type of Insurance:</label>
              <select
                value={insurance.insuranceType}
                onChange={(e) => handleInsuranceChange(index, 'insuranceType', e.target.value)}
              >
                <option value="presentPrimary">Present Primary</option>
                <option value="presentExcess">Present Excess</option>
                <option value="previous">Previous</option>
              </select>
            </div>

            <div className="input-field">
              <label>Name of Carrier:</label>
              <input
                type="text"
                value={insurance.name}
                onChange={(e) => handleInsuranceChange(index, 'name', e.target.value)}
              />
            </div>

            <div className="input-field">
              <label>Policy Number:</label>
              <input
                type="text"
                value={insurance.policyNumber}
                onChange={(e) => handleInsuranceChange(index, 'policyNumber', e.target.value)}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label>Coverage Limits:</label>
              <input
                type="text"
                value={insurance.coverageLimits}
                onChange={(e) => handleInsuranceChange(index, 'coverageLimits', e.target.value)}
              />
            </div>

            <div className="input-field">
              <label>Date From:</label>
              <input
                type="date"
                value={insurance.dateFrom}
                onChange={(e) => handleInsuranceChange(index, 'dateFrom', e.target.value)}
              />
            </div>

            <div className="input-field">
              <label>Date To:</label>
              <input
                type="date"
                value={insurance.dateTo}
                onChange={(e) => handleInsuranceChange(index, 'dateTo', e.target.value)}
              />
            </div>
          </div>
        </div>
      ))}
        <button onClick={addInsurance} className="add-claim-button">
          {insurances.length > 0 ? "Add Another Insurance" : "Add Insurance"}
        </button>
    </div>
  );
}

export default StepTen;
