import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from "../../firebase";
import { doc, getDoc } from 'firebase/firestore';
import './SingleArticle.css';
import Footer from '../Website/subcomponents/Footer';
import SearchBar from '../Articles/SearchBar';
import Header from '../Website/subcomponents/Header';
import { Helmet } from 'react-helmet';
import SupportFeatured from '../Website/subcomponents/supportFeatured';


const SingleArticle = () => {
    const [article, setArticle] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchArticle = async () => {
            const docRef = doc(db, "articles", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setArticle(docSnap.data());
            } else {
            }
        };
        fetchArticle();
    }, [id]);

    if (!article) {
        return;
    }

    const keywords = article.keywords ? article.keywords.join(', ') : '';

    return (

        <div>
            <Header />

            <Helmet>
                <meta name="keywords" property="og:keywords" content={keywords} />
                <meta name="author" property="og:type" content="Popularis Health"></meta>
                <meta name="title" property="og:title" content="Popularis Health"></meta>
                <meta name="description" property="og:description" content={article.title}/>
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />  
                
                <title>{article.title}</title>
            </Helmet>

            <div className="single-article-container">
                
                <SearchBar />

                <div className="article-content" dangerouslySetInnerHTML={{ __html: article.article }}></div>
                <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>

            <SupportFeatured />
            </div>

            <Footer/ >
        </div>
    );
};

export default SingleArticle;
