import React, { useState, useEffect } from "react";
import styles from "./PatientFiles.modules.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrash } from "@fortawesome/free-solid-svg-icons";
import { db, auth } from "../../firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import GenerateClinicLink from "./Clinics/Clinics";
import useUID from "../General/useUID";

function FolderControls({ onFolderChange }) {
  const [clinics, setClinics] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("All Patients");
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (!uid) return;
    const clinicsCol = collection(db, "clinics");
    const q = query(clinicsCol, where("ownerId", "==", uid));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clinicList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClinics(clinicList);
    });

    // Select "All Patients" automatically.
    handleFolderSelect("All Patients");

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [uid]);

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    onFolderChange(folder);
  };

  return (
    <div className={styles.folderSelectContainer}>
      <ul className="folderList">
        <GenerateClinicLink />
        <li
          className={
            selectedFolder === "All Patients"
              ? "folder selectedFolder"
              : "folder"
          }
          onClick={() => handleFolderSelect("All Patients")}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon icon={faFolder} className="folderIcon" />
          <span className={styles.folderName}>All Patients</span>
        </li>
  
        {clinics.map((clinic) => (
          <li
            key={clinic.id}
            className={
              selectedFolder === clinic ? "folder selectedFolder" : "folder"
            }
            onClick={() => handleFolderSelect(clinic)}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faFolder} className="folderIcon" />
            <span className={styles.folderName}>{clinic.clinicName}</span>
          </li>
        ))}
  
        <li
          className={
            selectedFolder === "Deleted Patients"
              ? "folder selectedFolder"
              : "folder"
          }
          onClick={() => handleFolderSelect("Deleted Patients")}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon icon={faTrash} className="folderIcon" />
          <span className={styles.folderName}>Deleted Patients</span>
        </li>
      </ul>
    </div>
  );  
}

export default FolderControls;
