import React, { useState, useRef } from 'react';
import SignatureCanvas from "react-signature-canvas";

const InputTypeSignature = ({
  fieldItem,
  isVisible = true,
  preview = true,
  handleInputChange = () => { },
}) => {
  const [isSigned, setIsSigned] = useState(false);
  const signatureRef = useRef(null);

  const clearSignature = (event) => {
    event.preventDefault(); // Prevent form submission
    signatureRef.current.clear();
    setIsSigned(false); // Reset isSigned to false when the signature is cleared
    handleInputChange({ target: { name: fieldItem?.name, value: null } }); // Propagate an empty string to indicate the signature was cleared
  };

  const handleSignatureEnd = () => {
    const signatureImage = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    handleInputChange({ target: { name: fieldItem?.name, value: signatureImage } });
  };

  return (
    <>
      {isVisible && (
        <div className='m-auto signature-canvas-width'>
          {fieldItem?.label && (
            <label>
              {fieldItem?.label}
              <span className="RequiredMark">
                {fieldItem?.required && " *"}
              </span>
            </label>
          )}
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            className={preview ? 'signature-canvas-form-white' : "signature-canvas-form"}
            canvasProps={{
              width: 200 * 1.618,
              height: 200,
              className: preview ? 'signature-canvas-form-white' : "signature-canvas-form",
              willReadFrequently: true,
            }}
            onEnd={handleSignatureEnd}
          />
          <button className='primar mx-0 signature-canvas-width' onClick={clearSignature}>Clear Signature</button>
        </div>
      )}
    </>
  );
};

export default InputTypeSignature;