import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "./Claims.css";
import HexagonSpinner from "../General/Animations/Hexspinner";
import SingleClaim from "./Tools/SingleClaim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faNotesMedical,
  faFileInvoiceDollar,
  faArrowUp,
  faDownload,
  faDatabase,
  faFileMedical,
} from "@fortawesome/free-solid-svg-icons";
import {
  getFunctions,
  httpsCallableFromURL,
  httpsCallable,
} from "firebase/functions";
import ClaimsViewClaimStatus from "./Subcomponents/ClaimsViewClaimStatus";
import ClaimsViewEligibility from "./../PatientFiles/Billing/ClaimsViewEligibility";
import ClaimsStatusCards from "./Subcomponents/ClaimStatusView";
import useUID from "../General/useUID";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import ClaimsReportsView from "./Subcomponents/ClaimsReportsView";
import downloadClaimsData from "./DownloadClaimsData";
import SFSDMessageViewer from "./Subcomponents/SFSDMessageViewer";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import * as XLSX from 'xlsx';

const ClaimsPopup = ({ claimIds, onClose, selectedBatchChange }) => {
  const [showSingleClaimPopup, setShowSingleClaimPopup] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [CMSLoading, setisCMSLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [largeFileSizeMessage, setLargeFileSizeMessage] = useState(null);
  const [patientsData, setPatientsData] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [selectedClaimIndex, setSelectedClaimIndex] = useState(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [updatePatients, setUpdatePatients] = useState(false);
  const [uid, subUserUID] = useUID();
  const [showRunBatchModal, setShowRunBatchModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [batchClaimsStatusCost, setBatchClaimsStatusCost] = useState(0);
  const [resubmitClaimControlNumber, setResubmitClaimControlNumber] =
    useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const scrollingTableRef = useRef(null);
  const navigate = useNavigate();

  // Adjust to select based on claimId rather than index
  const handleClaimSelection = (claimId) => {
    const selected = patientsData.find((claim) => claim.claimId === claimId);
    setSelectedClaim(selected);

    // Use navigate to change the URL, interpolating the claimId
    navigate(`/claims/${claimId}`);
  };

  useEffect(() => {
    if (!uid) return;
    setLoading(true);
  
    const fetchClaimsData = async () => {
      try {
        const batchDecrypt = httpsCallable(getFunctions(), "batchDecryptClaims");
        const encryptedClaimData = await Promise.all(
          claimIds.map(async (claimId) => {
            const claimDocumentPath = `patients/${uid}/claims/${claimId}`;
            const claimRef = doc(db, claimDocumentPath);
            const claimSnapshot = await getDoc(claimRef);
  
            if (!claimSnapshot.exists()) {
              return null;
            }
  
            const claimData = claimSnapshot.data();
            const patientId = claimData.patientId;
            const patientClaimId = claimData.claimId;
            const requestDataPath = `patients/${uid}/patientData/${patientId}/claims/${patientClaimId}`;
            const requestDataRef = doc(db, requestDataPath);
            const requestDataSnapshot = await getDoc(requestDataRef);
  
            if (requestDataSnapshot.exists()) {
              return {
                claimId,
                encryptedData: requestDataSnapshot.data(),
                claimData,
              };
            } else {
              return { claimId, ...claimData };
            }
          })
        );
  
        const validEncryptedClaimData = encryptedClaimData.filter(data => data && data.encryptedData);

        if (validEncryptedClaimData.length > 0) {

          const decryptedResults = await batchDecrypt({ claims: validEncryptedClaimData.map(data => data.encryptedData) });
          const decryptedPatients = decryptedResults.data.decryptedClaims;

          const updatedClaims = encryptedClaimData.map((claim, index) => {
            if (!claim.encryptedData) return claim;
            const decryptedData = decryptedPatients[index];
            return { claimId: claim.claimId, ...claim.claimData, ...decryptedData };
          });
  
          setPatientsData(updatedClaims);
  
          if (updatedClaims.length > 0) {
            setSelectedClaim(updatedClaims[0]);
            setSelectedClaimIndex(0);
          } else {
            setSelectedClaim(null);
            setSelectedClaimIndex(null);
          }
        } else {
          setPatientsData(encryptedClaimData);
          setSelectedClaim(encryptedClaimData[0]);
          setSelectedClaimIndex(0);
        }
      } catch (error) {
        console.error("Error fetching or decrypting data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (claimIds && claimIds.length > 0) {
      fetchClaimsData();
    } else {
      setPatientsData([]);
      setSelectedClaim(null);
      setSelectedClaimIndex(null);
      setLoading(false);
    }
  }, [uid, selectedBatchChange]);
  

  useEffect(() => {
    if (!updatePatients) return;
    const updatePatientsWithStatusesAndReports = async () => {
      const updatedPatients = await Promise.all(
        patientsData.map(async (patient) => {
          const claimStatuses = await fetchClaimStatuses(patient);
          const reports = await fetchReportsForClaim(patient);
          return { ...patient, claimStatuses, reports };
        })
      );

      setPatientsData(updatedPatients);
      setUpdatePatients(false);

      // Automatically select the first claim if there are multiple claims
      if (updatedPatients.length > 1) {
        setSelectedClaim(updatedPatients[0]);
        setSelectedClaimIndex(0); // Set the index to 0 for the first claim
      } else if (updatedPatients.length === 1) {
        // If there's only one claim, select it
        setSelectedClaim(updatedPatients[0]);
        setSelectedClaimIndex(null); // No need to highlight as there's only one claim
      } else {
        // If there are no claims
        setSelectedClaim(null);
        setSelectedClaimIndex(null);
      }
    };
    if (patientsData.length > 0) {
      updatePatientsWithStatusesAndReports();
    }
  }, [updatePatients, patientsData]);

  useEffect(() => {
    if (!scrollingTableRef.current) {
      return;
    }
    const checkScrollTop = () => {
      const tableScrollPosition = scrollingTableRef.current.scrollTop;
      if (!showScrollToTop && tableScrollPosition > 400) {
        setShowScrollToTop(true);
      } else if (showScrollToTop && tableScrollPosition <= 400) {
        setShowScrollToTop(false);
      }
    };
    scrollingTableRef.current.addEventListener("scroll", checkScrollTop);
    return () => {
      if (scrollingTableRef.current) {
        scrollingTableRef.current.removeEventListener("scroll", checkScrollTop);
      }
    };
  }, [showScrollToTop]);

  // Calculate total charges
  const totalCharges = patientsData.reduce((sum, patient) => {
    const chargeAmount =
      patient.requestData?.claimInformation?.claimChargeAmount || 0;
    return sum + parseFloat(chargeAmount);
  }, 0);

  // Number of claims
  const numberOfClaims = patientsData.length;

  // Function to format the paid date
  const formatPaidDate = (dateStr) => {
    if (!dateStr || dateStr.length !== 8) return "-";

    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6, 8);

    return `${month}/${day}/${year}`;
  };

  // Function to convert DOB format
  function convertDobFormat(dob) {
    if (!dob || dob.length !== 8) return "";
    const year = dob.slice(0, 4);
    const month = dob.slice(4, 6);
    const day = dob.slice(6, 8);
    return `${year}-${month}-${day}`;
  }

  // Modify handleResubmitClick function
  const handleResubmitClick = async (e, patient) => {
    setResubmitClaimControlNumber(patient.controlNumber);
    setShowSingleClaimPopup(true);
    setSelectedPatient(patient.requestData);
  };

  // Function to send data to an external API
  const handleSendToAPI = async () => {
    setShowRunBatchModal(false);

    if (numberOfClaims > 400) {
      const estimatedTime = numberOfClaims * 0.3;
      const message = `Due to the large file size, we recommend you check back later. It is estimated to take ${estimatedTime} seconds.`;
      setLargeFileSizeMessage(message);
    }

    setLoading(true);
    try {
      const functions = getFunctions();
      const sendPatientData = httpsCallableFromURL(
        functions,
        process.env.REACT_APP_CLAIMSBATCHCLAIMSSTATUS_URL
      );
      const response = await sendPatientData({ uid: uid, patientsData });

      if (response && response.data.message) {
        // Assuming response.data.allClaims contains updates or new data for patients
        const apiData = response.data.allClaims;
      } else {
        // Handle the scenario where no message is returned
      }
    } catch (error) {
      console.error("Error sending data to the API:", error);
      setApiError("Error sending data to the API. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Check if any claim has status IDs and calculate total number of statuses and total paid
  const anyClaimHasStatusIds = patientsData.some(
    (patient) => patient.claimStatusIds && patient.claimStatusIds.length > 0
  );

  let numberOfStatuses = 0;
  let totalPaid = 0;

  if (anyClaimHasStatusIds) {
    patientsData.forEach((patient) => {
      numberOfStatuses += patient.claimStatuses?.length || 0;
      totalPaid +=
        patient.claimStatuses?.reduce(
          (sum, status) =>
            sum + (parseFloat(status.mainStatus?.amountPaid) || 0),
          0
        ) || 0;
    });
  }

  const fetchClaimStatuses = async (patient) => {
    let statuses = [];

    // Check if claimStatusIds exists and is an array
    if (patient?.claimStatusIds && Array.isArray(patient.claimStatusIds)) {
      for (const statusId of patient.claimStatusIds) {
        const statusRef = doc(
          db,
          `patients/${auth.currentUser.uid}/patientData/${patient.patientId}/claimStatus/${statusId}`
        );
        const statusSnapshot = await getDoc(statusRef);
        if (statusSnapshot.exists()) {
          statuses.push(statusSnapshot.data());
        }
      }
    }

    return statuses;
  };

  const fetchReportsForClaim = async (patient) => {
    let latestReport = null;
    if (patient?.reportIds && Array.isArray(patient.reportIds)) {
      for (const reportId of patient.reportIds) {
        const reportRef = doc(
          db,
          `patients/${auth.currentUser.uid}/patientData/${patient.patientId}/claims/${patient.claimId}/reports/${reportId}`
        );
        const reportSnapshot = await getDoc(reportRef);
        if (reportSnapshot.exists()) {
          const reportData = reportSnapshot.data();
          if (
            !latestReport ||
            reportData.timestamp.toDate() > latestReport.timestamp.toDate()
          ) {
            latestReport = reportData;
          }
        }
      }
    }

    return latestReport;
  };

  // Helper function to interpret claim status with expanded code coverage
  const interpretClaimStatus = (report) => {
    if (!report) return ""; // Return empty string if no report is available

    const adjustmentCodes =
      report.adjustments?.map((adj) => adj.adjustmentReasonCode) || [];

    // Define sets of codes for each category
    const deniedCodes = ["2", "3", "5"]; // Example denied status codes
    const rejectedCodes = ["6", "7", "8"]; // Example rejected status codes
    const paidCodes = ["1"]; // Example paid status codes
    const partialPaymentCodes = ["9", "10"]; // Example partial payment codes
    const acknowledgedCodes = ["11", "12", "13"]; // Example acknowledged status codes
    const informationalCodes = ["14", "15", "16"]; // Long, descriptive reason codes

    // Check for specific codes in adjustments and claim status
    if (
      deniedCodes.includes(report.claimStatusCode) ||
      adjustmentCodes.some((code) => deniedCodes.includes(code))
    ) {
      return "Denied";
    }
    if (
      rejectedCodes.includes(report.claimStatusCode) ||
      adjustmentCodes.some((code) => rejectedCodes.includes(code))
    ) {
      return "Rejected";
    }
    if (paidCodes.includes(report.claimStatusCode)) {
      return adjustmentCodes.length === 0 ? "Paid" : "Partial Payment";
    }
    if (
      partialPaymentCodes.includes(report.claimStatusCode) ||
      adjustmentCodes.some((code) => partialPaymentCodes.includes(code))
    ) {
      return "Partial Payment";
    }
    if (informationalCodes.includes(report.claimStatusCode)) {
      return "Informational";
    }
    if (acknowledgedCodes.includes(report.claimStatusCode)) {
      return "Acknowledged";
    }

    // Default to 'Unknown' for any codes not covered
    return "Unknown";
  };

  // Function to display report details
  const displayReportDetails = (report) => {
    if (!report) return <p></p>;

    return (
      <div>
        <p>Status: {interpretClaimStatus(report)}</p>
        <p>Total Charge Amount: ${report.lineItemChargeAmount}</p>
        <p>
          Total Provider Payment Amount: ${report.lineItemProviderPaymentAmount}
        </p>
        {report.adjustments &&
          report.adjustments.map((adj, index) => (
            <div key={index}>
              <p>Adjustment Reason Code: {adj.adjustmentReasonCode}</p>
              <p>Adjustment Amount: ${adj.adjustmentAmount}</p>
            </div>
          ))}
      </div>
    );
  };

  const scrollToTop = () => {
    if (scrollingTableRef.current) {
      scrollingTableRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const fetchUserAccountTier = async (uid) => {
    const docRef = doc(db, `users/${uid}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().AccountTier;
    } else {
      return "Freebie";
    }
  };

  // Function to fetch the account tier and calculate the cost
  const fetchTierAndCalculateCost = async () => {
    const accountTier = await fetchUserAccountTier(uid);
    let tierCharge;
    switch (accountTier) {
      case "Professional":
        tierCharge = 0.35;
        break;
      case "Enterprise":
        tierCharge = 0.25;
        break;
      case "Freebie":
      default:
        tierCharge = 0;
        break;
    }

    const cost = numberOfClaims * tierCharge;
    setBatchClaimsStatusCost(cost);
    setShowRunBatchModal(true);
  };

  const showBatchMenu = selectedClaim?.batchId;

  // Modified onClose function
  const modifiedOnClose = () => {
    // Reset the URL
    navigate("/claims");

    // If there is any existing onClose logic, include it here
    if (typeof onClose === "function") {
      onClose();
    }
  };

  // Function to handle navigation to patient view
  const handleViewPatient = (patientId) => {
    navigate(`/patients/${patientId}`);
  };

  const handleDownload = () => {
    downloadClaimsData(patientsData);
  };

  const filteredData = patientsData.filter((patient) => {
    const dobFormatted = formatPaidDate(
      patient.requestData.subscriber.dateOfBirth
    );
    const dosFormatted = formatPaidDate(
      patient.requestData.claimInformation.serviceLines[0].serviceDate
    );
    const dosToFormatted = formatPaidDate(
      patient.requestData.claimInformation.serviceLines[0].serviceDateEnd
    );


    const diagnosisCodes =
      patient.requestData.claimInformation.healthCareCodeInformation
        .map((info) => info.diagnosisCode)
        .join(", ");

    return (
      patient.requestData.subscriber.firstName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      patient.requestData.subscriber.middleName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      patient.requestData.subscriber.lastName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      dobFormatted.includes(searchQuery) ||
      dosFormatted.includes(searchQuery) ||
      patient.requestData.receiver.organizationName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      patient.requestData.subscriber.memberId?.includes(searchQuery) ||
      patient.requestData.claimInformation.serviceLines.some((line) =>
        line.professionalService.procedureCode?.includes(searchQuery)
      ) ||
      diagnosisCodes.toLowerCase().includes(searchQuery.toLowerCase()) ||
      patient.requestData.controlNumber?.toString().includes(searchQuery) ||
      patient.requestData.claimInformation.claimChargeAmount
        ?.toString()
        .includes(searchQuery)
    );
  });
  const generatecms1500 = async () => {
    setisCMSLoading(true)
    const functions = getFunctions();
    const generateCMS = httpsCallable(functions, "generateCMS1500");

    try {
        const response = await generateCMS(selectedClaim.requestData);
        const result = response.data;
        if (result.success) {
            const pdfData = result.pdfData;
            const pdfBlob = new Blob([new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Create a temporary link element and trigger the download
            const downloadLink = document.createElement('a');
            downloadLink.href = pdfUrl;
            downloadLink.download = "CMS1500_Form.pdf"; // This can be dynamically set to a more descriptive name
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(pdfUrl); // Clean up the URL object

        } else {
            console.error("Failed to generate CMS-1500 form:", result.message);
            setisCMSLoading(false)

        }
    } catch (error) {
        console.error("Error calling generateCMS1500 function:", error);
        // setApiError("Error generating CMS-1500 form. Please try again.");
        setisCMSLoading(false)
    }
    setisCMSLoading(false)
};


  const handleDownloadJson = () => {
    const dataToDownload = { requestData: selectedClaim.requestData, responseData: selectedClaim.responseData };
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(dataToDownload));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "claim_data.json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  const handleDownloadXlsx = () => {
    downloadClaimsData(patientsData);
  };

  const openDownloadModal = () => setShowDownloadModal(true);
  const closeDownloadModal = () => setShowDownloadModal(false);
  

  return (
    <div className="batch-popup-container">
      <div className="batch-popup">
        {loading ? (
            <>
          <HexagonSpinner /> 
          <p className="authMessage" >Decryping claims data...</p>
          </>
        ) : (
          <>
            {showSingleClaimPopup ? (
              <>
                <button
                  onClick={() => setShowSingleClaimPopup(false)}
                  className="filesCloseButton"
                >
                  X
                </button>
                <div className="popupContentClaimsPopup">
                  <div className="singleClaimPopup">
                    <SingleClaim
                      initialFirstName={selectedPatient?.subscriber?.firstName}
                      initialMiddleName={
                        selectedPatient?.subscriber?.middleName
                      }
                      initialLastName={selectedPatient?.subscriber?.lastName}
                      initialDob={convertDobFormat(
                        selectedPatient?.subscriber?.dateOfBirth
                      )}
                      initialDos={convertDobFormat(
                        selectedPatient?.claimInformation?.serviceLines[0]
                          .serviceDate
                      )}
                      initialDosTo={convertDobFormat(
                        selectedPatient?.claimInformation?.serviceLines[0]
                          .serviceDateEnd
                      )}
                      initialGender={selectedPatient?.subscriber?.gender}
                      initialmemberId={selectedPatient?.subscriber?.memberId}
                      initialaddress1={
                        selectedPatient?.subscriber?.address.address1
                      }
                      initialaddress2={
                        selectedPatient?.subscriber?.address.address2
                      }
                      initialcity={selectedPatient?.subscriber?.address.city}
                      initialstate={selectedPatient?.subscriber?.address.state}
                      initialzip={
                        selectedPatient?.subscriber?.address.postalCode
                      }
                      initialTradingPartnerName={
                        selectedPatient?.receiver?.organizationName
                      }
                      patientId={selectedClaim.patientId}
                      resubmitClaimControlNumber={resubmitClaimControlNumber}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {showBatchMenu && (
                  <div className="batchListContainer" ref={scrollingTableRef}>
                    <div className="batchListContent">
                      <div className="run-claim-status-popup-container">
                        <div className="run-claim-status-popup">
                          {/* {loading ? (
                            <HexagonSpinner />
                          ) : (
                            <div className="flex-container">
                              <button
                                onClick={fetchTierAndCalculateCost}
                                className="primary"
                              >
                                <FontAwesomeIcon
                                  className="mr-3"
                                  icon={faPlay}
                                />{" "}
                                Run Claim Status on Batch
                              </button>
                            </div>
                          )} */}
                          <button
                            onClick={handleDownload}
                            className="btn btn-primary"
                          >
                            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDownload}/>XLSX
                          </button>
                          {largeFileSizeMessage && (
                            <p style={{ color: "red" }}>
                              {largeFileSizeMessage}
                            </p>
                          )}
                          {apiError && (
                            <p style={{ color: "red" }}>{apiError}</p>
                          )}
                        </div>
                      </div>
                      <Modal
                        isOpen={showRunBatchModal}
                        onRequestClose={() => setShowRunBatchModal(false)}
                        className="confirmModal"
                      >
                        <h3>
                          Confirming that you want claim status for{" "}
                          {numberOfClaims} claims?
                        </h3>
                        <p>It will cost ${batchClaimsStatusCost.toFixed(2)}</p>
                        <div className="confirmButtons">
                          <button
                            className="secondaryButton"
                            onClick={() => setShowRunBatchModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="primaryButton"
                            onClick={handleSendToAPI}
                          >
                            Run
                          </button>
                        </div>
                      </Modal>
                      <div className="batch-summary-container">
                        <div>
                          <h4 className="batch-popup-title">Sent</h4>
                          <p className="batch-popup-text">
                            ${totalCharges.toFixed(2)}
                          </p>
                        </div>
                        <div>
                          <h4 className="batch-popup-title">Claims</h4>
                          <p className="batch-popup-text">{numberOfClaims}</p>
                        </div>
                      </div>
                      <div style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                      <input
                        type="text"
                        placeholder="Search this batch..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-bar"
                      />
                      </div>
                      {loading ? (
                        Array(8)
                          .fill()
                          .map((_, idx) => (
                            <div key={idx} className="batch-placeholder"></div>
                          ))
                      ) : (
                        <>
                          {numberOfClaims > 1 &&
                            filteredData.map((patient, index) => {
                              const {
                                subscriber,
                                claimInformation,
                                tradingPartnerName,
                              } = patient.requestData;

                              const latestReport = patient.reports;
                              const claimStatus =
                                interpretClaimStatus(latestReport);

                              return (
                                <div
                                  onClick={() =>
                                    handleClaimSelection(patient.claimId)
                                  }
                                  className={`batch-wrapper ${
                                    selectedClaim?.claimId === patient.claimId
                                      ? "batch-selected"
                                      : ""
                                  }`}
                                >
                                  <div className="batch-header">
                                    <FontAwesomeIcon
                                      icon={faNotesMedical}
                                      className="medical-icon"
                                    />
                                    <h3>
                                      {subscriber.firstName}{" "}
                                      {subscriber.lastName}
                                    </h3>
                                  </div>
                                  <div className="batch-header-trading">
                                    <p>{tradingPartnerName}</p>
                                  </div>
                                  <p className="batch-meta">
                                    $
                                    {parseFloat(
                                      claimInformation.claimChargeAmount || 0
                                    ).toFixed(2)}
                                    <br />
                                    {formatPaidDate(
                                      claimInformation.serviceLines[0]
                                        ?.serviceDate
                                    )}
                                    <br />
                                    {claimStatus}
                                  </p>
                                  {/* Additional details can be added here if needed */}
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                    <div className="scrollButtonContainer">
                      <button
                        className={`scrollToTopButtonClaims ${
                          showScrollToTop ? "visible" : ""
                        }`}
                        onClick={scrollToTop}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </button>
                    </div>
                  </div>
                )}


                {!selectedClaim && (
                  <>
                    <HexagonSpinner />
                    <p className="authMessage" >Decrypting claims data...</p>
                  </>
                )}

                <div
                  className={`popupClaimsPopup ${
                    showBatchMenu ? "popupClaimsPopupWithList" : ""
                  }`}
                >
                 
                    {selectedClaim && (
                        <div className="batch-popup-content">
                           <button
                            onClick={modifiedOnClose}
                            className="filesCloseButtonClaimsSingleView"
                          >
                            X
                          </button>
                        <div className="claimsTopView">
                          <div className="claimsButtonsleft">
                            <div className="claimsButtonsRight">
                              {/* <ClaimsViewEligibility
                                claimId={selectedClaim.claimId}
                                patientId={selectedClaim.patientId}
                              /> */}
                              <ClaimsViewClaimStatus
                                claimId={selectedClaim.claimId}
                                patientId={selectedClaim.patientId}
                              />
                              <button
                                onClick={() =>
                                  handleResubmitClick(null, selectedClaim)
                                }
                                className="btn btn-outline-secondary"
                                disabled={loading}
                                title="Resubmit this claim"
                              >
                                {loading ? (
                                  "Resubmitting..."
                                ) : (
                                  <FontAwesomeIcon
                                    className="mr-3"
                                    icon={faFileInvoiceDollar}
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                Resubmit Claim
                              </button>
                                <button onClick={openDownloadModal}>
                                  <FontAwesomeIcon
                                    className="mr-3"
                                    icon={faDownload}
                                    style={{ marginRight: "5px" }}
                                  />
                                  Download
                                </button>
                                <Modal
                                  isOpen={showDownloadModal}
                                  onRequestClose={closeDownloadModal}
                                  className="confirmModal"
                                >
                                  <>
                                    <button
                                      onClick={closeDownloadModal}
                                      className="filesCloseButton"
                                    >
                                      X
                                    </button>
                                  <div style={{ paddingLeft: "1rem"}}>
                                    {CMSLoading ? (
                                      <HexagonSpinner /> // Show spinner when loading
                                    ) : (
                                      <button onClick={generatecms1500}>
                                        <FontAwesomeIcon
                                          className="mr-3"
                                          icon={faFileMedical}
                                          style={{ marginRight: "5px" }}
                                        />
                                        CMS-1500
                                      </button>
                                    )}
                                    <button onClick={handleDownloadJson} className="btn btn-secondary">
                                      <FontAwesomeIcon
                                        className="mr-3"
                                        icon={faDatabase}
                                        style={{ marginRight: "5px" }}
                                      />
                                      JSON
                                    </button>
                                    <button onClick={handleDownloadXlsx} className="btn btn-secondary">
                                      <FontAwesomeIcon
                                        className="mr-3"
                                        icon={faFileExcel}
                                        style={{ marginRight: "5px" }}
                                      />
                                      XLSX
                                    </button>
                                    </div>
                                  </>
                                </Modal>
                            </div>

                            <h3>
                              {selectedClaim.requestData.subscriber.firstName}{" "}
                              {selectedClaim.requestData.subscriber.lastName}
                            </h3>
                            <h4 title="Payer name and patient member ID.">
                              {" "}
                              {
                                selectedClaim.requestData.tradingPartnerName
                              }{" "}
                              {" | "}{" "}
                              {selectedClaim.requestData.subscriber.memberId}
                            </h4>
                            <h4 title="Patient gender and date of birth.">
                              {selectedClaim.requestData.subscriber.gender} |{" "}
                              {formatPaidDate(
                                selectedClaim.requestData.subscriber.dateOfBirth
                              )}
                            </h4>
                            <span
                              onClick={() =>
                                handleViewPatient(selectedClaim.patientId)
                              }
                              style={{ color: "#0147DF", cursor: "pointer" }}
                            >
                              View Patient's File {">"}
                            </span>
                            <div
                              style={{
                                height: "1px",
                                backgroundColor: "black",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            ></div>
                            <h4>
                              {selectedClaim.requestData.tradingPartnerName} |
                              Total Charges: $
                              {
                                selectedClaim.requestData.claimInformation
                                  .claimChargeAmount
                              }
                            </h4>
                            <div
                              style={{
                                height: "1px",
                                backgroundColor: "black",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            ></div>
                            <SFSDMessageViewer
                              claimId={selectedClaim.claimId}
                              patientId={selectedClaim.patientId}
                            />
                          </div>
                        </div>
                        {displayReportDetails(selectedClaim.reports)}
                        <table>
                          <thead>
                            <tr>
                              <th>Service Date</th>
                              <th>Procedure Code</th>
                              <th>Charge Amount</th>
                              <th>Diagnosis Codes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedClaim.requestData.claimInformation.serviceLines.map(
                              (line, index) => (
                                <tr key={index}>
                                    <td>
                                      {line.serviceDate && (
                                        <>
                                          {formatPaidDate(line.serviceDate)}
                                          {line.serviceDateEnd && ' - '}
                                          {line.serviceDateEnd && formatPaidDate(line.serviceDateEnd)}
                                        </>
                                      )}
                                    </td>
                                  <td>
                                    {line.professionalService.procedureCode}
                                  </td>
                                  <td>
                                    $
                                    {
                                      line.professionalService
                                        .lineItemChargeAmount
                                    }
                                  </td>
                                  <td>
                                    {line.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers
                                      .map(
                                        (pointer) =>
                                          selectedClaim.requestData
                                            .claimInformation
                                            .healthCareCodeInformation[
                                            pointer - 1
                                          ]?.diagnosisCode
                                      )
                                      .join(", ")}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>

                        {/* Additional claim details */}
                        <p>
                          Service Provider:{" "}
                          {
                            selectedClaim.requestData.claimInformation
                              .serviceFacilityLocation.organizationName
                          }
                          {" | "}
                          {
                            selectedClaim.requestData.claimInformation
                              .serviceFacilityLocation.address.address1
                          }
                          {", "}
                          {
                            selectedClaim.requestData.claimInformation
                              .serviceFacilityLocation.address.city
                          }
                          {", "}
                          {
                            selectedClaim.requestData.claimInformation
                              .serviceFacilityLocation.address.state
                          }{" "}
                          {
                            selectedClaim.requestData.claimInformation
                              .serviceFacilityLocation.address.zip
                          }
                        </p>
                        <p>
                          Control Number:{" "}
                          {selectedClaim.requestData.controlNumber}
                        </p>
                        <p>
                          Patient Control Number:{" "}
                          {
                            selectedClaim.requestData.claimInformation
                              .patientControlNumber
                          }
                        </p>
                        <p>
                          Trace ID: {selectedClaim.responseData?.meta?.traceId}
                        </p>
                        
                      </div>
                    )}
                    {selectedClaim && (
                      <div className="StatusandEligibility">
                        <div>
                          <h3>Reports (ERAs)</h3>
                          <ClaimsReportsView
                            claimId={selectedClaim.claimId}
                            patientId={selectedClaim.patientId}
                          />
                        </div>

                        <div>
                          <h3>Claim Status</h3>
                          <ClaimsStatusCards
                            claimId={selectedClaim.claimId}
                            patientId={selectedClaim.patientId}
                          />
                        </div>
                      </div>
                    )}

                  {/* API error message */}
                  {apiError && <p style={{ color: "red" }}>{apiError}</p>}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClaimsPopup;
