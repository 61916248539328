import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { db } from "../../../firebase";
import HexSpinner from "../../General/Animations/Hexspinner";

const SearchBox = ({ onCodeSelect, initialSelectedCodes }) => {
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [manualOverride, setManualOverride] = useState(false);
  const [manualCode, setManualCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (initialSelectedCodes && initialSelectedCodes.length > 0) {
      setSelectedCodes(initialSelectedCodes);
      initialSelectedCodes.forEach(code => searchAndSelectCode(code));
    }
  }, [initialSelectedCodes]);

  const searchAndSelectCode = async (code) => {
    setIsLoading(true);
    const results = await searchICD10(code);
    if (results.length > 0) {
      handleCodeSelect(results[0]);
    }
    setIsLoading(false);
  };

  const handleCodeSelect = (result) => {
    if (!selectedCodes.some((code) => code.code === result.code)) {
      const newSelectedCodes = [...selectedCodes, result];
      setSelectedCodes(newSelectedCodes);
      setSearchTerm("");
      setShowResults(false);

      onCodeSelect(newSelectedCodes);
    }
  };

  const handleManualCodeSelect = () => {
    if (manualCode.includes(".")) {
      setErrorMessage("Periods are not allowed in diagnosis codes in Popularis.");
    } else if (
      manualCode.trim() !== "" &&
      !selectedCodes.some((code) => code.code === manualCode)
    ) {
      const newSelectedCodes = [...selectedCodes, { code: manualCode }];
      setSelectedCodes(newSelectedCodes);
      setManualOverride(false);
      setManualCode("");
      setErrorMessage("");

      if (onCodeSelect) {
        onCodeSelect(newSelectedCodes);
      }
    } else {
      console.error("Code is already in the list");
    }
  };

  const handleChangeManualCode = (event) => {
    setManualCode(event.target.value);
  };

  const removeSelectedCode = (codeToRemove) => {
    const updatedSelectedCodes = selectedCodes.filter(
      (code) => code.code !== codeToRemove
    );
    setSelectedCodes(updatedSelectedCodes);

    if (onCodeSelect) {
      onCodeSelect(updatedSelectedCodes);
    }
  };

  const searchICD10 = async (term) => {
    try {
      const icd10Ref = collection(db, "ICD10");
      const cleanedTerm = term.trim().replace(".", ""); // Remove periods
      const capitalizedTerm =
        cleanedTerm.charAt(0).toUpperCase() + cleanedTerm.slice(1);
      const upperCaseTerm = cleanedTerm.toUpperCase();

      const executeQueries = async (searchTerm) => {
        const codeQuery = query(
          icd10Ref,
          where("code", ">=", searchTerm.toUpperCase()), // Assuming codes are uppercase
          where("code", "<=", `${searchTerm.toUpperCase()}\uf8ff`),
          limit(25)
        );

        const descriptionQuery = query(
          icd10Ref,
          where("short_description", ">=", searchTerm),
          where("short_description", "<=", `${searchTerm}\uf8ff`),
          limit(25)
        );

        const longDescriptionQuery = query(
          icd10Ref,
          where("long_description", ">=", searchTerm),
          where("long_description", "<=", `${searchTerm}\uf8ff`),
          limit(25)
        );

        const snapshots = await Promise.all([
          getDocs(codeQuery),
          getDocs(descriptionQuery),
          getDocs(longDescriptionQuery),
        ]);

        const combinedResults = [];
        snapshots.forEach((snapshot) => {
          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            if (data.valid_code === 1) {
              // Filtering only valid codes
              combinedResults.push(data);
            }
          });
        });
        return combinedResults;
      };

      const resultsCleaned = await executeQueries(cleanedTerm);
      const resultsCapitalized = await executeQueries(capitalizedTerm);
      const resultsUpperCase = await executeQueries(upperCaseTerm);

      const resultMap = {};
      [...resultsCleaned, ...resultsCapitalized, ...resultsUpperCase].forEach(
        (result) => {
          resultMap[result.code] = result;
        }
      );
      const combinedResults = Object.values(resultMap);
      return combinedResults;
    } catch (error) {
      console.error("Error while searching ICD10:", error);
      return [];
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === ".") {
      e.preventDefault();
    } else {
      handleSearch();
    }
  };

  const throttle = (func, delay) => {
    let isThrottled = false;
    return (...args) => {
      if (isThrottled) return;
      func.apply(null, args);
      isThrottled = true;
      setTimeout(() => {
        isThrottled = false;
      }, delay);
    };
  };

  const handleChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleSearch = async () => {
    if (searchTerm.trim() !== "") {
      setIsLoading(true);
      const results = await searchICD10(searchTerm);
      setSearchResults(results);
      setShowResults(true);
      setIsLoading(false);
    } else {
      setSearchResults([]);
      setShowResults(false);
    }
  };

  useEffect(() => {
    const throttledSearch = throttle(handleSearch, 100);
    throttledSearch(searchTerm);
  }, [searchTerm]);

  return (
    <div>
      <input
        type="text"
        placeholder="Search ICD10 codes"
        value={searchTerm}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      {isLoading ? (
        <HexSpinner />
      ) : (
        <>
          {manualOverride ? (
            <div className="">
              <input
                type="text"
                placeholder="Enter Diagnosis Code"
                value={manualCode}
                onChange={(e) => handleChangeManualCode(e)}
              />
              <button type="button" onClick={() => handleManualCodeSelect()}>
                + Add Code
              </button>
              {errorMessage && <p>{errorMessage}</p>}
              <button
                type="button"
                className=""
                onClick={() => setManualOverride(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button type="button" onClick={() => setManualOverride(true)}>
              Manual
            </button>
          )}
          {showResults && (
            <div className="results-container">
              {searchResults.map((result, index) => (
                <div key={`${result.code}-${index}`}>
                  <span>
                    <button
                      type="button"
                      onClick={() => handleCodeSelect(result)}
                    >
                      Select
                    </button>
                    {result.code} - {result.long_description}
                  </span>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {selectedCodes.length > 0 && (
        <div>
          {selectedCodes.map((code, index) => (
            <div
              className="primary-card w-100 content-center py-2 mt-3 m-0 px-0 pb-0"
              key={index}
              onClick={() => removeSelectedCode(code.code)}
            >
              <h3>{code.code}</h3>
              {code.short_description && <p>{code.short_description}</p>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBox;
