import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import ClaimStatusCategoryFlag from "../../ClaimStatus/ClaimStatusCategoryFlag";
import styles from "../Claims.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getDatabase, ref, onValue } from "firebase/database";
import * as XLSX from "xlsx";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const ClaimsTable = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [claimsData, setClaimsData] = useState([]);
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [uid, subUserUID] = useUID();
  const [searchInput, setSearchInput] = useState("");
  const [filterPopupVisible, setFilterPopupVisible] = useState(false);
  const [selectedPayers, setSelectedPayers] = useState(new Set());
  const [selectedProviders, setSelectedProviders] = useState(new Set());
  const [showFilters, setShowFilters] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();

  const calculateTotalChargeAmount = (serviceLines) => {
    return serviceLines.reduce((total, line) => {
      const chargeAmount = parseFloat(
        line.professionalService?.lineItemChargeAmount || 0
      );
      return total + chargeAmount;
    }, 0);
  };

  useEffect(() => {
    if (!uid) return;

    const db = getDatabase();
    // Define claimsRef here using the uid to construct the path
    const claimsRef = ref(db, `fetchClaimsData/${uid}`);

    const unsubscribe = onValue(
      claimsRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const claimsArray = Object.keys(data).map((key) => {
            const claim = data[key];
            // Calculate the total charge amount for each claim
            const totalChargeAmount = calculateTotalChargeAmount(
              claim.serviceLines
            );
            return {
              ...claim,
              id: key,
              totalChargeAmount, // Add this property to each claim
            };
          });
          setClaimsData(claimsArray);
        } else {
          setClaimsData([]);
        }
        setLoading(false);
      },
      {
        onlyOnce: true,
      }
    );

    return () => unsubscribe();
  }, [uid]);

  const toggleSelectedClaim = (claimId) => {
    setSelectedClaimId(selectedClaimId === claimId ? null : claimId);
  };

  const findServiceDetails = (procedureId, serviceDetails) => {
    return serviceDetails.find((detail) => detail.procedureId === procedureId);
  };

  const sortStatusesByDate = (statuses) => {
    // Check if statuses is undefined or null before attempting to sort
    if (!statuses) return [];

    // Safely access properties within each status and handle cases where mainStatus or updatedAt might be undefined
    return statuses
      .filter((status) => status?.mainStatus?.updatedAt)
      .sort((a, b) => {
        const dateA = new Date(a.mainStatus.updatedAt);
        const dateB = new Date(b.mainStatus.updatedAt);
        return dateB - dateA;
      });
  };

  // Utility function to format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp._seconds) return "";

    return new Date(timestamp._seconds * 1000).toLocaleDateString();
  };

  // Function to handle search input changes
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value.toLowerCase());
  };

  // Function to get unique payers and providers from claims data
  const getUniqueOptions = (data, key) => {
    return [...new Set(data.map((claim) => claim[key]))];
  };

  const uniquePayers = getUniqueOptions(claimsData, "receiver");
  const uniqueProviders = getUniqueOptions(claimsData, "provider");

  // Function to handle payer/provider checkbox changes
  const handleFilterChange = (value, type) => {
    const updateSet = new Set(
      type === "payer" ? selectedPayers : selectedProviders
    );
    if (updateSet.has(value)) {
      updateSet.delete(value);
    } else {
      updateSet.add(value);
    }
    type === "payer"
      ? setSelectedPayers(updateSet)
      : setSelectedProviders(updateSet);
  };

  const formatDOB = (dob) => {
    if (!dob || dob.length !== 8) return dob;
    return `${dob.substring(4, 6)}/${dob.substring(6, 8)}/${dob.substring(
      0,
      4
    )}`;
  };

  const formatDateForComparison = (date) => {
    // Assuming date is in 'YYYYMMDD' format
    if (!date || date.length !== 8) return "";
    // Convert to 'YYYY-MM-DD' for consistency with input date format
    return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
      6,
      8
    )}`;
  };

  const filteredClaimsData = claimsData.filter((claim) => {
    // Check if the claim matches the search input
    const matchesSearch = searchInput.toLowerCase();
    const dobFormatted = formatDOB(claim.dateOfBirth);
    const submittedDateFormatted = formatTimestamp(claim.timestamp);
    const serviceDateFormatted = formatDateForComparison(
      claim.serviceLines[0]?.serviceDate || ""
    );
  
    // Check if the claim matches the selected payers and providers
    const matchesPayers =
      selectedPayers.size === 0 || selectedPayers.has(claim.receiver);
    const matchesProviders =
      selectedProviders.size === 0 || selectedProviders.has(claim.provider);
  
    // Check if the claim falls within the selected date range
    const serviceDate = new Date(serviceDateFormatted);
    const fromDateObj = fromDate ? new Date(fromDate) : null;
    const toDateObj = toDate ? new Date(toDate) : null;
    const isAfterFromDate = !fromDate || serviceDate >= fromDateObj;
    const isBeforeToDate = !toDate || serviceDate <= toDateObj;
  
    return (
      (claim.firstName.toLowerCase().includes(matchesSearch) ||
        claim.lastName.toLowerCase().includes(matchesSearch) ||
        claim.receiver.toLowerCase().includes(matchesSearch) ||
        claim.provider.toLowerCase().includes(matchesSearch) ||
        claim.memberId.toLowerCase().includes(matchesSearch) ||
        dobFormatted.includes(matchesSearch) ||
        submittedDateFormatted.includes(matchesSearch) ||
        claim.serviceLines.some((line) =>
          line.professionalService?.procedureCode?.toLowerCase?.().includes(matchesSearch)
        ) ||
        claim.diagnosisCodes.some(
          (code) =>
            typeof code === "string" &&
            code.toLowerCase().includes(matchesSearch)
        )) &&
      matchesPayers &&
      matchesProviders &&
      isAfterFromDate &&
      isBeforeToDate
    );
  });
  

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Loading every detail of every last claim.</h4>
      </div>
    );
  }
  const toggleFilterModal = () => setShowFilters(!showFilters);

  const handleMoreInfoClick = (claimId) => {
    navigate(`/claims/${claimId}`);
  };

  const handleDownload = () => {
    const dataForDownload = filteredClaimsData.map((claim) => ({
      "First Name": claim.firstName,
      "Last Name": claim.lastName,
      Gender: claim.gender,
      "Date of Birth": formatDOB(claim.dateOfBirth),
      "Member ID": claim.memberId,
      "Control Number": claim.controlNumber,
      "Patient Control Number": claim.patientControlNumber,
      "Address 1": claim.address?.address1,
      City: claim.address?.city,
      State: claim.address?.state,
      "Postal Code": claim.address?.postalCode,
      "Diagnosis Codes": claim.diagnosisCodes.join(", "),
      Provider: claim.provider,
      Receiver: claim.receiver,
      "Payment Responsibility Level Code": claim.paymentResponsibilityLevelCode,
      "Service Date": claim.serviceLines
        .map((line) => formatDOB(line?.serviceDate || ""))
        .join(", "),
      "Procedure Codes": claim.serviceLines
        .map((line) => line.professionalService?.procedureCode)
        .join(", "),
      "Charge Amount": claim.serviceLines
        .map((line) => `$${line.professionalService?.lineItemChargeAmount}`)
        .join(", "),
      // Include any additional fields you want to export
    }));

    const ws = XLSX.utils.json_to_sheet(dataForDownload);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ClaimsData");
    XLSX.writeFile(wb, "FilteredClaimsData.xlsx");
  };


  // {claim.serviceLines.map((line, idx) => {
  //   const detail = findServiceDetails(
  //     line.professionalService.procedureCode,
  //     claim.statuses.flatMap(
  //       (status) => status.serviceDetails || []
  //     )
  //   );
  //   return (
  //     <tr key={idx}>
  //       <td>
  //         {line.professionalService.procedureCode}
  //       </td>
  //       <td>{claim.diagnosisCodes.join(", ")}</td>
  //       <td>
  //         $
  //         {
  //           line.professionalService
  //             .lineItemChargeAmount
  //         }
  //       </td>
  //       <td>${detail?.amountPaidService || "N/A"}</td>
  //       <td>
  //         <ClaimStatusCategoryFlag
  //           statusCategoryCode={
  //             detail?.statusCategoryCode
  //           }
  //         />
  //       </td>
  //     </tr>
  //   );
  // })}


  return (
    <div
      className="Claims-inner-container"
      style={{ display: "flex", alignItems: "flex-start" }}
    >
      <button className="filesCloseButton" onClick={onClose}>
        X
      </button>
      {/* Filter Modal */}
      {showFilters && (
        <div className="overlay">
          <div className="modalBox">
            <button className="" onClick={toggleFilterModal}>
              X
            </button>
            <h4>Filter by Payer</h4>
            {uniquePayers.map((payer) => (
              <div key={payer}>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={selectedPayers.has(payer)}
                  onChange={() => handleFilterChange(payer, "payer")}
                />
                {payer}
              </div>
            ))}
            <h4>Filter by Provider</h4>
            {uniqueProviders.map((provider) => (
              <div key={provider}>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={selectedProviders.has(provider)}
                  onChange={() => handleFilterChange(provider, "provider")}
                />
                {provider}
              </div>
            ))}
            <div className="dateFilters">
              <label htmlFor="fromDate">From Date:</label>
              <input
                type="date"
                id="fromDate"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />

              <label htmlFor="toDate">To Date:</label>
              <input
                type="date"
                id="toDate"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      )}

      <div className="claimsTableContainer">
      <div className="centerHeader">
            <h3>Claims Table</h3>
            <p>Running database of all claims sent.</p>
          </div>
          <button onClick={toggleFilterModal}>Filter</button>
        <input
          type="text"
          placeholder="Search claims..."
          value={searchInput}
          onChange={handleSearchInputChange}
          className={styles.searchInput} // Add CSS as needed
        />
        <button className="delete" onClick={handleDownload}>
          <FontAwesomeIcon
            title={"Download selected in XLSX"}
            icon={faDownload}
          />
        </button>
        <table className={styles.claimsDataTable}>
          <thead>
            <tr>
              <th className={styles.claimsDataHeader}>Patient</th>
              <th className={styles.claimsDataHeader}>Insurance</th>
              <th className={styles.claimsDataHeader}>Status</th>
              <th className={styles.claimsDataHeader}>Charge Amount</th>
              <th className={styles.claimsDataHeader}>Billing Codes</th>
              <th className={styles.claimsDataHeader}>Diagnosis Codes</th>
              <th className={styles.claimsDataHeader}>Date of Service</th>
              <th className={styles.claimsDataHeader}>Provider</th>
              <th className={styles.claimsDataHeader}>Submitted</th>
            </tr>
          </thead>
          <tbody>
            {filteredClaimsData.map((claim, index) => {
              const sortedStatuses = sortStatusesByDate(claim.statuses);
              const latestStatus =
                sortedStatuses[0]?.mainStatus.statusCategoryCode || "N/A";
              const formattedTotalChargeAmount = `$${claim.totalChargeAmount.toFixed(
                2
              )}`;

              return (
                <React.Fragment key={index}>
                  <tr onClick={() => toggleSelectedClaim(claim.claimId)}>
                    <td>
                      {claim.firstName}{" "}
                      {claim.middleName ? `${claim.middleName} ` : ""}
                      {claim.lastName}
                      <br />
                      {claim.gender} | DOB: {formatDOB(claim.dateOfBirth)}
                    </td>
                    <td>
                      {claim.receiver}
                      <br />
                      {claim.memberId}
                    </td>
                    <td>
                      <ClaimStatusCategoryFlag
                        statusCategoryCode={latestStatus}
                      />
                    </td>
                    <td>{formattedTotalChargeAmount}</td>
                    <td>
                      {claim.serviceLines
                        .map((line) => line.professionalService?.procedureCode)
                        .join(", ")}
                    </td>
                    <td>
                      {claim.diagnosisCodes.join(", ")}
                    </td>
                    <td>
                      {formatDOB(claim.serviceLines[0]?.serviceDate || "")}
                    </td>
                    <td>{claim.provider}</td>
                    <td>{formatTimestamp(claim.timestamp)}</td>
                    <td>
                      <button
                        onClick={() => handleMoreInfoClick(claim.claimId)}
                        className="moreInfoButton" // Add suitable CSS class
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{ marginRight: "5px" }}
                        />
                        More Information
                      </button>
                    </td>
                  </tr>
                  {selectedClaimId === claim.claimId && (
                    <tr>
                      <td colSpan="6">
                        <table>
                          <thead>
                            <tr>
                              <th>Procedure Code</th>
                              <th>Diagnosis Codes</th>
                              <th>Amount Charged</th>
                              <th>Amount Paid</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                           
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClaimsTable;
