import React, { useState, useEffect } from "react";
import { collection, query, getDocs, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import useUID from "../../General/useUID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNotesMedical,
  faSyringe,
  faFlask,
  faPills,
  faHeartbeat,
  faTrash,
  faPeopleRoof,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../PatientView.module.css";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import Modal from "react-modal";

const functions = getFunctions();

const typeToIcon = {
  FamilyHistory: faPeopleRoof,
  ImmunizationsAllergies: faSyringe,
  LabAndTest: faFlask,
  Medications: faPills,
  Vitals: faHeartbeat,
  PatientHistory: faNotesMedical,
};

function PatientMedicalHistoryView({ patientId }) {
  const [medicalHistoryRecords, setMedicalHistoryRecords] = useState([]);
  const [expandedRecordId, setExpandedRecordId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uid] = useUID();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);

  useEffect(() => {
    if (!uid) return;
    setIsLoading(true);
    const q = query(collection(db, "patients", uid, "patientData", patientId, "medicalHistory"));

    const unsubscribe = onSnapshot(
      q,
      async (querySnapshot) => {
        const records = [];
        for (const doc of querySnapshot.docs) {
          const encryptedData = {
            ciphertext: doc.data().ciphertext,
            iv: doc.data().iv,
          };
          try {
            const decryptFunction = httpsCallable(functions, "decrypt");
            const decryptedResponse = await decryptFunction(encryptedData);
            const decryptedData = decryptedResponse.data;

            records.push({
              id: doc.id,
              ...decryptedData,
              timestamp: doc.data().timestamp?.toDate().toLocaleString(),
              type: doc.data().type,
            });
          } catch (error) {}
        }
        records.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
        setMedicalHistoryRecords(records);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching medical history: ", error);
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [patientId, uid]);

  // Helper function to convert Firestore field names
  const formatFieldName = (fieldName) => {
    const formattedName = {
      FamilyHistory: "Family History",
      ImmunizationsAllergies: "Immunizations & Allergies",
      LabAndTest: "Lab and Test Results",
      Medications: "Medications",
      Vitals: "Vitals",
      PatientHistory: "Patient History",
    };

    return formattedName[fieldName] || fieldName;
  };

  // Add the delete handler
  const handleDelete = async () => {
    if (!recordToDelete) return;
    try {
      await deleteDoc(
        doc(
          db,
          "patients",
          uid,
          "patientData",
          patientId,
          "medicalHistory",
          recordToDelete
        )
      );
      setMedicalHistoryRecords((prevRecords) =>
        prevRecords.filter((record) => record.id !== recordToDelete)
      );
      closeModal();
    } catch (error) {
      console.error("Error deleting medical history record: ", error);
    }
  };

  const toggleExpand = (recordId) => {
    setExpandedRecordId(expandedRecordId === recordId ? null : recordId);
  };

  const openModal = (recordId) => {
    setRecordToDelete(recordId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setRecordToDelete(null);
  };

  const familyMembers = [
    "Father",
    "Mother",
    "Siblings",
    "Children",
    "Grandparents",
    "UnclesAunts",
    "Cousins",
    "Other",
  ];

  // Card component
  const MedicalHistoryCard = ({ record }) => {
    // Determine the class name for the card color
    const cardColorClassName = `card-color-${record.type}`;

    return (
      <div
        className={`Card ${expandedRecordId === record.id ? "expanded" : ""} ${
          styles[cardColorClassName]
        }`}
        onClick={() => toggleExpand(record.id)}
      >
        <div className={styles.medicalHistoryHeader}>
          <FontAwesomeIcon
            icon={typeToIcon[record.type] || faNotesMedical}
            size="lg"
          />
          <p>{formatFieldName(record.type)}</p>
          <p>{record.timestamp}</p>
          <button
            className="delete"
            style={{ color: "white" }}
            onClick={(e) => {
              e.stopPropagation();
              openModal(record.id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
        {expandedRecordId === record.id && (
          <div className={styles.medicalHistoryDetails}>
            {record.type === "Vitals" && (
              <div className={styles.medicalHistoryDetails}>
                {record.bmi && (
                  <p>
                    <strong>BMI:</strong> {record.bmi}
                  </p>
                )}
                {record.bloodPressure && (
                  <p>
                    <strong>Blood Pressure:</strong> {record.bloodPressure}
                  </p>
                )}
                {record.heartRate && (
                  <p>
                    <strong>Heart Rate:</strong> {record.heartRate}
                  </p>
                )}
                {record.oxygenSaturation && (
                  <p>
                    <strong>Oxygen Saturation:</strong>{" "}
                    {record.oxygenSaturation}
                  </p>
                )}
                {record.respiratoryRate && (
                  <p>
                    <strong>Respiratory Rate:</strong> {record.respiratoryRate}
                  </p>
                )}
                {record.temperature && (
                  <p>
                    <strong>Temperature:</strong> {record.temperature}
                  </p>
                )}
                {record.weight && (
                  <p>
                    <strong>Weight:</strong> {record.weight}
                  </p>
                )}
              </div>
            )}
            {record.type === "LabAndTest" && (
              <>
                {record.testName && (
                  <p>
                    <strong>Test Name:</strong> {record.testName}
                  </p>
                )}
                {record.testDate && (
                  <p>
                    <strong>Test Date:</strong> {record.testDate}
                  </p>
                )}
                {record.testResults && (
                  <p>
                    <strong>Test Results:</strong> {record.testResults}
                  </p>
                )}
                {record.notes && (
                  <p>
                    <strong>Notes:</strong> {record.notes}
                  </p>
                )}
              </>
            )}
            {record.type === "Medications" && (
              <>
                {record.dosage && (
                  <p>
                    <strong>Dosage:</strong> {record.dosage}
                  </p>
                )}
                {record.frequency && (
                  <p>
                    <strong>Frequency:</strong> {record.frequency}
                  </p>
                )}
                {record.medicationName && (
                  <p>
                    <strong>Medication Name:</strong> {record.medicationName}
                  </p>
                )}
                {record.startDate && (
                  <p>
                    <strong>Start Date:</strong> {record.startDate}
                  </p>
                )}
                {record.endDate && (
                  <p>
                    <strong>End Date:</strong> {record.endDate}
                  </p>
                )}
                {record.notes && (
                  <p>
                    <strong>Notes:</strong> {record.notes}
                  </p>
                )}
              </>
            )}
            {record.type === "ImmunizationsAllergies" && (
              <>
                {record.immunizations && (
                  <p>
                    <strong>Immunizations:</strong> {record.immunizations}
                  </p>
                )}
                {record.allergies && (
                  <p>
                    <strong>Allergies:</strong> {record.allergies}
                  </p>
                )}
                {record.notes && (
                  <p>
                    <strong>Notes:</strong> {record.notes}
                  </p>
                )}
              </>
            )}
            {record.type === "FamilyHistory" &&
              familyMembers.map(
                (member) =>
                  record[member.toLowerCase()] && (
                    <p key={member}>
                      <strong>{member}:</strong>{" "}
                      {record[member.toLowerCase()].join(", ")}
                    </p>
                  )
              )}
            {record.type === "PatientHistory" && (
              <>
                {record.condition && (
                  <p>
                    <strong>Condition:</strong> {record.condition}
                  </p>
                )}
                {record.treatment && (
                  <p>
                    <strong>Treatment:</strong> {record.treatment}
                  </p>
                )}
                {record.treatmentStartDate && (
                  <p>
                    <strong>Treatment Start Date:</strong>{" "}
                    {record.treatmentStartDate}
                  </p>
                )}
                {record.treatmentEndDate && (
                  <p>
                    <strong>Treatment End Date:</strong>{" "}
                    {record.treatmentEndDate}
                  </p>
                )}
                {record.doctor && (
                  <p>
                    <strong>Doctor:</strong> {record.doctor}
                  </p>
                )}
                {record.notes && (
                  <p>
                    <strong>Notes:</strong> {record.notes}
                  </p>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {isLoading ? (
        <>
        <HexagonSpinner />
        <p>Decrypting...</p>
        </>
      ) : (
        medicalHistoryRecords.length > 0 && (
          <>
            {medicalHistoryRecords.map((record) => (
              <MedicalHistoryCard key={record.id} record={record} />
            ))}
          </>
        )
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Delete"
        className="confirmModal"
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this record?</p>
        <button onClick={handleDelete}>Yes</button>
        <button onClick={closeModal}>No</button>
      </Modal>
    </div>
  );
}

export default PatientMedicalHistoryView;
