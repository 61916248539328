import React, { useState, useEffect } from "react";
import { auth, db } from "../../../firebase";
import {
  collection,
  onSnapshot,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import ICD10 from "./ICD10";
import "../Claims.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import Modal from "react-modal";
import HelpArticleLink from "../../Articles/HelpArticleLink";
import useUID from '../../General/useUID'

function BillingCodesSelector(props) {
  const { onBillingCodeSelect, mode } = props;
  const [billingCodes, setBillingCodes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [billAmount, setBillAmount] = useState("0.00");
  const [modifierA, setModifierA] = useState("");
  const [modifierB, setModifierB] = useState("");
  const [diagnosisCode, setDiagnosisCode] = useState([]); // Updated state name to plural
  const [error, setError] = useState("");
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);
  const [editingCodeId, setEditingCodeId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState(null);
  const [uid, subUserUID] = useUID();

  // Initialize Firestore database reference
  const billingCodesRef = collection(db, `users/${uid}/billingCodes`);

  useEffect(() => {
    if (!uid) return;

    async function getBillingCodes() {
      setIsLoading(true);
      // Check if billing codes collection exists, create if not
      const querySnapshot = await getDocs(billingCodesRef);
      if (querySnapshot.empty) {
        await addDoc(billingCodesRef, {});
      }

      // Load billing codes from database on mount
      const unsubscribe = onSnapshot(billingCodesRef, (querySnapshot) => {
        const billingCodesData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          billingCodesData.push({
            id: doc.id,
            name: data.name,
            code: data.code,
            diagnosisCode: data.diagnosisCode || [], // Ensure diagnosisCodes is always an array
            billAmount: data.billAmount,
            modifierA: data.modifierA,
            modifierB: data.modifierB,
          });
        });
        setBillingCodes(billingCodesData);
        setIsLoading(false);
      });
      return unsubscribe;
    }

    getBillingCodes();
  }, [uid]);

  // Handle form submission to add new billing code
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    // Validate all required fields
    if (!name) {
      setError("Name is required.");
      return;
    }
    if (diagnosisCode.length === 0) {
      setError("At least one diagnosis code must be selected.");
      return;
    }
    if (!code) {
      setError("Billing code is required.");
      return;
    }
    if (!billAmount || isNaN(billAmount)) {
      setError("Valid charge amount is required.");
      return;
    }

    // Convert the bill amount to cents
    const billAmountInDollars = parseFloat(billAmount);

    // Convert diagnosis code objects to an array of strings
    const diagnosisCodeStrings = diagnosisCode.map((codeObj) => codeObj.code);

    // Add new billing code to Firestore
    await addDoc(billingCodesRef, {
      name,
      code,
      diagnosisCode: diagnosisCodeStrings, // Store as array of strings
      billAmount: billAmountInDollars,
      modifierA,
      modifierB,
    });

    // Reset form fields
    setName("");
    setCode("");
    setDiagnosisCode([]); // Updated state name to plural
    setBillAmount("0.00");
    setModifierA("");
    setModifierB("");
    setShowPopup(false);
  };

  const handleDeleteRequest = (e, billingCodeId) => {
    e.stopPropagation(); // Prevent row selection when clicking delete button
    setDeleteCandidateId(billingCodeId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDelete = async () => {
    await deleteDoc(doc(billingCodesRef, deleteCandidateId));

    // Update billing codes state to remove deleted billing code
    const updatedBillingCodes = billingCodes.filter(
      (billingCode) => billingCode.id !== deleteCandidateId
    );
    setBillingCodes(updatedBillingCodes);
    setShowDeleteConfirmModal(false);
  };

  // Handle selection of existing billing code
  const handleSelect = (billingCodeId) => {
    // Find the selected billing code from the billing codes array
    const billingCode = billingCodes.find(
      (billingCode) => billingCode.id === billingCodeId
    );

    // Check if the selected billing code is already in the array
    const alreadySelected = selectedBillingCodes.some(
      (selectedCode) => selectedCode.id === billingCodeId
    );

    // Update the selected billing codes state
    setSelectedBillingCodes((prevState) => {
      let updatedState;
      if (!alreadySelected && prevState.length < 5) {
        updatedState = [
          ...prevState,
          {
            id: billingCodeId,
            name: billingCode.name,
            code: billingCode.code,
            diagnosisCode: billingCode.diagnosisCode || [],
            billAmount: billingCode.billAmount,
            modifierA: billingCode.modifierA,
            modifierB: billingCode.modifierB,
          },
        ];
      } else if (alreadySelected) {
        updatedState = prevState.filter(
          (selectedCode) => selectedCode.id !== billingCodeId
        );
      } else {
        updatedState = prevState;
      }

      // Call the parent component's function with the array of updated selected billing codes
      onBillingCodeSelect(updatedState);

      return updatedState;
    });
  };

  const resetFormFields = () => {
    setName("");
    setCode("");
    setDiagnosisCode([]); // Updated state name to plural
    setBillAmount(0);
    setModifierA("");
    setModifierB("");
  };

  const handleDiagnosisCodeSelect = (selectedCodes) => {
    setDiagnosisCode(selectedCodes); // Store the selected diagnosis codes
  };

  const startEdit = (billingCode) => {
    setName(billingCode.name);
    setCode(billingCode.code);
    setDiagnosisCode(billingCode.diagnosisCode || []); // Updated state name to plural
    setBillAmount(billingCode.billAmount);
    setModifierA(billingCode.modifierA);
    setModifierB(billingCode.modifierB);
    setEditingCodeId(billingCode.id);
    setShowPopup(true);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setError("");

    // Validate all required fields
    if (!name) {
      setError("Name is required.");
      return;
    }
    if (diagnosisCode.length === 0) {
      setError("At least one diagnosis code must be selected.");
      return;
    }
    if (!code) {
      setError("Billing code is required.");
      return;
    }
    if (!billAmount || isNaN(billAmount)) {
      setError("Valid charge amount is required.");
      return;
    }

    const billAmountInDollars = parseFloat(billAmount);

    // Convert diagnosis code objects to an array of strings
    const diagnosisCodeStrings = diagnosisCode.map((codeObj) => codeObj.code);

    await setDoc(doc(billingCodesRef, editingCodeId), {
      name,
      code,
      diagnosisCode: diagnosisCodeStrings, // Store as array of strings
      billAmount: billAmountInDollars,
      modifierA,
      modifierB,
    });
    resetFormFields();
    setShowPopup(false);
    setEditingCodeId(null);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (editingCodeId) {
      handleUpdate(event);
    } else {
      handleSubmit(event);
    }
  };

  return (
    <div className="billing-codes-selector-container">
      <div className="billing-codes-header">
        {mode !== "encounters" && <h3 className="billing-codes-label">Billing Codes</h3>}
        <button
          className="add-billing-code-button"
          onClick={() => setShowPopup(true)}
        >
          <FontAwesomeIcon className="m-0" icon={faPlus} /> Add Billing Code
        </button>
      </div>
      {showPopup && (
        <div className="add-billing-code-popup">
          <div className="billingInput">
            <div className="input-group-row">
              <div className="input-field">
                <label style={{ color: "#ffffff" }} htmlFor="name">
                  Name of Template:
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value.toUpperCase())}
                  placeholder="eg. Annual Wellness Visit"
                  required
                />
              </div>
            </div>
            <div className="input-group-row">
              <div className="input-field">
                <label style={{ color: "#ffffff" }} htmlFor="name">
                  Diagnosis Code Search:
                </label>
              </div>
            </div>

            <ICD10 onCodeSelect={handleDiagnosisCodeSelect} initialSelectedCodes={diagnosisCode} />

            <div className="input-group-row">
              <div className="input-field">
                <label style={{ color: "#ffffff" }} htmlFor="name">
                  Billing Code:
                </label>
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="eg. G0438"
                  required
                />
              </div>

              <div className="input-field">
                <label style={{ color: "#ffffff" }} htmlFor="name">
                  Charge Amount:
                </label>
                <input
                  type="text"
                  value={billAmount}
                  placeholder="eg. $100"
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                    setBillAmount(value);
                  }}
                  required
                />
              </div>
            </div>

            <div className="input-group-row">
              <div className="input-field">
                <label style={{ color: "#ffffff" }} htmlFor="Modifier a">
                  Modifier A:
                </label>
                <input
                  type="text"
                  value={modifierA}
                  onChange={(e) => setModifierA(e.target.value)}
                  placeholder="Modifier A:"
                />
              </div>
              <div className="input-field">
                <label style={{ color: "#ffffff" }} htmlFor="Modifier B">
                  Modifier B:
                </label>
                <input
                  type="text"
                  value={modifierB}
                  onChange={(e) => setModifierB(e.target.value)}
                  placeholder="Modifier B:"
                />
              </div>
            </div>
            <button onClick={handleFormSubmit}>
              {editingCodeId ? "Update Billing Code" : "Add Billing Code"}
            </button>

            {error && <div className="error">{error}</div>}
            <button
              onClick={() => {
                resetFormFields();
                setShowPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {!showPopup && (
        <div className="billingCodesContainer">
          {isLoading ? (
            <HexagonSpinner /> // Show spinner while loading
          ) : billingCodes.length > 0 ? (
            billingCodes.map((billingCode) => (
              <div
                key={billingCode.id}
                className={`w-90 h-250px content-center py-4 px-2 ${selectedBillingCodes.some(
                  (code) => code.id === billingCode.id
                )
                  ? "blue-card card-selected"
                  : "white-card"
                  }`}
                onClick={() => handleSelect(billingCode.id)}
              >
                <h4>{billingCode.name}</h4>

                <p>
                  Diagnosis Code:
                  <strong>
                    {billingCode.diagnosisCode.map((diag, index) => (
                      <span key={index}>{diag}</span>
                    ))}
                  </strong>
                </p>
                <p>
                  Billing Code:<strong>{billingCode.code}</strong>
                </p>

                {mode !== "encounters" && (
                  <p>
                    Bill Amount:<strong>$
                      {billingCode.billAmount
                        ? parseFloat(billingCode.billAmount).toFixed(2)
                        : "0.00"}
                    </strong>
                  </p>
                )}

                {/* Conditionally render Modifier A */}
                {billingCode.modifierA && (
                  <p>
                    Modifier A:<strong>{billingCode.modifierA}</strong>
                  </p>
                )}

                {/* Conditionally render Modifier B */}
                {billingCode.modifierB && (
                  <p>
                    Modifier B: <strong>{billingCode.modifierB}</strong>
                  </p>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <button
                    className={selectedBillingCodes.some(
                      (code) => code.id === billingCode.id
                    )
                      ? "primary"
                      : "secondary"
                    }
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the card's click event
                      startEdit(billingCode);
                    }}
                    title={"Edit codes"}
                  >
                    <FontAwesomeIcon className="m-0" icon={faEdit} />
                  </button>

                  <button
                    className={selectedBillingCodes.some(
                      (code) => code.id === billingCode.id
                    )
                      ? "primary"
                      : "secondary"
                    }
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the card's click event
                      handleDeleteRequest(e, billingCode.id); // Open the confirmation modal
                    }}
                    title={"Delete code"}
                  >
                    <FontAwesomeIcon className="m-0" icon={faTrash} />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="no-billing-codes-message">
              <h4>You don't have any codes, please add your billing codes.</h4>
            </div>
          )}
        </div>
      )}
      <Modal
        isOpen={showDeleteConfirmModal}
        onRequestClose={() => setShowDeleteConfirmModal(false)}
        className="confirmModal"
      >
        <h2>Delete Billing Code?</h2>
        <div>
          <button onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </button>
          <button onClick={confirmDelete}>Delete</button>
        </div>
      </Modal>
      {mode !== "encounters" && (
        <div className="help-article-wide">
          <HelpArticleLink
            article={{
              title: "Adding Billing Codes",
              link: "https://popularishealth.com/article/Adding-Billing-Codes",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default BillingCodesSelector;
