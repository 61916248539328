import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedical, faUserCheck, faFileAlt } from '@fortawesome/free-solid-svg-icons';

function PricingTable({ showPaymentForm }) {
  const plans = [
    {
      name: 'Freebie',
      description: 'Understand why we are the ultimate healthcare app.',
      buttonText: 'Get Started',
      price: 'Free',
      features: ['25 free messages', 'add up to 2 Providers', 'All of the patients tools']
    },
    {
      name: 'Professional',
      description: 'Take advantage of our powerful tools.',
      buttonText: 'Get Started',
      price: '$199/month',
      features: ['$0.40c per message', 'Join our IPA', 'add up to 3 Providers']
    },
    {
      name: 'Enterprise',
      description: 'Take your healthcare management to the next level.',
      buttonText: 'Get Started',
      price: '$1,499/month',
      features: ['$0.35c per message', 'Unlimited Providers and Users', 'Access to APIs']
    },
  ];


  return (
    <div className="pricing-table">
      <h1 style={{ marginTop: "2rem" }}>Which plan is right for you?</h1>
      <div className="plan-container">
        {plans.map((plan, index) => (
          <div className="plan-card" key={index}>
            <h2>{plan.name}</h2>
            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <div className='price-container'>
              <span className="price">{plan.price.split('/')[0]}</span>
              {plan.price.split('/')[1] && (
                <span className="month">/{plan.price.split('/')[1]}</span>
              )}
            </div>
            <button
              onClick={() =>
                showPaymentForm({
                  name: plan.name,
                  details: plan.features,  // Passing the features of the plan directly
                  price: plan.price,
                })
              }
            >
              {plan.buttonText}
            </button>

          </div>
        ))}
      </div>
      <p style={{color: '#010101'}}>* A message is an eligibility request, claim sent, or claim status checked.
    </p>
    </div >
  );
}

export default PricingTable;
