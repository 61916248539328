import React, { useState } from "react";
import "./styles/ai.modules.css";
import TeachingDocuments from "./teaching/teachingDocuments";
import Chat from "./chat";

function Teachingai() {
  const [password, setPassword] = useState("");
  const correctPassword = "6665";  // Store the correct password

  return (
    <div className="aibackground">
      {password === correctPassword ? (
        <>
        
          <div className="chat">
            <Chat />
          </div>
          <div className="sidebarContainer">
          <div>          
            <p>Uploading documents to here will propegate the data to all users of the system. Be careful what is uploaded as this is a process that connot be undone.</p>
        </div>
            <TeachingDocuments />
          </div>
        </>
      ) : (
        <div className="passwordPrompt">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
        </div>
      )}
    </div>
  );
}

export default Teachingai;
