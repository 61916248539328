import { useState, useEffect } from 'react';
import { auth, db } from "../../firebase"; // Adjust import paths as necessary
import { doc, getDoc } from "firebase/firestore";
import useUID from './useUID';

const useEmailVerified = () => {
    const [isEmailVerified, setIsEmailVerified] = useState(null);
    const [uid] = useUID(); // Assuming useUID returns an array with uid as the first element

    useEffect(() => {
        const checkEmailVerified = async () => {
            if (uid) {
                // Fetch the user's document from Firestore to check the emailVerified field
                const userDocRef = doc(db, "users", uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    // Update state based on the emailVerified field in Firestore
                    setIsEmailVerified(userDoc.data().emailVerified);
                } else {
                    // Handle the case where the user document does not exist
                    setIsEmailVerified(false); // Example fallback behavior
                }
            } else {
                setIsEmailVerified(null); // Handle as needed if no user is logged in
            }
        };

        checkEmailVerified();
    }, [uid]); // Include uid in the dependency array to re-run the effect when uid changes

    return isEmailVerified;
};

export default useEmailVerified;
