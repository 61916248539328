import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import privilegeStatuses from '../components/PrivilegeStatuses';
import { db } from "../../../firebase";
import useUID from '../../General/useUID';
import { doc, getDocs, setDoc, collection, addDoc, updateDoc, deleteDoc, onSnapshot } from "firebase/firestore";
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';


const states = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

function StepSeven({ onNext, prevStep, setCanProceed  }) {
  const [uid] = useUID();
  const [addingNewHospital, setAddingNewHospital] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [newHospitalStatus, setNewHospitalStatus] = useState('');
  const [isAddingNewHospital, setIsAddingNewHospital] = useState(false);
  const [selectedPrivilegeStatus, setSelectedPrivilegeStatus] = useState(null);

  // Validation check for hospital details
  const validateHospitalDetails = () => {
    return hospitals.every(hospital => {
      return hospital.name && hospital.years && hospital.address1 && hospital.city && hospital.state && hospital.zip;
    });
  };

  useEffect(() => {
    // Call setCanProceed based on whether all hospitals pass the validation
    setCanProceed(validateHospitalDetails());
  }, [hospitals, setCanProceed]);
  
    useEffect(() => {
      if (!uid) return;
  
      const unsubscribe = onSnapshot(
        collection(db, `users/${uid}/applications/ipaApplication/hospitals`),
        (snapshot) => {
          const hospitalsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setHospitals(hospitalsData);
          setIsAddingNewHospital(hospitalsData.length === 0);
        },
        (err) => {
          console.error("Failed to fetch hospitals:", err);
        }
      );
  
      // Cleanup subscription on component unmount
      return () => unsubscribe();
    }, [uid]);
  
    const handleHospitalChange = async (index, field, value) => {
      const updatedHospitals = [...hospitals];
      const hospitalToUpdate = updatedHospitals[index];
  
      // Update the local state
      hospitalToUpdate[field] = value;
      setHospitals(updatedHospitals);
  
      // Update Firestore document
      const hospitalRef = doc(
        db,
        `users/${uid}/applications/ipaApplication/hospitals`,
        hospitalToUpdate.id
      );
      try {
        await updateDoc(hospitalRef, { [field]: value });
      } catch (error) {
        console.error("Error updating hospital:", error);
      }
    };
  
    const handlePrivilegeChange = async (status) => {
      setIsAddingNewHospital(true);
      addHospitalWithStatus(status);
    };
  
    const addHospitalWithStatus = async (status) => {
      if (!status) {
        console.error("Privilege status is undefined, cannot save hospital.");
        return;
      }
  
      const nextHospitalNumber = hospitals.length + 1;
      const hospitalId = `hospital-${nextHospitalNumber}`;
  
      try {
        const hospitalRef = doc(
          db,
          `users/${uid}/applications/ipaApplication/hospitals`,
          hospitalId
        );
        await setDoc(hospitalRef, {
          status: status,
          step: "enterAddress",
          // Add additional fields as necessary
        });

  
        // Reset the UI and prepare for potentially adding another hospital
        setIsAddingNewHospital(false);
  
        // Fetch hospitals again to update the UI with the new addition
        // You might need to adjust or call the function that fetches hospitals again here
      } catch (error) {
        console.error("Error adding new hospital:", error);
      }
    };
  


  const deleteHospital = async (index) => {
    if (index === 0 && hospitals.length === 1) {
      alert("Cannot delete the primary hospitals.");
      return;
    }

    let updatedHospitals = [...hospitals];
    let hospitalToDelete = updatedHospitals.splice(index, 1)[0]; // Remove the location from local state
    setHospitals(updatedHospitals);

    if (hospitalToDelete.id) {
      // If the location has an ID, delete it from Firestore
      const hospitalRef = doc(
        db,
        `users/${uid}/applications/ipaApplication/hospitals`,
        hospitalToDelete.id
      );
      await deleteDoc(hospitalRef);
    }
  };


  // Placeholder for posDescription function - implement as needed
  const posDescription = posType => posType;

  const findIconForStatus = (status) => {
    const statusObject = privilegeStatuses.find(ps => ps.status === status);
    return statusObject ? statusObject.icon : null; // Returns the icon based on status or null if not found
  };

  useEffect(() => {
    window.scrollTo(0, 0);
}, [addingNewHospital]);

return (
  <div className="provider-type-selection-form">
    <h2 style={{ textAlign: "left" }}>Please list your hospital privileges.</h2>
    {!isAddingNewHospital &&
      hospitals.map((hospital, index) => (
        <div className='location-containers' key={index}>
         {hospital.status && (
            <FontAwesomeIcon icon={findIconForStatus(hospital.status)} style={{ height: "40px" }} />
          )}
          <h3>Hospital {index + 1}: {hospital.status && posDescription(hospital.status)}</h3>
          
            {hospital.step === 'enterAddress' && (
            <div style={{textAlign: "left"}}>
              <div className="input-group-row">
                    <div className="input-field">
                      <label htmlFor="hospital-name">Hospital Name:</label>
                      <input
                        id="hospital-name"
                        type="text"
                        name="hospitalName"
                        value={hospital.name} 
                        onChange={(e) => handleHospitalChange(index, "name", e.target.value)} 
                        />
                    </div>
                    <div className="input-field">
                      <label htmlFor="years-admitted">Years Admitted:</label>
                      <input
                        id="years-admitted"
                        type="text"
                        name="yearsAdmitted"
                        value={hospital.years} 
                        onChange={(e) => handleHospitalChange(index, "years", e.target.value)} 
                        />
                    </div>
                  </div>
            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor={`address1-${index}`}>Address Line 1:</label>
                <input 
                  id={`address1-${index}`} 
                  type="text" 
                  name="address1" 
                  value={hospital.address1} 
                  onChange={(e) => handleHospitalChange(index, "address1", e.target.value)} 
                  />
              </div>
              <div className="input-field">
                <label htmlFor={`address2-${index}`}>Address Line 2 (optional):</label>
                <input 
                  id={`address2-${index}`} 
                  type="text" 
                  name="address2" 
                  value={hospital.address2} 
                  onChange={(e) => handleHospitalChange(index, "address2", e.target.value)} 
                />
              </div>
            </div>
            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor={`city-${index}`}>City:</label>
                <input 
                  id={`city-${index}`} 
                  type="text" 
                  name="city" 
                  value={hospital.city} 
                  onChange={(e) => handleHospitalChange(index, "city", e.target.value)} 
                />
              </div>
              <div className="input-field">
                <label htmlFor={`state-${index}`}>State:</label>
                <select 
                  id={`state-${index}`} 
                  name="state" 
                  value={hospital.state} 
                  onChange={(e) => handleHospitalChange(index, "state", e.target.value)} 
                >
                  <option value="">Select a State</option>
                  {states.map(s => <option key={s} value={s}>{s}</option>)}
                </select>
              </div>
              <div className="input-field">
                <label htmlFor={`zip-${index}`}>Zip:</label>
                <input 
                  id={`zip-${index}`} 
                  type="text" 
                  name="zip" 
                  maxLength={"5"}
                  pattern={"^\\d{5}$"}
                  value={hospital.zip} 
                  onChange={(e) => handleHospitalChange(index, "zip", e.target.value)} 
                />
              </div>
            </div>
            {index !== 0 && ( // Only show the delete button for non-primary locations
                <button onClick={() => deleteHospital(index)} className="delete">
                     <FontAwesomeIcon icon={faTrashAlt} />
                </button>
            )}
          </div>
        )}
      </div>
    ))}
  
  {isAddingNewHospital && (
        <div>
          <h4>Select Privilege Status for the New Location</h4>
          <div className="provider-type-cards-container">
            {privilegeStatuses.map(({ status, icon, description }) => (
              <div
                key={status}
                className={`provider-card ${selectedPrivilegeStatus === status ? 'selected' : ''}`}
                onClick={() => {
                  handlePrivilegeChange(status);
                  addHospitalWithStatus();
                }}
              >
                <FontAwesomeIcon icon={icon} style={{ height: "40px" }} />
                <h3>{description}</h3>
              </div>
            ))}
          </div>
        </div>
      )}

      {!isAddingNewHospital && (
        <button onClick={() => setIsAddingNewHospital(true)}>
          {hospitals.length > 1 || (hospitals.length === 1 && hospitals[0].status)
            ? 'Add Another Hospital'
            : 'Add First Hospital Privilege'}
        </button>
      )}
  </div>
);

}

export default StepSeven;


