import React, { useState } from "react";
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase";
import useUID from "../../General/useUID";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";

const functions = getFunctions();
const encryptFunction = httpsCallable(functions, "encrypt");

function ImmunizationsAllergies({ patientId, onClose }) {
  const [uid, subUserUID, error] = useUID();
  const [immunizationsAllergies, setImmunizationsAllergies] = useState({
    immunizations: "",
    allergies: "",
    notes: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setImmunizationsAllergies({
      ...immunizationsAllergies,
      [e.target.name]: e.target.value,
    });
  };

  const isFormEmpty = () => {
    return (
      !immunizationsAllergies.immunizations && !immunizationsAllergies.allergies
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      alert("Please fill in the immunizations or allergies field.");
      return;
    }

    setLoading(true);

    try {
      // Encrypt the data before sending it to Firestore
      const encryptedResponse = await encryptFunction(immunizationsAllergies);
      const encryptedData = encryptedResponse.data; // Assuming this is the structure of your encrypted data

      const medicalHistoryRef = collection(
        doc(db, "patients", uid, "patientData", patientId),
        "medicalHistory"
      );

      await addDoc(medicalHistoryRef, {
        type: "ImmunizationsAllergies",
        timestamp: serverTimestamp(),
        ciphertext: encryptedData.ciphertext, // Store the encrypted ciphertext
        iv: encryptedData.iv, // Store the IV needed for decryption
      });

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        activity: "Medical history added",
        activityType: "medicalHistory",
        uid: uid,
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      setImmunizationsAllergies({
        immunizations: "",
        allergies: "",
        notes: "",
      });
      onClose();
    } catch (error) {
      console.error("Error during encryption or adding document: ", error);
    } finally {
      setLoading(false); // Stop loading after the async operation
    }
  };

  return (
    <div className={styles.immunizationsAllergiesForm}>
      <form onSubmit={handleSubmit}>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="immunizations">Immunizations:</label>
            <textarea
              id="immunizations"
              name="immunizations"
              value={immunizationsAllergies.immunizations}
              onChange={handleChange}
              placeholder="List all immunizations with dates if possible"
            />
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="allergies">Allergies:</label>
            <textarea
              id="allergies"
              name="allergies"
              value={immunizationsAllergies.allergies}
              onChange={handleChange}
              placeholder="List all known allergies"
            />
          </div>
        </div>

        {loading ? (
          <HexagonSpinner />
        ) : (
          <button
            type="submit"
            className={styles.submitButton}
            disabled={loading}
          >
            Submit Immunizations & Allergies
          </button>
        )}
      </form>
    </div>
  );
}

export default ImmunizationsAllergies;
