import React, { useRef, useState, useEffect } from "react";
import styles from "./PatientView.module.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faHeart,
  faFileCircleCheck,
  faFileInvoiceDollar,
  faNotesMedical,
  faFileMedical,
  faStaffSnake,
  faBoltLightning,
  faStickyNote,
  faFileUpload,
  faHeadset,
  faPhone,
  faMobile,
  faVideo,
  faMoneyCheckDollar,
  faMagnifyingGlassDollar,
  faPrescription,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import {
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import PatientFilesEligibility from "./Billing/PatientFilesEligibility";
import PatientFilesClaimStatus from "./Billing/PatientFilesClaimStatus";
import PatientFilesClaimsSend from "./Billing/PatientFilesClaim";
import Zoom from "./Zoom/Zoom";
import Notes from "./Notes/Notes";
import Files from "./Files";
import EditPatientPage from "./EditPatientPage";
import PatientDataDownload from "./Download/PatientDataDownload";
import Encounters from "./Encounters/Encounters";
import EncountersView from "./Encounters/EncountersView";
import useUID from "../General/useUID";
import ClaimsView from "./Billing/ClaimsView";
import EligibilityCards from "./Billing/EligibilityView";
import NotesView from "./Notes/NotesView";
import MedicalHistory from "./MedicalHistory/MedicalHistory";
import PatientFlag from "./PatientFlags";
import { useNavigate, useParams } from "react-router-dom";
import InstantEligibility from "./Billing/InstantEligibility";
import PatientFileTimer from "./PatientFileTimer";
import { getFunctions, httpsCallable } from "firebase/functions";
import { SHA256 } from "crypto-js";
import IntakeView from "./Clinics/Intake/IntakeView";
import ClinicNameFetcher from "./ClinicNameFetcher";

function PatientView({ patientSelected, onDataUpdated, resetView }) {
  const [openSections, setOpenSections] = useState([]);
  const [isEditing, setisEditing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editedPatientData, setEditedPatientData] = useState(null);
  const [showPatientFilesEligibility, setShowPatientFilesEligibility] =
    useState(false);
  const [showPatientFilesClaimStatus, setShowPatientFilesClaimStatus] =
    useState(false);
  const [showPatientFilesClaims, setShowPatientFilesClaims] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [showEncounters, setShowEncounters] = useState(false);
  const [hasEncounters, setHasEncounters] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [uid, subUserUID] = useUID();
  const { PatientId } = useParams();
  const navigate = useNavigate();
  const functions = getFunctions();
  
  useEffect(() => {
    // Reset all sections when the resetView flag changes
    setShowPatientFilesEligibility(false);
    setShowPatientFilesClaimStatus(false);
    setShowPatientFilesClaims(false);
    setShowZoom(false);
    setShowNotes(false);
    setShowFiles(false);
    setShowEncounters(false);
    setShowTools(false);
  }, [resetView]);

  
  if (!patientSelected) {
    return <div></div>;
  }

  const patientData = patientSelected.data;
  const { patient } = patientData;
  const firstName = patient ? patient.firstName : patientData.subfirstName;
  const middleName = patient ? patient.middleName : patientData.submiddleName;
  const lastName = patient ? patient.lastName : patientData.sublastName;
  const memberid =
    patientData.payers?.memberId || patientData.eligibility?.submemberId;
  const address1 =
    patient?.address?.address1 || patientData.eligibility?.subAddress1;
  const address2 =
    patient?.address?.address2 || patientData.eligibility?.subAddress2;
  const city = patient?.address?.city || patientData.eligibility?.subCity;
  const state = patient?.address?.state || patientData.eligibility?.subState;
  const zip = patient?.address?.zip;
  const email = patient?.email;
  const phone = patient?.phone;
  const tradingPartnerName = patientData.payers?.name;
  const dob =(patient.dob);
  const gender = patient.gender
  const flag = patientData?.flag;
  const isDeleted = patientData?.deleted === true;
  

  const handleEligibility = () => {
    setShowPatientFilesEligibility(!showPatientFilesEligibility);
  };

  const handleClaimStatus = () => {
    setShowPatientFilesClaimStatus(!showPatientFilesClaimStatus);
  };

  const handleSendClaim = () => {
    setShowPatientFilesClaims(!showPatientFilesClaims);
  };

  const handleZoom = () => {
    setShowZoom(!showZoom);
  };

  const handleNotes = () => {
    setShowNotes(!showNotes);
  };
  const handleFiles = () => {
    setShowFiles(!showFiles);
  };

  function hashDocIdToNumber(docId) {
    const hash = SHA256(docId).toString(); // This gives you the hash as a hex string
    const largeNumber = parseInt(hash.slice(0, 15), 16); // Convert the first 15 characters to an integer
    return largeNumber % 1000000000; // Use modulo to limit it to 9 digits
  }
  const patientMRN = hashDocIdToNumber(patientSelected.id);

  const handleEncounters = () => {
    // Check if either address1 or address2 is present
    if (!address1 && !address2) {
      alert("No address found. Cannot use encounters for patients with no address.");
      setShowEncounters(false); // Ensure encounters don't open
    } else {
      setShowEncounters(!showEncounters); // Toggle visibility of encounters if address is present
    }
  };
  

  function formatGender(gender) {
    if (!gender) {
      return "";
    }

    const genderLower = gender.toLowerCase();
    if (genderLower === "m" || genderLower === "male") {
      return "Male";
    } else if (genderLower === "f" || genderLower === "female") {
      return "Female";
    } else {
      return "";
    }
  }

  //autistics capitalization function
  function capitalizeName(name) {
    if (!name) {
      return "";
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  const handleShowEditPage = () => {
    if (isEditing) {
      setisEditing(false);
    } else {
      setisEditing(true);
    }
  };

  const handleCloseEditPage = () => {
    setisEditing(false);
  };

  const handleDelete = () => {
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    // Mark the patient as "deleted" in Firestore
    const patientRef = doc(
      db,
      "patients",
      uid,
      "patientData",
      patientSelected.id
    );

    // Update the "deleted" field of the document
    const test = await updateDoc(patientRef, {
      deleted: true,
    });

    // Close the confirmation modal
    setShowConfirmModal(false);
    setisEditing(false);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleToggleTools = () => {
    setShowTools(!showTools);
  };

  const handleSavePatientData = async () => {
    const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
    try {
      const docSnap = await getDoc(patientRef);
      if (docSnap.exists()) {
        const encryptedData = docSnap.data();
  
        // Decrypt data
        const decryptFunction = httpsCallable(functions, "decrypt");
        const decryptedResult = await decryptFunction(encryptedData);
        const decryptedData = decryptedResult.data;
    
        return decryptedData; // Return decrypted data for further use, such as populating a form
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  
  const handleRecoverPatient = async () => {
    const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
    try {
      // Retrieve the encrypted patient data
      const docSnap = await getDoc(patientRef);
      if (docSnap.exists()) {
        const encryptedData = docSnap.data();
  
        const decryptFunction = httpsCallable(functions, "decrypt");
        const decryptedResult = await decryptFunction(encryptedData);
        const decryptedData = decryptedResult.data;
  
        // Proceed with the recovery process
        await updateDoc(patientRef, {
          ...decryptedData,
          deleted: false,
        });
  
      } else {
      }
    } catch (error) {
      console.error("Error recovering patient:");
    }
  };
  

  return (
    <div className={styles.contentContainer}>
      {showPatientFilesEligibility && (
        <div>
          <PatientFilesEligibility
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesEligibility(false)}
          />
        </div>
      )}
      {showPatientFilesClaimStatus && (
        <div>
          <PatientFilesClaimStatus
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesClaimStatus(false)}
          />
        </div>
      )}
      {showPatientFilesClaims && (
        <div>
          <PatientFilesClaimsSend
            patientId={patientSelected.id}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dob={dob}
            gender={gender}
            memberid={memberid}
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zip={zip}
            tradingPartnerName={tradingPartnerName}
            onClose={() => setShowPatientFilesClaims(false)}
          />
        </div>
      )}
      {showZoom && (
        <div className="overlay">
          <div className="modalBox">
            <Zoom
              email={email}
              phone={phone}
              patientId={patientSelected.id}
              onClose={() => setShowZoom(false)}
            />
          </div>
        </div>
      )}
      {showNotes && (
        <div className="overlay">
          <div className="modalBox">
            <Notes
              patientId={patientSelected.id}
              onClose={() => setShowNotes(false)}
            />
          </div>
        </div>
      )}
      {showFiles && (
        <div className="overlay">
          <div className="modalBox">
            <Files
              patientId={patientSelected.id}
              onClose={() => setShowFiles(false)}
            />
          </div>
        </div>
      )}
      {showEncounters && (
        <Encounters
          patientId={patientSelected.id}
          patientData={patientData}
          onClose={() => setShowEncounters(false)}
        />
      )}

      <Modal
        isOpen={showConfirmModal}
        onRequestClose={cancelDelete}
        className={styles.confirmModal}
        overlayClassName={styles.confirmModalOverlay}
      >
        <h2>
          Delete "{firstName} {lastName}"?
        </h2>
        <p>Are you sure you want to delete the selected patient?</p>
        <div className={styles.confirmButtons}>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </div>
      </Modal>
      <div className={styles.topBar}>
        {isDeleted ? (
          <span className={styles.editText} onClick={handleRecoverPatient}>
            Recover
          </span>
        ) : (
          <span className={styles.editText} onClick={handleShowEditPage}>
            Edit
          </span>
        )}
        <PatientFlag
          patientSelected={patientSelected}
          onDataUpdated={onDataUpdated}
        />
        <PatientDataDownload patientSelected={patientSelected} />
        <PatientFileTimer patientSelected={patientSelected} />
      </div>

      {isEditing && (
        <EditPatientPage
          patientId={patientSelected.id}
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          dob={dob}
          gender={gender}
          memberid={memberid}
          address1={address1}
          address2={address2}
          city={city}
          state={state}
          zip={zip}
          tradingPartnerName={tradingPartnerName}
          patientData={editedPatientData || patientData}
          handleSave={handleSavePatientData}
          handleShowEditPage={handleShowEditPage}
          handleDelete={handleDelete}
          patientSelected={patientSelected}
          onClose={handleCloseEditPage}
          onDataUpdated={onDataUpdated}
        />
      )}

      <div className="patientDataContainer">
        <div className="titleBox">
          <h2 className="patientDataTitle">
            <span className="initialsCircleContainer">
              <span className="initialsCircle">
                {capitalizeName(firstName).charAt(0)}
                {capitalizeName(lastName).charAt(0)}
              </span>
              <span className={styles.name}>
                {capitalizeName(firstName)} {middleName && capitalizeName(middleName)} {capitalizeName(lastName)}
              </span>

            </span>
            <br />
            {dob && (
              <div className="dobandgender">
                <span style={{ fontSize: "24px", fontWeight: "lighter" }}>
                  {dob}
                </span>
                {gender && (
                  <span
                    style={{
                      marginLeft: "50px",
                      fontSize: "24px",
                      fontWeight: "lighter",
                    }}
                  >
                    {formatGender(gender)}
                  </span>
                )}
              </div>
            )}
          </h2>
        </div>
        <div className="actions">
          <div className="firstRow">
            <div className="action-button-container">
              {/* 
              <button onClick={handleToggleContact}>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faPrescription} size="lg" style={{ height: '30px' }} />
                </div>
                <div className="text-container">
                  Prescription
                </div>
              </button> */}

              <button onClick={handleEncounters}>
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faStaffSnake}
                    size="lg"
                    style={{ height: "30px" }}
                  />
                </div>
                <div className="text-container">Encounter</div>
              </button>

              {!subUserUID && (
                <div>
                  <button
                    onClick={handleToggleTools}
                    className={`${showTools ? "selected" : ""}`}
                  >
                    <div className="icon-container">
                      <FontAwesomeIcon
                        icon={faMoneyCheckDollar}
                        size="lg"
                        style={{ height: "30px" }}
                      />
                    </div>
                    <div className="text-container">Billing</div>
                  </button>
                </div>
              )}
            </div>

            {showTools && (
              <div className="secondRow">
                <div className="action-button-container">
                  <>
                    <button onClick={handleSendClaim}>
                      <div className="icon-container">
                        <FontAwesomeIcon
                          icon={faFileInvoiceDollar}
                          size="lg"
                          style={{ height: "30px" }}
                        />
                      </div>
                      <div className="text-container">Send Claim</div>
                    </button>

                    <button onClick={handleEligibility}>
                      <div className="icon-container">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlassDollar}
                          size="lg"
                          style={{ height: "30px" }}
                        />
                      </div>
                      <div className="text-container">Eligibility Search</div>
                    </button>

                    <InstantEligibility patientId={patientSelected.id} />
                  </>
                </div>
              </div>
            )}

            <div className="secondRow">
              <div className="action-button-container">
                <button onClick={handleZoom}>
                  <div className="icon-container">
                    <FontAwesomeIcon
                      icon={faVideo}
                      size="lg"
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="text-container">Zoom</div>
                </button>
                <button onClick={handleNotes}>
                  <div className="icon-container">
                    <FontAwesomeIcon
                      icon={faNotesMedical}
                      size="lg"
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="text-container">Notes</div>
                </button>
                <button onClick={handleFiles}>
                  <div className="icon-container">
                    <FontAwesomeIcon
                      icon={faFileMedical}
                      size="lg"
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="text-container">Files</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.patientInfocontainer}>
            <div className={styles.payerSummary}>
              <div style={{ display: "flex" }}>
                {patientData && patientData.payers?.name && (
                  <p className={styles.insuranceName}>
                    {patientData.payers?.name}
                    {(flag === "Eligible" || flag === "Ineligible") &&
                      ` | ${flag}`}
                  </p>
                )}
              </div>
              {patientData && patientData.payers?.memberId && (
                <p className={styles.memberId}>
                  Member ID: {patientData.payers?.memberId}
                </p>
              )}
            </div>

               {/* Secondary Payer Display */}
            {patientData.payers?.secondaryPayer && (
              <div className={styles.payerSummary} style={{ marginTop: "10px" }}>
                <div style={{ display: "flex" }}>
                  <p className={styles.insuranceName}>
                    {patientData.payers.secondaryPayer.name}
                    {/* Add any additional flags or indicators as needed */}
                  </p>
                </div>
                <p className={styles.memberId}>
                  Member ID: {patientData.payers.secondaryPayer.memberId}
                </p>
              </div>
            )}

            <div className={styles.collapsibleContainer}>
              <div>
              {patientData.patient?.firstName && (
                    <p>
                      <span style={{ opacity: 0.75 }}>Name: </span>
                      <span className="opacity-100">
                        {capitalizeName(`${patientData.patient?.firstName} ${patientData.patient?.middleName ? patientData.patient?.middleName + ' ' : ''}${patientData.patient?.lastName}`)}
                      </span>
                    </p>
                  )}

                {patientData.patient?.dob && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Date of Birth: </span>
                    <span className="opacity-100">
                      {(patientData.patient.dob)}
                    </span>
                  </p>
                )}
                {patientData.patient?.gender && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Gender: </span>
                    <span className="opacity-100">
                      {formatGender(patientData.patient.gender)}
                    </span>
                  </p>
                )}
                {patientData.patient?.address?.address1 && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Address: </span>
                    <span>
                      {patientData.patient.address.address1}
                      {patientData.patient.address.address2
                        ? `, ${patientData.patient.address.address2}`
                        : ""}
                      , {patientData.patient.address.city},{" "}
                      {patientData.patient.address.state}{" "}
                      {patientData.patient.address.zip}
                    </span>
                  </p>
                )}
                {patientData.patient?.email && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Email: </span>
                    <span className="opacity-100">
                      {patientData.patient?.email}
                    </span>
                  </p>
                )}
                {patientData.patient?.phone && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Phone: </span>
                    <span className="opacity-100">
                      {patientData.patient?.phone}
                    </span>
                  </p>
                )}

               {patientMRN && (
                  <p>
                    <span style={{ opacity: 0.75 }}>Medical Record Number: </span>
                    <span className="opacity-100">
                      {patientMRN}
                    </span>
                  </p>
                )}
                <ClinicNameFetcher patientId={patientSelected.id}/>
                <NotesView patientId={patientSelected.id} />
                <EncountersView patientId={patientSelected.id} />
                <MedicalHistory patientId={patientSelected.id} />
                <EligibilityCards patientId={patientSelected.id} />
                <ClaimsView patientId={patientSelected.id} />
                <IntakeView patientId={patientSelected.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientView;
