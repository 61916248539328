import React, { useState } from "react";
import BillingCodesSelector from "./BillingCodesSelector";
import styles from "../Claims.css";

function ServiceLineTable({ onBillingCodesSelect, onTotalChargesChange }) {
  const [selectedBillingCodes, setSelectedBillingCodes] = useState([]);

  const totalCharges = selectedBillingCodes.reduce((acc, curr) => {
    return acc + (curr.billAmount || 0);
  }, 0);

  const handleBillingCodesSelect = (selectedCodes) => {
    setSelectedBillingCodes(selectedCodes);
    onBillingCodesSelect(selectedCodes);

    const newTotalCharges = selectedCodes.reduce((acc, curr) => {
      return acc + (curr.billAmount || 0);
    }, 0);
    onTotalChargesChange(newTotalCharges);
  };

  return (
    <div>
      <div className="selectors-container">
        <div className="billingSelector">
          <BillingCodesSelector
            onBillingCodeSelect={handleBillingCodesSelect}
          />
          <h4 className="selected-codes-text">
            {selectedBillingCodes.length === 0 ? (
              "Please select a billing code above."
            ) : (
              <>
                Selected Codes:{" "}
                {selectedBillingCodes
                  .map((selectedCode) => {
                    const billAmount = selectedCode.billAmount || 0;
                    return `${selectedCode.name} (${
                      selectedCode.code
                    }) - $${billAmount.toFixed(2)}`;
                  })
                  .join(", ")}
              </>
            )}
          </h4>
        </div>
      </div>
      <div
        style={{
          height: "1px",
          backgroundColor: "black",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>

      <h3>Service Lines</h3>
      <table className={styles.serviceLineTable}>
        <thead>
          <tr className="codesTable">
            <th className="codesTablehover">Name</th>
            <th>HCPCS Code</th>
            <th>ICD-10 Code</th>
            <th>Charge Amount</th>
          </tr>
        </thead>
        <tbody>
          {selectedBillingCodes.map((billingCode, index) => (
            <tr className="codesTablehover" key={billingCode.code}>
              <td>{billingCode.name}</td>
              <td>{billingCode.code}</td>
              <td>
                {Array.isArray(billingCode.diagnosisCode)
                  ? billingCode.diagnosisCode.join(", ")
                  : billingCode.diagnosisCode}
              </td>
              <td>${(billingCode.billAmount || 0).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="codesTablehover">
            <td colSpan="3">Total Charges:</td>
            <td colSpan="2">${totalCharges.toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default ServiceLineTable;
