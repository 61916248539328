import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const ClinicEditModal = ({
  selectedClinic,
  setSelectedClinic,
  onClinicsUpdated,
  onClose,
}) => {
  const [clinicData, setClinicData] = useState(null);

  useEffect(() => {
    const fetchClinicData = async () => {
      if (selectedClinic && selectedClinic.id) {
        const clinicRef = doc(db, "clinics", selectedClinic.id);
        const clinicSnapshot = await getDoc(clinicRef);

        if (clinicSnapshot.exists()) {
          setClinicData(clinicSnapshot.data());
        }
      }
    };

    fetchClinicData();
  }, [selectedClinic]);

  const handleSaveEdit = async () => {
    if (clinicData) {
      try {
        // Ensure all fields are defined
        const {
          clinicName = "",
          addInsuranceCardCamera = false,
          requireAddress = false,
          requireInsuranceInfo = false,
          requirePhone = false,
          showCustomIntakeFrom = false,
          location = "",
        } = clinicData;

        // Use selectedClinic.id instead of clinicData.id
        const clinicRef = doc(db, "clinics", selectedClinic.id);

        await updateDoc(clinicRef, {
          clinicName,
          addInsuranceCardCamera,
          requireAddress,
          requireInsuranceInfo,
          requirePhone,
          showCustomIntakeFrom,
          location,
        });

        setSelectedClinic(null); // Close the modal after updating
        onClinicsUpdated(); // Notify the parent component to refetch or update its state
      } catch (error) {
        console.error("Error updating clinic:", error);
      }
    }
  };

  const handleClose = () => {
    setSelectedClinic(null);
    onClose();
  };

  return (
    <ReactModal
      isOpen={!!selectedClinic}
      onRequestClose={() => setSelectedClinic(null)}
      className="clear"
      onClick={() => {
        handleClose();
      }}
    >
      <div className="overlay">
        <div className="modalBox">
          <div className="modalContainer">
            <button className="filesCloseButton" onClick={() => handleClose()}>
              X
            </button>

            <h3 className="mt-4">Edit Clinic</h3>

            <label>Clinic Name: </label>
            <br />
            <input
              type="text"
              value={clinicData ? clinicData.clinicName : ""}
              onChange={(e) =>
                setClinicData((prev) => ({
                  ...prev,
                  clinicName: e.target.value,
                }))
              }
            />
            <br />

            <label>Location: </label>
            <br />
            <input
              type="text"
              value={clinicData ? clinicData.location : ""}
              onChange={(e) =>
                setClinicData((prev) => ({
                  ...prev,
                  location: e.target.value,
                }))
              }
            />
            <br />

            <div className="checkbox-row">
              <label>Add Insurance Card Camera: </label>
              <input
                type="checkbox"
                checked={clinicData ? clinicData.addInsuranceCardCamera : false}
                onChange={(e) =>
                  setClinicData((prev) => ({
                    ...prev,
                    addInsuranceCardCamera: e.target.checked,
                  }))
                }
                className="custom-checkbox"
                required
              />
            </div>
            <br />

            <div className="checkbox-row">
              <label>Require Address: </label>
              <input
                type="checkbox"
                checked={clinicData ? clinicData.requireAddress : false}
                onChange={(e) =>
                  setClinicData((prev) => ({
                    ...prev,
                    requireAddress: e.target.checked,
                  }))
                }
                className="custom-checkbox"
                required
              />
            </div>
            <br />

            <div className="checkbox-row">
              <label>Require Insurance Info: </label>
              <input
                type="checkbox"
                checked={clinicData ? clinicData.requireInsuranceInfo : false}
                onChange={(e) =>
                  setClinicData((prev) => ({
                    ...prev,
                    requireInsuranceInfo: e.target.checked,
                  }))
                }
                className="custom-checkbox"
                required
              />
            </div>
            <br />

            <div className="checkbox-row">
              <label>Require Phone: </label>
              <input
                type="checkbox"
                checked={clinicData ? clinicData.requirePhone : false}
                onChange={(e) =>
                  setClinicData((prev) => ({
                    ...prev,
                    requirePhone: e.target.checked,
                  }))
                }
                className="custom-checkbox"
                required
              />
            </div>
            <br />

            <div className="checkbox-row">
              <label>Show Custom Intake Form: </label>
              <input
                type="checkbox"
                checked={clinicData ? clinicData.showCustomIntakeFrom : false}
                onChange={(e) =>
                  setClinicData((prev) => ({
                    ...prev,
                    showCustomIntakeFrom: e.target.checked,
                  }))
                }
                className="custom-checkbox"
                required
              />
            </div>
            <br />

            <button className="primary mb-4" onClick={handleSaveEdit}>
              <FontAwesomeIcon icon={faSave} className="mr-3" /> Update Clinic
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ClinicEditModal;
