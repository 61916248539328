import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const downloadClaimsData = (patientsData) => {
  // Map the patientsData to the required format

  function formatDateString(dateStr) {
    if (!dateStr || dateStr.length !== 8) return '';
    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6, 8);
    return `${month}/${day}/${year}`;
  }
  

  const basicData = patientsData.map(patient => ({
    FirstName: patient.requestData.subscriber.firstName,
    middleName: patient.requestData.subscriber.middleName,
    LastName: patient.requestData.subscriber.lastName,
    dateOfBirth: formatDateString(patient.requestData.subscriber.dateOfBirth),
    gender: patient.requestData.subscriber.gender,
    address1: patient.requestData.subscriber.address.address1,
    address2: patient.requestData.subscriber.address.address2 || '',  // Assuming address2 might be optional
    city: patient.requestData.subscriber.address.city,
    state: patient.requestData.subscriber.address.state,
    zip: patient.requestData.subscriber.address.postalCode,
    PAYER: patient.requestData.tradingPartnerName,
    memberId: patient.requestData.subscriber.memberId,
    dateOfService: formatDateString(patient.requestData.claimInformation.serviceLines[0].serviceDate),
    dateOfServiceEnd: formatDateString(patient.requestData.claimInformation.serviceLines[0].serviceDateEnd),
    priorAuthorizationNumber: patient.requestData.claimInformation.priorAuthorizationNumber || '',  // Assuming it might be optional

    ControlNumber: patient.requestData.controlNumber,
    PatientControlNumber: patient.requestData.claimInformation.patientControlNumber,
    ClaimChargeAmount: patient.requestData.claimInformation.claimChargeAmount,
    ServiceFacilityLocation: patient.requestData.claimInformation.serviceFacilityLocation.organizationName,
    ProviderOrganizationName: patient.requestData.submitter.organizationName || '',
    correlationId: patient.responseData?.claimReference?.correlationId || '',
    traceId: patient.responseData?.meta?.traceId || '',
    timestamp: patient.timestamp || '',
  }));

  let serviceLinesData = [];
  patientsData.forEach(patient => {
    patient.requestData.claimInformation.serviceLines.forEach((serviceLine, index) => {
      const diagnosisCode = patient.requestData.claimInformation.healthCareCodeInformation[index]?.diagnosisCode || 'N/A';
  
      serviceLinesData.push({
        firstName: patient.requestData.subscriber.firstName,
        middleName: patient.requestData.subscriber.middleName || '',
        lastName: patient.requestData.subscriber.lastName,
        DateOfBirth: formatDateString(patient.requestData.subscriber.dateOfBirth),
        Gender: patient.requestData.subscriber.gender,
  
        ProcedureCode: serviceLine.professionalService.procedureCode,
        ChargeAmount: serviceLine.professionalService.lineItemChargeAmount,
        DateOfService: formatDateString(serviceLine.serviceDate),
        DateOfServiceEnd: formatDateString(serviceLine.serviceDateEnd),
        DiagnosisCode: diagnosisCode,

      });
    });
  });
  
  

  let providersData = [];
  patientsData.forEach(patient => {
    patient.requestData.providers.forEach(provider => {
      providersData.push({
        firstName: patient.requestData.subscriber.firstName,
        middleName: patient.requestData.subscriber.middleName || '',
        lastName: patient.requestData.subscriber.lastName,
        DateOfBirth: formatDateString(patient.requestData.subscriber.dateOfBirth),
        Gender: patient.requestData.subscriber.gender,
        
        organizationName: provider.organizationName || '',
        ProviderFirstName: provider.firstName || '',
        ProviderLastName: provider.lastName || '',
        ProviderNPI: provider.npi || '',
        ProviderEmployerId: provider.employerId || '',
        ProviderType: provider.providerType || '',
        ProviderAddress1: provider.address?.address1,
        ProviderCity: provider.address?.city,
        ProviderState: provider.address?.state,
        ProviderPostalCode: provider.address?.postalCode,
      });
    });
  });
  
    // Assuming the first patient's data is representative for the filename
  const providerOrganizationName = patientsData[0]?.requestData?.submitter?.organizationName || 'UnknownProvider';
  
  // Convert Firebase timestamp to JavaScript Date object
  let timestamp = new Date();
  if (patientsData[0] && patientsData[0].timestamp) {
    const { seconds, nanoseconds } = patientsData[0].timestamp;
    timestamp = new Date(seconds * 1000 + nanoseconds / 1000000);
  }

  // Format timestamp to a more suitable string (YYYY-MM-DD)
  const formattedTimestamp = timestamp.toISOString().split('T')[0];

  const filename = `ClaimsData_${providerOrganizationName}_${formattedTimestamp}.xlsx`;

    // Create and append sheets to the workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(basicData), 'BasicData');
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(serviceLinesData), 'ServiceLines');
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(providersData), 'Providers');
  
    // Write the workbook to a binary string and download
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, filename);
  };

// Utility function for ArrayBuffer conversion
function s2ab(s) {
  const buffer = new ArrayBuffer(s.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buffer;
}

export default downloadClaimsData;