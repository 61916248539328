import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserTie,
  faHandshake,
  faFileInvoiceDollar,
  faCommentsDollar,
  faUsers,
  faNetworkWired,
  faFileSignature,
  faLaptopMedical
} from '@fortawesome/free-solid-svg-icons';
import { db } from "../../firebase";
import Topbar from "../General/Topbar";
import useUID from '../General/useUID';
import './ipa.css'; // Ensure you have an appropriate CSS file
import { doc, getDoc } from "firebase/firestore";

function IPA() {
  const navigate = useNavigate();
  const [uid] = useUID();
  const [selectedServices, setSelectedServices] = useState([]);
  const [applicationExists, setApplicationExists] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [totalSteps] = useState(12);

  useEffect(() => {
    const checkForExistingApplication = async () => {
      if (!uid) return;
      const docRef = doc(db, `users/${uid}/applications/ipaApplication`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setApplicationExists(true);
        setCurrentStep(docSnap.data().currentStep);
      }
    };

    checkForExistingApplication();
  }, [uid]);

  const services = [
    { name: "Credentialing", icon: faUserTie },
    { name: "A Friend", icon: faHandshake },
    { name: "Billing Service", icon: faFileInvoiceDollar },
    { name: "Collective Negotiation", icon: faCommentsDollar },
    { name: "Billing Professionals", icon: faUsers },
    { name: "Request Payers", icon: faNetworkWired },
    { name: "In Network with Payers", icon: faFileSignature },
    { name: "Electronic Registration", icon: faLaptopMedical },
  ];

  const toggleServiceSelection = (serviceName) => {
    setSelectedServices((prev) =>
      prev.includes(serviceName) ? prev.filter(s => s !== serviceName) : [...prev, serviceName]
    );
  };

  const startApplication = () => {
    // Optionally handle service selection persistence here
    navigate('/ipaapplication');
  };

  const renderServiceSelection = () => (
    <>
      <h2>Select the services you're interested in:</h2>
      <div className="provider-type-cards-container">
        {services.map((service) => (
          <div
            key={service.name}
            className={`provider-card ${selectedServices.includes(service.name) ? 'selected' : ''}`}
            onClick={() => toggleServiceSelection(service.name)}
          >
            <FontAwesomeIcon icon={service.icon} style={{ height: "40px" }} />
            <h3 style={{ textAlign: "center" }}>{service.name}</h3>
          </div>
        ))}
      </div>
      <button onClick={startApplication} className="navigateButton">
        Start Your Application
      </button>
    </>
  );

  const renderApplicationStatus = () => {
    if (currentStep > totalSteps) {
      // Assuming the application is complete if currentStep is beyond totalSteps
      return (
        <>
          <h2>Your IPA Application is Complete</h2>
          <p>Thank you for completing your application. Our staff will reach out to you with next steps.</p>
          <button onClick={() => navigate('/home')} className="navigateButton">
            Return to Home
          </button>
        </>
      );
    } else {
      return (
        <>
          <h2>Your IPA Application Status</h2>
          <p>You are currently on step {currentStep} of the application process.</p>
          <button onClick={() => navigate('/ipaapplication')} className="navigateButton">
            Continue Application
          </button>
        </>
      );
    }
  };

  return (
    <div className='IPA-background'>
      <Topbar />
      <div className='IPA-container'>
        <div className='IPA-form'>
          {applicationExists ? renderApplicationStatus() : renderServiceSelection()}
        </div>
      </div>
    </div>
  );
}

export default IPA;
