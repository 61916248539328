import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCheck, // Active
  faUserFriends, // Associate
  faHandshake, // Courtesy
  faHourglassStart, // Provisional
  faUserMd, // Consulting
  faClock, // Temporary
  faUserTie, // Visiting
  faHospitalUser, // Admitting
  faUserSlash, // Suspended
  faHourglassHalf, // Pending
  faUserClock, // Active Provisional Staff
  faHandshakeAngle, // Courtesy Provisional Staff
  faUserGraduate, // Senior Staff
  faQuestion, // Other
} from '@fortawesome/free-solid-svg-icons';

export const privilegeStatuses = [
    { status: 'Active', description: 'Active', icon: faUserCheck },
    { status: 'Associate', description: 'Associate', icon: faUserFriends },
    { status: 'Courtesy', description: 'Courtesy', icon: faHandshake },
    { status: 'Provisional', description: 'Provisional', icon: faHourglassStart },
    { status: 'Consulting', description: 'Consulting', icon: faUserMd },
    { status: 'Temporary', description: 'Temporary', icon: faClock },
    { status: 'Visiting', description: 'Visiting', icon: faUserTie },
    { status: 'Admitting', description: 'Admitting', icon: faHospitalUser },
    { status: 'Suspended', description: 'Suspended', icon: faUserSlash },
    { status: 'Pending', description: 'Pending', icon: faHourglassHalf },
    { status: 'Active Provisional Staff', description: 'Active Provisional Staff', icon: faUserClock },
    { status: 'Courtesy Provisional Staff', description: 'Courtesy Provisional Staff', icon: faHandshakeAngle },
    { status: 'Senior Staff', description: 'Senior Staff', icon: faUserGraduate },
    { status: 'Other', description: 'Other', icon: faQuestion },
  ];

  export default privilegeStatuses;
  