import React from "react";
import Confetti from "../../../General/Animations/Confetti";

const parseDate = (dateString) => {
  const dateParts = dateString.split(' ');
  const month = new Date(Date.parse(`${dateParts[0]} 1, 2020`)).getMonth() + 1;
  const day = parseInt(dateParts[1], 10);
  const year = new Date().getFullYear();
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

const generateICS = (appointmentData, location) => {
  const parsedDate = parseDate(appointmentData.date);
  const timeZoneOffset = '-05:00'; // Fixed offset for EST

  const startDateTimeString = `${parsedDate}T${appointmentData.time}:00${timeZoneOffset}`;
  const startDateTime = new Date(startDateTimeString);

  if (isNaN(startDateTime.getTime())) {
    console.error("Invalid date:", startDateTimeString);
    return;
  }

  const endDateTime = new Date(startDateTime.getTime() + (appointmentData.appointmentDuration || 30) * 60000); // Default to 30 minutes if duration is missing

  const formatDate = (date) => {
    return date.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
  };

  const event = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//hacksw/handcal//NONSGML v1.0//EN
BEGIN:VEVENT
UID:${new Date().getTime()}@example.com
DTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}Z
DTSTART:${formatDate(startDateTime)}
DTEND:${formatDate(endDateTime)}
SUMMARY:${appointmentData.appointmentType}
LOCATION:${location}
DESCRIPTION:Appointment with ${appointmentData.clinicName || 'Clinic'}
END:VEVENT
END:VCALENDAR
  `.trim();

  const blob = new Blob([event], { type: 'text/calendar' });
  return URL.createObjectURL(blob);
};

export default function IntakeSuccess({ appointmentData, location }) {
  const icsFileUrl = generateICS(appointmentData, location);

  return (
    <div>
      <Confetti />
      <div className="congratulations-message pt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
        >
          <circle
            cx="50"
            cy="50"
            r="45"
            stroke="rgb(0, 49, 111)"
            strokeWidth="8"
            fill="none"
          />
          <path
            d="M30 50L45 65L75 35"
            stroke="rgb(0, 49, 111)"
            strokeWidth="8"
            strokeLinecap="round"
          />
        </svg>
        <h2>Congratulations!</h2>
        <p>Your intake is completed & saved.</p>
        <p>Your appointment "{appointmentData.appointmentType}" is set for:</p>
        <div className="success-message">
          {appointmentData.dayOfWeek} {appointmentData.date} @ {appointmentData.time} {appointmentData.timeZone}
        </div>
        {location && (
          <div className="clinic-location">
            <p>{location}</p>
          </div>
        )}
        <a href={icsFileUrl} download="appointment.ics">
          Add to Google Calendar
        </a>
        <p>We will see you then!</p>
      </div>
    </div>
  );
}
